.profile-dashboard-left{
    border-right: 1px solid #E3E3E3;
    background-color: #FDFDFD;
    width: 30%;
}
.isg-profile-main{
    background: #FFFFFF;
}
.profile-dashboard-right{
    width: 70%;
}
.profile-dashboard{
    margin-top: 84px;
    border-top: 1px solid #E3E3E3;
}
.profile-name-photo{
    border-radius: 99px;
    background-color: #24584B;
    display: flex;
    width: 72px;
    height: 72px;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: center;
    font-family: var(--switzer);
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 43.2px */
    text-transform: capitalize;
    overflow: hidden;
}
.profile-name-photo img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.profile-name-text{
    color: #222;
    font-family: var(--switzer);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}
.profile-name-btn span{
    color: #888;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: inline-block;
    padding-bottom: 3px;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    cursor: pointer;
    border-bottom: 1px solid #E3E3E3;
}
.prof-name-disp-left{
    gap: 16px;
}
.prof-name-disp-right{
    width: 42px;
    height: 42px;
    border: 3px solid #E6E6E6;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.prof-name-disp-fill{
    position: absolute;
    border: 3px solid #A8DD73;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.prof-name-disp-percentage{
    color: #222;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}
.profile-dashboard-tabs{
    padding-top: 52px;
}
.profile-dashboard-tabs ul{
    display: flex;
    flex-direction: column;
    border: none;
    gap: 36px;
}
.profile-dashboard-tabs ul li{
    background-color: transparent;
    border: none;
    display: flex;
    gap: 16px;
    align-items: center;
}
.profile-name-edit-profile-wrap{
    gap: 8px;
}
.profile-dashboard-tabs ul li div, .profile-dashboard-tabs .logOut div{
    color: #777;
    font-family: var(--switzer);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    transition: .2s ease all;
}
.profile-dashboard-tabs ul li.react-tabs__tab.react-tabs__tab--selected div, .profile-dashboard-tabs ul li.react-tabs__tab.react-tabs__tab:hover div, .profile-dashboard-tabs .logOut:hover div{
    color: #24584B;
}
.profile-dashboard-tabs ul li svg path, .profile-dashboard-tabs .logOut svg path {
    transition: .2s ease all;
}
.profile-dashboard-tabs ul li svg path:first-child {
    fill: #C0C0C0;
}
.profile-dashboard-tabs .logOut svg path {
    fill: #C0C0C0;
}
.profile-dashboard-tabs ul li.react-tabs__tab.react-tabs__tab--selected svg path:first-child, .profile-dashboard-tabs ul li.react-tabs__tab.react-tabs__tab:hover svg path:first-child, .profile-dashboard-tabs .logOut:hover svg path:first-child  {
    fill: #24584B;
}
.profile-dashboard-tabs ul li.react-tabs__tab.react-tabs__tab:hover svg path:first-child {
    stroke: #24584B;
}
.profile-dashboard-tabs .logOut:hover svg path {
    fill: #24584B;
}
.profile-dashboard-tabs .logOut {
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    display: flex;
    gap: 16px;
    align-items: center;
    margin-top: 36px;
}
.profile-dashboard-left-wrapper{
    padding: 67px 18px 50px 111px;
}
.dashboard-empty-state-banner{
    border-radius: 8px;
    background-color: #24584B;
    width: 100%;
    position: relative;
    height: 120px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.dash-empty-banner-ptrn{
    position: absolute;
    right: 0;
    top: 0;
}
.dash-empty-banner-ptrn img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.dash-empty-sessions-content {
    position: relative;
}
.dash-empty-sessions-content .newblogbtn {
    position: absolute;
    top: 0;
    right: 0;
}
.newblogbtn .primary-btn.border {
    padding: 6px 20px;
    font-size: 12px;
    border-color: #C0C0C0;
}
.dash-empty-name{
    gap: 16px;
}
.dash-empty-name-text{
    color: #A8DD73;
    font-family: var(--switzer);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-transform: capitalize;
}
.dash-empty-body{
    padding-top: 5px;
}
.dash-empty-body p{
    color: #FFF;
    font-family: var(--nunito);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%;
}
.dash-empty-text-wrap{
    padding-left: 16px;
    position: relative;
}
.dashboard-empty-state{
    padding: 36px 120px 70px 72px;
    margin: 0 auto;
}
.dash-empty-upcoming-sessions{
    padding-top: 44px;
    padding-bottom: 88px;
    border-bottom: 1px solid #E3E3E3;
}
.dash-empty-upcoming-sessions.less-space {
    padding-bottom: 60px;
}
.dash-empty-sess-head{
    color: #222;
    font-family: var(--switzer);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 15px;
}
.dash-empty-sess-body{
    /* padding-top: 6px; */
}
.dash-sessions-data.schedule-cards {
    margin-top: 20px;
}
.dash-empty-sess-body p{
    color: #777;
    font-family: var(--nunito);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%;
    letter-spacing: 0.04px;
}
.dash-emp-no-sess-head{
    color: #333;
    text-align: center;
    font-family: var(--switzer);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 144%;
    letter-spacing: 0.36px;
}
.dash-emp-no-sess-body{
    padding-top: 8px;
}
.dash-emp-no-sess-body p{
    color: #777;
    text-align: center;
    font-family: var(--nunito);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}
.dash-emp-no-sess-btn .primary-btn{
    padding: 6px 21px;
}
.dash-emp-no-sess-btn{
    padding-top: 24px;
}
.dash-emp-no-sess{
    max-width: 35ch;
    margin: 0 auto;
    text-align: center;
    padding-top: 56px;
}
.dash-empty-top-rated-mentors{
    padding-top: 44px;
}
.dash-empty-trm-slider-btns{
    gap: 15px;
}
.det-slider-prev, .det-slider-next {
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    pad: 3px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 3px 4px 0px #F6F6F6;
}
.det-slider-next.swiper-button-disabled {
    cursor: not-allowed;
    opacity: .5;
    box-shadow: none;
}
.det-slider-prev.swiper-button-disabled{
    cursor: not-allowed;
    opacity: .5;
    box-shadow: none;
}
.det-slider-prev svg {
    transform: rotate(-180deg);
}
.ongoing-empty .det-slider-prev, .ongoing-empty .det-slider-next {
    background-color: #313131;
}
.ongoing-empty .det-slider-prev svg path, .ongoing-empty .det-slider-next svg path {
    stroke: #FFFFFF;
}
.ongoing-empty .det-slider-prev.swiper-button-disabled, .ongoing-empty .det-slider-next.swiper-button-disabled {
    background-color: transparent;
}
.ongoing-empty .det-slider-prev.swiper-button-disabled svg path, .ongoing-empty .det-slider-next.swiper-button-disabled svg path {
    stroke:#313131;
}
.dash-emp-trm-slider-left{
    border-radius: 8px;
    background-color: #FF7252;
}
.dash-emp-trm-slider-left.popular {
    background-color: #24584B;
}
.dash-emp-trm-slider-left-head {
    color: #F9E491;
    font-family: var(--switzer);
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 90%;
}
.dash-emp-trm-slider-left.popular .dash-emp-trm-slider-left-head {
    color: #A8DD73;
}
.dash-emp-trm-slider-left-dash {
    width: 17px;
    height: 3px;
    background-color: #FFA590;
}
.dash-emp-trm-slider-left.popular .dash-emp-trm-slider-left-dash {
    background-color: #558176;
}
.dash-emp-trm-slider-left-cont-body p{
    color: #FCFCFC;
    font-family: var(--nunito);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.035px;
}
.dash-emp-trm-slider-left-cont-btn .primary-btn.border{
    color: #FFFFFF;
    padding: 6px 21px;
    border: 1px solid #FFF;
}
.dash-emp-trm-slider-left{
    width: 33%;
    margin-right: 13px;
}
.dash-emp-trm-slider{
    padding-top: 24px;
}
.dash-emp-trm-slider-left-wrap{
    padding: 30px 16px;
}
.dash-emp-trm-slider-left-cont-btn{
    padding-top: 19px;
}
.dash-emp-trm-slider-left-wrap{
    gap: 52px;
    justify-content: space-between;
    height: calc(100% - 60px);
}
.dash-emp-trm-slider-left-cont-body{
    max-width: 18ch;
}
.dash-emp-trm-slider-right{
    width: 66%;
}
.fav-mentors-empty .dash-emp-no-sess{
    padding-top: 91px;
}
.favourite-mentors-list-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap:24px;
    grid-column-gap:13px;
    padding-top: 24px;
}
.favourite-mentors-list-button{
    padding-top: 48px;
    text-align: center;
}
.profile-settings-tabs {
    padding-top: 39px;
}
.profile-settings-form-wrapper{
    padding-top: 40px;
}
.profile-settings-password-head{
    color: #222;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
}
.profile-settings-password-body{
    padding-top: 6px;
}
.profile-settings-password-body p{
    color: #777;
    font-family: var(--nunito);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}
.profile-settings-form-input-label{
    gap: 8px;
}
.profile-settings-form-input-label label{
    color: #666;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%;
    letter-spacing: 0.24px;
    text-transform: capitalize;
}
.profile-settings-form-input-label label span{
    color: #111;
    display: inline;
}
.profile-settings-form-input-label input {
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    background-color: #F9F9F9;
    padding: 10px 15px;
    width: 100%;
    /* height: 35px;
    line-height: normal; */
}
/* .profile-settings-form-input-label input::placeholder {
    all: unset;
    -webkit-text-security: initial;
    padding: 0 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  } */
.profile-settings-form-input-label select{
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    background-color: #F9F9F9;
    padding: 10px 15px;
    width: 100%;
    color: #777;
    font-family: var(--nunito);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}
.profile-settings-form-input-label input::placeholder{
    color: #C1C1C1;
    font-size: 40px;
}
.profile-settings-form-input-label input::-webkit-input-placeholder {
    font-size: inherit;
}
.profile-settings-form-input-wrap{
    position: relative;
}
.profile-settings-form-input-wrap .error-name-onb {
    margin-bottom: 8px;
}
.profile-settings-form-input-label svg{
    right: 15px;
    bottom: 12px;
    position: absolute;
}
.profile-settings-form-cont{
    padding-top: 30px;
}
.profile-settings-form-cont form .profile-settings-form-input-label:not(:first-child){
    padding-top: 16px;
}
.profile-settings-form-cont form{
    min-width: 340px;
    width: 40%;
    padding-bottom: 35px;
    border-bottom: 1px solid #E3E3E3;
}
.reset-text {
    margin-top: 35px;
}
.reset-text p {
    color: #777;
    font-family: var(--nunito);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%;
    letter-spacing: 0.04px;
}
.profile-settings-delete-btn{
    padding-top: 15px;
    gap: 8px;
    align-items: center;
    cursor: pointer;
}
/* .profile-settings-delete-btn.lesspad {
    padding-top: 15px;
} */
.profile-settings-delete-btn .profile-settings-delete-icon {
    display: flex;
    align-items: center;
    width: 20px;
}
.profile-settings-delete-icon svg {
    width: 20px;
}
.profile-settings-delete-icon img {
    width: 18px;
}
.profile-settings-delete-text{
    color: #FF6565;
    font-family: var(--switzer);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 144%;
    letter-spacing: 0.32px;
}
.green .profile-settings-delete-text {
    color: #24584B;
}
.delete-account-pop .insage-login{
    margin-top: 100px;
}
.delete-account-pop input::placeholder{
    font-size: 14px;
    color: #777;
}
.delete-account-pop .profile-settings-form-input-wrap{
    gap: 8px;
}
.delete-account-pop .profile-settings-form-input-label:not(:first-child){
    padding-top: 30px;
}
.delete-account-btn.primary-btn{
    color: #F45E5C;
    background-color: #F8E0DD;
    padding: 6px 21px;
    justify-content: center;
    width: 40%;
}
.purchase-history-empty .dash-emp-no-sess{
   padding-top: 90px;
   max-width: 50ch;
}
.dashboard-messages-head{
    padding-top: 32px;
    padding-left: 24px;
}
.dashboard-messages-tabs{
    padding-top: 16px;
}
.dashboard-messages-left {
    width: 31%;
    border-right: 1px solid #E3E3E3;
    /* height: 85vh; */
    overflow: hidden;
}
.dashboard-messages-tabs .react-tabs__tab{
   width: 100%;
   text-align: center;
}
.dashboard-messages-tabs .react-tabs__tab-list{
    gap: 0px;
}
.dashboard-messages-tabs .react-tabs__tab--selected{
    color: #222;
    border-bottom: 1px solid #222;
}
.dashboard-messages-tabs .insage-guides-tab-buttons{
    width: 100%;
}
.dashboard-messages-tabs-content .react-tabs__tab-panel {
    height: 895px;
    overflow: hidden;
    overflow-y: auto;
}
.dashboard-messages-inbox-empty{
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 42px;
}
.dashboard-messages-inbox-empty p{
    font-size: 14px;
    color: #777;
}
.dashboard-messages-wrap{
    display: flex;
}
.dashboard-messages-wrap .react-tabs{
    width: 100%;
    display: flex;
}
.dashboard-messages-inbox-right-empty .dash-emp-no-sess{
    padding-top: 0px;
    max-width: 45ch;
}
.dashboard-messages-right{
    width: 60%;
    border-right: 1px solid #E3E3E3;
}
.dashboard-messages-right > p {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* color: #fec14b; */
    font-family: var(--switzer);
}
.dashboard-messages-inbox-right-empty{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dashboard-messages-right-inbox-tab, .dashboard-messages-right-mentors-tab{
    display: none;
    height: 100%;
    animation: fadeEffect 1s;
}
.dashboard-messages-right-inbox-tab.active, .dashboard-messages-right-mentors-tab.active{
    display: block;
}
.dashboard-messages-right-mentors-tab .dash-emp-no-sess{
    max-width: 35ch;
}
.dashboard-messages-inbox-active {
    width: 100%;
    text-align: center;
}
.dashboard-messages-inbox-search{
    padding: 20px 24px 12px 24px;
}
.dashboard-messages-inbox-search > div{
    position: relative;
}
.dashboard-messages-inbox-search input{
    width: 100%;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    background-color: #F9F9F9;
    height: 32px;
    color: #999;
    font-size: 12px;
    padding:0 30px;
    box-sizing: border-box;
}
.dashboard-messages-inbox-search-icon{
    position: absolute;
    display: flex;
    height: 11px;
    width: 12px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    left: 12px;
    cursor: pointer;
}
.dashboard-messages-inbox-search-icon svg{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.dashboard-messages-inbox-peep-wrap{
    padding: 16px 20px;
    display: flex;
    align-items: center;
    gap: 9px;
    border-bottom: 1px solid #E3E3E3;
    cursor: pointer;
}
.dashboard-messages-tabs-content .react-tabs__tab-panel:nth-child(1) .dashboard-messages-inbox-peep-wrap > div {
    display: flex;
    align-items: center;
    gap: 9px;
}
.dashboard-messages-inbox-peep-img{
    width: 45px;
    height: 45px;
    position: relative;
}
.dashboard-messages-inbox-peep-wrap .dashboard-messages-inbox-peep-details {
    width: calc(100% - 45px);
}
.online-offline-dot{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #C0C0C0;
    border: 1px solid #FFF;
    position: absolute;
    top: 0;
    right: 0;
}
.online-offline-dot.online{
    background-color: #80E477;
}
.dashboard-messages-inbox-peep-img img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.dashboard-messages-inbox-peep-name{
    color: #222;
    font-family: var(--switzer);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 144%;
    letter-spacing: 0.04px;
    text-align: left;
}
.dashboard-messages-inbox-peep-desig {
    color: #777;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
    text-align: left;
}
.dashboard-messages-inbox-right-messages-empty{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.dashboard-messages-keyboard{
    position: absolute;
    bottom: 10px;
    width: 95%;
    margin:0 auto;
}
.dashboard-messages-keyboard > div{
    position: relative;
}
.dashboard-messages-keyboard .emoji-icon{
    width: 20px;
    height: 20px;
    position: absolute;
    top: -5px;
    bottom: 0;
    left: 16px;
    margin: auto 0;
}
.dashboard-messages-keyboard .emoji-icon svg{
   height: 100%;
   width: 100%;
   object-fit: cover;
}
.dashboard-messages-keyboard .send-icon{
    width: 18px;
    height: 18px;
    position: absolute;
    top: -5px;
    bottom: 0;
    right: 20px;
    margin: auto 0;
}
.dashboard-messages-keyboard .send-icon svg{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.dashboard-messages-keyboard textarea{
    resize: none;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    background-color: #F9F9F9;
    outline: none;
    padding: 16px 46px;
    box-sizing: border-box;
    color: #999;
    font-family: var(--nunito);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}
.dashboard-messages-inbox-right-messages-empty .dash-emp-no-sess{
    padding-top: 0px;
}
.dashboard-messages-inbox-right-messages-active{
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dashboard-messages-inbox-right-messages-active .dahboard-messages-ui{
    height: 100%;
    width: 100%;
    position: relative;
}
.dahboard-messages-ui .dashboard-messages-inbox-peep{
    position: absolute;
    width: 100%;
    top: 0;
    background-color: #FFF;
    z-index: 4;
}
.dahboard-messages-ui .dashboard-messages-inbox-peep-wrap{
    gap: 0px;
    justify-content: space-between;
}
.dashboard-messages-inbox-peep-left{
    display: flex;
    gap: 9px;
    align-items: center;
}
.dashboard-messages-ui-report-btn{
    display: flex;
    cursor: pointer;
}
.dashboard-messages-date{
    color: #999;
    text-align: center;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%;
    letter-spacing: 0.24px;
}
.dashboard-messages-interface{
    display: flex;
    flex-direction: column;
    /* justify-content: end; */
    overflow: hidden;
    overflow-y: auto;
    padding: 100px 0;
    /* height: 56%; */
    position: relative;
    z-index: 0;
    height: calc(100% - 200px)!important;
    padding-bottom: 0;
}
.dashboard-messages-interface::-webkit-scrollbar{
    display: none;
 }
.dashboard-messages-chats-wrap{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.dashboard-messages-sender{
    background-color: #24584B;
    border-radius: 4px;
    display: inline-flex;
    padding: 8px 9px;
    position: relative;
    width: 290px;
    padding-bottom: 30px;
}
.dashboard-messages-reciever{
    background-color: #F2F2F2;
    border-radius: 4px;
    display: inline-flex;
    padding: 8px 9px;
    position: relative;
    width: 308px;
    padding-bottom: 30px;
}
.dashboard-messages-reciever::after{
    content: "";
    position: absolute;
    width: 15px;
    height: 19px;
    left: -8px;
    bottom: 0;
    background-image: url('https://simpletesting.website/insage-images/whiteBox.svg');
    background-repeat: no-repeat;
}
.dashboard-messages-sender::after{
    content: "";
    position: absolute;
    width: 15px;
    height: 19px;
    right: -8px;
    bottom: 0;
    background-image: url('https://simpletesting.website/insage-images/greenBox.svg');
    background-repeat: no-repeat;
}
.dashboard-messages-sender.message-old::after, .dashboard-messages-reciever.message-old::after{
   display: none;
}
.dashboard-message-time{
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: var(--switzer);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 152%; 
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 2;
}
.dashboard-messages-sender .dashboard-message-time{
    color: #97BEB4;
}
.dashboard-messages-text-sent{
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; 
    word-break: break-all;
}
.dashboard-messages-reciever .dashboard-messages-text-sent{
    color: #222;
}
.dashboard-messages-chats-wrap{
    padding-top: 30px;
    padding-left: 13px;
    padding-right: 13px;
    height: calc(100vh - 420px);
}
.dashboard-messages-sender-wrap{
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: end;
}
.dashboard-messages-sender-wrap .dashboard-messages-sender:last-child{
  margin-bottom: 10px;
}
.dashboard-messages-reciever-wrap-left img{
   width: 24px;
   height: 24px;
   border-radius: 50%;
   overflow: hidden;
}
.dashboard-messages-reciever-wrap-left{
    display: flex;
    justify-content: end;
    align-items: end;
}
.dashboard-messages-reciever-wrap{
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-bottom: 20px;
}
.dashboard-messages-reciever-wrap-right{
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.create-new-schedule{
    padding: 74px 96px 70px 72px;
}
.create-new-schedule-top{
    padding-bottom: 32px;
}
.create-new-schedule-top .dash-empty-sess-body{
    padding-top: 16px;
}
.create-new-schedule-top-wrap{
  max-width: 35ch;
}
.schedule-separator{
    background-color: #E3E3E3;
    height: 1px;
    width: 90%;
}
.create-new-schedule-middle{
    padding:32px 0;
}
.create-new-schedule-middle{
    display: flex;
    gap: 12px;
}
.schedule-deatils-set-wrap{
    padding: 50px 32px;
}
.schedule-steps-label{
    color: #777;
    font-family: var(--switzer);
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: uppercase;
}
.schedule-details-set-cont .login-head{
    padding-top: 6px;
}
.schedule-details-set-cont-body p{
    color: #555;
    font-family: var(--nunito);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}
.schedule-details-set-cont-body{
    padding-top: 12px;
}
.schedule-details-set-cont{
    max-width: 40ch;
}
.schedule-details-set{
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    width: 65%;
}
.schedule-details-summary{
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    width: 35%;

}
.schedule-details-set-dropdowns{
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
}
.schedule-details-set-dropdown-left, .schedule-details-set-dropdown-right{
    width: 48%;
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    background-color: #F9F9F9;
    cursor: pointer;
    display: flex;
}
.schedule-details-set-dropdown-wrap{
    padding: 15px;
    width: 100%;
}
.schedule-details-set-dropdown-wrap select{
    border: none;
    background-color: transparent;
    width: 100%;
    cursor: pointer;
    background-position: right 0px center;
}
.schedule-details-set-dropdowns{
    padding-top: 24px;
}
.schedule-details-set-dropdown-label{
    color: #AAA;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
}
.schedule-details-set-dropdown-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.schedule-details-summary-wrap{
    padding: 26px 24px;
}
.schedule-details-summary-cont .schedule-details-set-cont-body p{
    color: #777;
}
.schedule-details-summary-cont .dash-empty-sess-head{
    padding-top: 4px;
}
.schedule-details-summary-separator{
    height: 2px;
    width: 10px;
    background-color: #E3E3E3;
    margin: 16px 0;
}
.schedule-details-summary-timings-title{
    color: #666;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.035px;
}
.schedule-details-summary-timings-dot{
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #AAA;
}
.schedule-details-summary-timings-time{
    color: #666;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.035px;
}
.schedule-details-summary-timings-wrap{
    display: flex;
    align-items: center;
    gap: 6px;
    flex-wrap: wrap;
}
.schedule-details-summary-timings{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.schedule-details-summary-button.disabled div{
    background-color: #E9E9E9;
    color: #999;
    cursor: not-allowed;
}
.schedule-details-summary-button div{
    background-color: #E4F6EA;
    color: #2A8751;
    font-weight: 700;
    display: inline-flex;
    border-radius: 4px;
    overflow: hidden;
    padding: 6px 21px;
    font-family: var(--switzer);
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    cursor: pointer;
}
.complete-step1{
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    margin-top: 32px;
}
.complete-step1-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 64px 0;
}
.create-new-schedule-bottom{
    padding-top: 32px;
}
.create-new-schedule-bottom-wrap .schedule-details-set-cont-body{
    max-width: 45ch;
}
.create-new-schedule-bottom.disabled{
    opacity: 0.6;
    cursor: not-allowed;
}
.availability-calendar{
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: .4s ease all;
    background: rgba(255, 255, 255, 0.75);
}
.availability-calendar.active{
    visibility: visible;
    opacity: 1;
    z-index: 9999;
}
.confirm-schedule-pop-wrap .login-head{
    color: #24584B;
}
.confirm-schedule-pop-wrap .schedule-steps-label{
    color: #999;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
    text-transform: none;
}
.confirm-sched-bottom{
    margin-top: 32px;
}
.confirm-schedule-pop{
    max-width: 371px;
    margin: 0 auto;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.10);
    position: relative;
}
.confirm-schedule-close{
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
}
.confirm-schedule-pop-wrap{
    padding: 42px 36px;   
}
.confirm-pop{
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: .4s ease all;
    background: rgba(255, 255, 255, 0.75);
}
.confirm-pop.active{
    opacity: 1;
    visibility: visible;
    z-index: 99999999999999;
}
.manage-availability{
    max-width: 640px;
}
.manage-availability-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.manage-availability-top-right button.primary-btn{
    background-color: #E4F6EA;
    color: #2A8751;
    font-weight: 700;
    padding: 6px 21px;
    display: inline-flex;
    gap: 6px;
}
.manage-availability-bottom-empty .manage-availability-top-right{
    padding-top: 24px;
}
.create-new-schedule-top .dashboard-empty-state-banner-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.create-new-schedule-top-btn{
    padding-right: 24px;
}
.create-new-schedule-top-btn button.primary-btn{
    background-color: #FFF;
    color: #222;
}
.create-new-schedule-top{
    padding-bottom: 30px;
    border-bottom: 1px solid #E3E3E3;
}
.create-new-schedule-bottom-middle{
    padding-top: 20px;
}
.create-new-schedule-bottom-middle{
    display: flex;
    align-items: center;
    gap: 11px;
}
.show-schedule-button{
    height: 58px;
}
.show-schedule-button button{
    height: 100%;
}
.booked-session-card-middle{
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 24px 0;
}
.booked-session-card-top .booked-session-card-label{
    color: #777;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
}
.booked-session-card-top .dash-empty-sess-head{
    color: #24584B;
    padding-top: 5px;
}
.booked-session-card-btn button{
    border: 1px solid #C0C0C0;
    padding: 6px 21px;
    text-transform: capitalize;
    color: #222;
    font-weight: 500;
    line-height: normal;
}
.booked-session-card-wrap{
    padding: 32px 16px;
}
.booked-session-card{
    border-radius: 8px;
    border: 1px solid #DBDBDB;
    /* max-width: 313px; */
    width: 99%;
    position: relative;
}
.booked-session-card-note{
    padding-top: 32px;
}
.booked-session-card-note .slot-booking-note-wrap{
    align-items: start;
}
.create-new-schedule-bottom-bottom{
    padding-top: 36px;
}
.booked-session-card-tag{
    position: absolute;
    top: 11px;
    right: 0;
}
.booked-session-card-tag-wrap{
    padding: 4px 13px;
    display: inline-flex;
    font-family: var(--switzer);
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    border-radius: 4px 0px 0px 4px;
}
.booked-session-card-tag-wrap.almost{
    background: #FFDFCC;
    color: #B87F5F;
}
.booked-session-card-tag-wrap.fully{
    background: #E4F6EA;
    color: #2A8751;
}
.schedule-cards .booked-session-card-top .slot-icon {
    margin-bottom: 10px;
}
.schedule-cards .booked-session-card-top .dash-empty-sess-head {
    color: #333;
}
.create-new-schedule-bottom-bottom-empty .dash-emp-no-sess{
    max-width: 45ch;
}
.create-new-schedule-bottom-bottom-empty .dash-emp-no-sess-body{
    max-width: 33ch;
    margin: 0 auto;
}
.create-new-schedule-bottom-bottom-empty .manage-availability-top-right{
    padding-top: 24px;
}
.greenCTABox {
    padding: 32px 24px;
    border-radius: 8px;
    background: #24584B;
    position: relative;
    margin: 60px 0;
}
.greenCTABox .gctab-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.gctab-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.gctab-in {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    text-align: center;
    position: relative;
    z-index: 1;
}
.gctab-in .gctabi-heading h5 {
    color: #FFF;
}
.gctabi-heading p {
    margin-top: 10px;
    color: #E0E0E0;
    font-size: 16px;
}
.gctab-in button.primary-btn {
    background-color: #FFF;
    color: #222;
    font-weight: 700;
    padding: 6px 21px;
    display: inline-flex;
    gap: 6px;
}
.gb-border {
    border-bottom: 1px solid #E3E3E3;
}
/* Reviews CSS */
.dash-reviews-wrap {
    padding-top: 44px;
    padding-bottom: 44px;
    width: 80%;
}
.dash-reviews-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap;
}
.dash-reviews-sess-head {
    color: #222;
    font-family: var(--switzer);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}
.dash-reviews-sess-body {
    padding-top: 6px;
}
.dash-reviews-sess-body p {
    color: #777;
    font-family: var(--nunito);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%;
    letter-spacing: 0.04px;
}
.filled-reviews-wrap {
    padding: 40px 0;
}
.filled-reviews-wrap .avg-reviews-box {
    padding: 30px 24px;
    border-radius: 8px;
    background: #24584B;
}
.avg-reviews-box .arb-in {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.arb-in .arbi-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 18px;
}
.arbi-left .arbi-stars {
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.arbi-stars .arbi-star-number {
    color: #A8DD73;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 36px */
    text-transform: capitalize;
}
.arbi-star-stars {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1px;
}
.arbi-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.arbi-content-head h6 {
    color: #FFF;
    margin-top: 13px;
}
.arbi-content-para p {
    color: #C2D7D1;
}
.arbi-right , .arbi-right .arbi-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}
.reviews-session-card-wrap {
    margin-top: 40px;
}
.reviews-session-card {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.reviews-session-card .slci-main {
    padding: 24px 36px;
}
.mentor-earnings-wrap{
    padding-left: 72px;
    padding-top: 36px;
    padding-bottom: 70px;
}
.mentor-earnings-tabs{
    padding-top: 25px;
}
.mentor-earnings-wallet{
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    max-width: 317px;
    background-color: #FFD686;
}
.mentor-earnings-wallet.disabled{
    background-color:#FFF;
}
.mentor-earnings-wallet.disabled .mentor-earnings-wallet-balance{
    color: #999;
}
.mentor-lifetime-earnings{
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    width: 295px;
    display: flex;
    align-items: center;
}
.mentor-lifetime-earnings-wrap{
    padding: 26px 18px;
    width: 100%;
}
.mentor-earnings-wallet-wrap{
    padding: 26px 24px;
}
.mentor-earnings-wallet-head-wrap{
    display: flex;
    align-items: center;
    gap: 12px;
}
.mentor-earnings-wallet-head{
    color: #655434;
    font-family: var(--switzer);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 144%;
    letter-spacing: 0.36px;
}
.mentor-earnings-wallet.disabled .mentor-earnings-wallet-head{
    color: #888;
}
.mentor-earnings-wallet-svg{
    display: flex;
}
.mentor-earnings-wallet-balance{
    font-family: var(--switzer);
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    padding-top: 16px;
    color: #333;
}
.mentor-lifetime-earnings.disabled .mentor-earnings-wallet-balance{
    color: #999;
}
.mentor-earnings-wallet-text p{
    color: #7A6F5C;
    font-family:var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%;
    letter-spacing: 0.24px;
}
.mentor-earnings-wallet.disabled .mentor-earnings-wallet-text p{
    color: #999;
}
.mentor-earnings-wallet-middle{
    padding: 20px 0;
    border-bottom: 1px solid #E3E3E3;
}
.mentor-earnings-wallet-btn{
    padding-top: 12px;
}
.mentor-earnings-wallet-btn .primary-btn{
    background-color: #DDD;
    color: #999;
    padding: 6px 21px;
}
.mentor-earnings-wallet.disabled .mentor-earnings-wallet-btn .primary-btn{
    background-color: #F0F0F0;
    color: #999;
    pointer-events: none;
}
.mentor-earnings-wallet .mentor-earnings-wallet-btn .primary-btn{
    pointer-events: none;
}
.mentor-earnings-wallet.withdraw .mentor-earnings-wallet-btn .primary-btn{
    background-color: #222;
    color: #FFF;
    pointer-events: all;
}
.mentor-earnings-wallet-bottom{
    padding-top: 20px;
}
.mentor-earnings-wallet-pending-amt{
    border-radius: 4px;
    display: inline-flex;
    padding: 8px 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 16px;
    color: #333;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.035px;
    border: 1px solid #FFEAC1;
    background: #FFDD9D;
}
.mentor-earnings-wallet.disabled .mentor-earnings-wallet-pending-amt{
    border: 1px solid #F0F0F0;
    background-color: transparent;
    color: #888;
}
.mentor-earnings-wallet-pending-amt span{
    font-weight: 600;
    margin-left: 7px;
}
.mentor-earnings-tab-wrap{
    padding-top: 32px;
}
.mentor-earnings-tab{
    display: flex;
    align-items: stretch;
    gap: 18px;
}
.mentor-lifetime-earnings-showing-from-label{
    color: #AAA;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%;
    letter-spacing: 0.24px;
}
.mentor-lifetime-earnings-showing-from-date{
    color: #555;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%; /* 18.24px */
    letter-spacing: 0.24px;
}
.mentor-lifetime-earnings-showing-from{
    padding-top: 13px;
}
.mentor-lifetime-earnings-pointers{
    padding-top: 32px;
}
.mentor-lifetime-earnings-pointer{
    border-bottom: 1px solid #E3E3E3;
    border-top: 1px solid #E3E3E3;
}
.mentor-lifetime-earnings-pointers .mentor-lifetime-earnings-pointer:not(:first-child){
   border-top: none;
}
.mentor-lifetime-earnings-pointer-head{
    color: #AAA;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%; /* 18.24px */
    letter-spacing: 0.24px;
}
.mentor-lifetime-earnings-pointer-number{
    color: #AAA;
    font-family:var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%; /* 18.24px */
    letter-spacing: 0.24px;
}
.mentor-lifetime-earnings-pointer-wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
}
.withdraw-amt-pop{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: .4s ease all;
    z-index: 999999999999;
}
.withdraw-amt-pop.active{
    opacity: 1;
    visibility: visible;
}
.avail-balance{
    color: #333;
    font-family: var(--switzer);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%;
    letter-spacing: 0.36px;
    padding-top: 10px;
}
.avail-balance span{
    color: #24584C;
    font-weight: 600;
    display: inline;
}
.withdraw-amt-pop .confirm-schedule-pop-wrap .login-head{
    color: #222;
}
.withdraw-amt-pop .slot-booking-note{
    margin-top: 24px;
}
.withdraw-amt-pop .slot-booking-note-wrap{
    align-items: start;
}
.withdrawPop-input-head{
    color: #555;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%;
    letter-spacing: 0.28px;
}
.withdrawPop-input{
    padding-top: 24px;
}
.withdrawPop-input-input{
    padding-top: 8px;
}
.withdrawPop-input-input input{
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    background: #F9F9F9;
    width: 100%;
    padding: 13px 15px;
}
.withdrawPop-input-checkbox{
    padding-top: 10px;
}
.withdrawPop-input-checkbox label{
  display: flex;
  justify-content: end;
  gap: 9px;
}
.withdrawPop-input-checkbox label input[type="checkbox"]{
    accent-color: darkgreen;
}
.withdrawPop-input-checkbox label span{
    color: #666;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    text-transform: capitalize;
}
.withdrawPop-btns {
    display: flex;
    padding-top: 24px;
    gap: 7px;
}
.withdrawPop-btns .primary-btn.cancel{
    border-radius: 4px;
    border: 1px solid #C0C0C0;
    text-transform: capitalize;
    font-weight: 500;
    padding: 6px 21px;
    width: 100%;
}
.withdrawPop-btns .primary-btn.withdraw{
    border-radius: 4px;
    font-weight: 500;
    padding: 6px 21px;
    background-color: #E4F6EA;
    color: #2A8751;
    font-weight: 700;
    width: 100%;
}
.mentor-transactions-tab .dash-emp-no-sess-img{
    text-align: center;
}
.mentor-transactions-tab .dash-emp-no-sess-wrap{
    max-width: 421px;
    margin: 0 auto;
    padding-top: 84px;
}
.no-mentor-found{
    text-align: center;
}
.dashboard-messages-inbox-people{
    position: relative;
}
.dashboard-messages-inbox-people > svg{
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.payment-successful{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: .4s ease all;
    background: rgba(255, 255, 255, 0.75);
    z-index: 999999999999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.payment-successful-cont{
    border-radius: 8px;
    background: #FEFBF4;
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.10);
    position: relative;
    max-width: 675px;
    width: 100%;
}
.payment-successful-bottom{
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    background: linear-gradient(277deg, rgba(252, 252, 252, 0.23) 3.87%, rgba(252, 252, 252, 0.23) 99.1%);
    margin-top: 40px;
    margin-bottom: 31px;
    margin-left: 58px;
    margin-right: 58px;
}
.payment-successful-bottom-cont{
    padding: 20px 22px;
}
.payment-successful-bottom-head{
    color: #333;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
}
.payment-success-head{
    color: #222;
    text-align: center;
    font-family: var(--switzer);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 144%; 
    letter-spacing: 0.36px;
}
.payment-sucess-body{
    color: #666;
    text-align: center;
    font-family: var(--nunito);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
    padding-top: 12px;
    max-width: 30ch;
    margin: 0 auto;
}
.payment-successful-top{
    padding-top: 53px;
    max-width: 35ch;
    margin: 0 auto;
}
.payment-success-img{
    position: absolute;
    top: 12px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
}
.payment-successful-bottom-icon{
    display: flex;
}
.payment-successful-bottom-left{
    gap: 12px;
}
.payment-success-cross{
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;
}
/* Reviews CSS */

/* Purchase History CSS */
.ph-wrap {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    gap:16px;
    width: 90%;
}
.ph-wrap .slci-main {
    padding: 24px 30px;
}
.ph-wrap .slci-head {
    display: flex;
    align-items: center;
    gap: 20px;
}
.ph-wrap .slci-head .slct-image {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    overflow: hidden;
}
.ph-wrap .slci-head .slct-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.ph-wrap .slci-head .slct-data {
    width: calc(100% - 75px);
}
.ph-wrap .slci-main .details-btn {
    margin-top: 20px;
    display: flex;
    font-family: var(--switzer);
    font-size: 12px;
    gap: 8px;
    font-weight: 500;
    cursor: pointer;
    align-items: center;
}
.session-list-card-in .details-btn svg {
    transition: .3s ease all;
}
.session-list-card-in.active .details-btn svg {
    transform: rotate(90deg);
}
.ph-wrap .slci-in-content {
    margin-top: 0;
}
.ph-wrap .ses-cost {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px;
}
.ph-wrap .ses-cost > div {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    font-weight: 500;
    color: #24584B;
    font-family: var(--switzer);
}
.ph-wrap .ses-cost > div span {
    color: #444444;
    font-family: var(--nunito);
    font-weight: 600;
}
/* Purchase History CSS */

.dashtabsbutton {
    display: none;
}
.rt-mob-details {
    display: none;
}

@keyframes fadeEffect {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }  
}
@media only screen and (max-width:1400px){
    .dashboard-empty-state {
        padding: 36px 50px 70px 72px;
    }
    .profile-dashboard-left-wrapper {
        padding: 67px 18px 50px 61px;
    }
    .create-new-schedule {
        padding: 74px 26px 70px 72px;
    }
}

@media only screen and (max-width:1300px){
    .dashboard-empty-state {
        padding: 36px 35px 70px 35px;
    }
    .schedule-details-summary {
        width: 40%;
    }
    .schedule-details-set{
        width: 60%;
    }
}

@media only screen and (max-width:1200px){
    .dash-emp-trm-slider-right{
        width: 50%;
    }
    .dash-emp-trm-slider-left{
        width: 50%;
    }
    .profile-dashboard-left-wrapper {
        padding: 67px 18px 50px 18px;
    }
    .dashboard-messages-tabs-content .react-tabs__tab-panel {
        height: 680px;
    }
    .schedule-details-set-dropdown-left, .schedule-details-set-dropdown-right {
        width: 100%;
    }
}
@media only screen and (max-width:1100px){
    .profile-name-text{
        font-size: 18px;
    }
    .profile-name-photo{
        width: 62px;
        height: 62px;
    }
    .profile-dashboard-tabs ul li div,.profile-dashboard-tabs .logOut div {
        font-size: 18px;
    }
    .dash-empty-name-text{
        font-size: 26px;
    }
    .dash-empty-body p{
        font-size: 14px;
    }
    .dash-empty-sess-head{
        font-size: 18px;
    }
    .dash-empty-sess-body p {
        font-size: 14px;
    }
    .dash-emp-no-sess-head {
        font-size: 16px;
    }
    .schedule-details-summary-wrap {
        padding: 26px 20px;
    }

}

@media only screen and (max-width:991px){
    .profile-dashboard-wrapper{
        flex-direction: column;
    }
    .profile-dashboard-left {
        border-bottom: 1px solid #E3E3E3;
        border-right: none;
        width: 100%;
    }
    .profile-dashboard-right{
        width: 100%;
    }
    /* .profile-dashboard-tabs ul{
        flex-direction: row;
        scroll-snap-type: x mandatory;
        overflow: hidden;
        overflow-x: auto;
        gap: 0;
    } */
    .profile-dashboard-tabs ul li{
        scroll-snap-align: start;
        flex-shrink: 0;
    }
    .profile-dashboard{
        margin-top: 71px;
    }
    .favourite-mentors-list-grid{
        grid-template-columns: repeat(2, 1fr);
    }
    .profile-dashboard-tabs .logOut {
        margin-top: 0px;
        margin-bottom: 30px;
    }
    .dash-empty-sessions-content .newblogbtn {
        position: relative;
        margin-top: 20px;
    }
    .dash-reviews-wrap {
        width: 100%;
    }
    .schedule-details-set-dropdown-left, .schedule-details-set-dropdown-right {
        width: 100%;
    }
    .profile-dashboard-tabs {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        background-color: #fff;
        transition: .3s ease all;
        transform: translateX(-100%);
        z-index: 99;
        overflow: hidden;
        overflow-y: auto;
        padding: 0;
        margin-bottom: 50px;
    }
    .profile-dashboard-tabs.toggledClass {
        transform: translateX(0);
    }
    .profile-dashboard-tabs ul {
        gap: 0;
        margin-bottom: 0;
    }
    .profile-dashboard-tabs ul li {
        margin-bottom: 12px;
        padding-bottom: 16px;
        border-bottom: 1px solid #E3E3E3;
    }
    .dashtabsbutton {
        display: inline-flex;
        margin-top: 20px;
        text-transform: capitalize;
        font-weight: 600;
        cursor: pointer;
        align-items: center;
        padding: 8px 16px;
        background: #ffc14b;
        border-radius: 10px;
        font-size: 15px;
    }
    .dashtabsbutton svg {
        width: 16px;
        margin-left: 10px;
    }
    .rt-mob-details {
        display: flex;
        margin-bottom: 30px;
        padding: 10px 20px;
        align-items: center;
        justify-content: space-between;
        margin-top: 110px;
    }
    .rt-mob-details .rtmd-left {
        font-size: 18px;
        font-weight: 500;
    }
    .rt-mob-details .rtmd-right .login-close-button {
        position: relative;
        top: inherit;
        right: inherit;
    }
}

@media only screen and (max-width:767px){
    .dashboard-empty-state {
        padding: 36px 20px 70px 20px;
    }
    .dash-emp-trm-slider{
        flex-direction: column-reverse;
        gap: 20px;
    }
    .dash-emp-trm-slider-left{
        width: 100%;
        margin-right: 0;
    }
    .dash-emp-trm-slider-right{
        width: 100%;
    }
    .favourite-mentors-list-grid{
        grid-template-columns: repeat(1, 1fr);
    }
    .mentor-earnings-wrap {
        padding: 30px;
    }
    .mentor-earnings-tab {
        flex-direction: column;
    }
    .mentor-earnings-wallet {
        max-width: 100%;
    }
    .mentor-lifetime-earnings {
        width: 100%;
    }
    .dashboard-messages-wrap .react-tabs{
        flex-direction: column;
    }
    .dashboard-messages-left{
        width: 100%;
        height: 100%;
    }
    .dashboard-messages-right{
        width: 100%;
    }
    .dashboard-messages-inbox-right-empty .dash-emp-no-sess{
        padding: 100px 0;
    }
    .dashboard-messages-inbox-peep{
        display: flex;
        width: 100%;
        scroll-snap-type: x mandatory;
        border-bottom: 1px solid #E3E3E3;
        overflow: hidden;
        overflow-x: scroll;
    }
    .dashboard-messages-inbox-peep-wrap{
        flex-shrink: 0;
        scroll-snap-align: start;
    }
    .dashboard-messages-inbox-peep-wrap{
        border-bottom: none;
    }
    .dashboard-messages-tabs-content .react-tabs__tab-panel{
        display: flex;
        overflow: hidden;
        scroll-snap-type: x mandatory;
        width: 100%;
        overflow-x: scroll;
    }
    .dashboard-messages-tabs-content .react-tabs__tab-panel .dashboard-messages-inbox-peep-wrap{
        scroll-snap-align: start;
        flex-shrink: 0;
    }
    .create-new-schedule {
        padding: 78px 26px 70px 26px;
    }
    .create-new-schedule-middle{
        flex-direction: column;
    }
    .schedule-details-summary {
        width: 100%;
    }
    .schedule-details-set{
        width: 100%;
    }
    .schedule-deatils-set-wrap {
        padding: 40px 20px;
    }
    .schedule-details-set-dropdown-label{
        font-size: 10px;
    }
    .manage-availability-top {
        flex-direction: column;
        gap: 30px;
        align-items: start;
    }
    .show-schedule-button button{
        padding: 10px 15px;
        font-size: 10px;
    }
    .create-new-schedule-top .dashboard-empty-state-banner-wrapper{
        flex-direction: column;
        align-items: start;
        gap: 20px;
    }
    .create-new-schedule-top-btn{
        padding-right: 0px;
        padding-left: 16px;
    }
    .dashboard-empty-state-banner{
        padding: 20px 0;
    }
    .dash-empty-name-text {
        font-size: 22px;
    }
    .schedule-details-set-dropdown-left, .schedule-details-set-dropdown-right {
        width: 100%;
    }
    .dashboard-messages-tabs-content .react-tabs__tab-panel {
        height: auto;
    }
    .dashboard-messages-inbox-peep-wrap .dashboard-messages-inbox-peep-details {
        width: inherit;
    }
    .ph-wrap {
        width: 100%;
    }
    .ph-wrap .slci-head {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }
    .ph-wrap .slci-head .slct-data {
        width: 100%;
    }
    .ph-wrap .ses-cost {
        gap: 8px;
    }
}