.insage-become-a-mentor .header .label{
    color: #FFFFFF;
}
.insage-become-a-mentor .header .logo-name a{
    color: #FFFFFF;
}
.insage-become-a-mentor .header .header-left-dropdown svg path{
    stroke: #FFFFFF;
}
.insage-become-a-mentor .header .header-search svg path {
    fill: #FFFFFF;
} 
.insage-become-a-mentor .header.active .header-left-dropdown svg path{
    stroke: #222222;
}
.insage-become-a-mentor .header.active .logo-name a{
    color: #222222;
}
.insage-become-a-mentor .header.active .label{
    color: #222222;
}
.insage-become-a-mentor .header.active .header-search svg path{
    fill: #222222;
}
.insage-become-a-mentor .header .header-btn a{
    background-color: #FFFFFF;
    /* color: #222222; */
}
.insage-become-a-mentor .header.active .header-btn a { 
    background-color: #222222;
    color: #FFFFFF;
}
.insage-become-a-mentor .header.active .header-btn a svg path {
    stroke:#fff;
}
.insage-become-a-mentor .header .header-hamburger-icon-top, .insage-become-a-mentor .header .header-hamburger-icon-middle, .insage-become-a-mentor .header .header-hamburger-icon-bottom{
    border: 1.5px solid white;
}
.insage-become-a-mentor .header.active .header-hamburger-icon-top, .insage-become-a-mentor .header.active .header-hamburger-icon-middle, .insage-become-a-mentor .header.active .header-hamburger-icon-bottom{
    border: 1.5px solid black;
}
.bam-bannner-bg{
    position: absolute;
    right: 0;
    bottom: -3px;
    width: 60%;
}
.insage-become-a-mentor-banner .custom-container-fluid{
    padding: 0px;
    padding-bottom: 120px;
    
}
.insage-become-a-mentor-banner{
    min-height: 90vh;
}
.insage-become-a-mentor-banner .container{
    position: relative;
    z-index: 1;
}
.bam-bannner-bg svg{
    width: 100%;
    height: 100%;
    object-fit:cover;
}
.insage-become-a-mentor-banner{
    padding: 150px 0 0 0;
    background-color: #24584B;
    position: relative;
}
.bam-banner-heading{
    overflow: hidden;
    padding-bottom: 6px;
}
.bam-banner-heading h2{
    text-align: left;
    position: relative;
}
.bam-banner-heading h2 span{
    position: absolute;
    top: 30%;
    right: 5%;

}

.bam-banner-body{
    padding-top: 15px;
    overflow: hidden;
}
.bam-banner-btn{
    padding-top: 48px;
}
.bam-banner-right-wrapper{
    position: relative;
}
.bam-banner-img-svg{
    position: absolute;
    bottom: -40px;
}
.insage-bam-banner-left{
    flex: 1 1 60%;
}
.insage-bam-banner-right{
    flex: 1 1 40%;
}
.insage-bam-banner-left-wrapper{
    width: 59ch;
}
.bam-banner-ticker .marquee-wrap{
  position: absolute;
  width: 100%;
  bottom: -8%;
}
.bam-second-section{
    padding: 200px 0;
}
.bam-second-section-body{
    padding-top: 30px;
    width: 60%;
    margin: 0 auto;
    text-align: center;
}
.bam-second-section-bottom{
    padding-top: 55px;
    width: 90%;
    margin: 0 auto;
}
.bam-second-section-bottom-wrapper{
    position: relative;
}
.bam-second-section-bottom-wrapper .bam-second-section-svg{
    position: absolute;
    top: 5%;
    left: -5%;
}
.bam-second-section-bottom .wwo-top-mentors-heading{
    font-weight: 700;
    font-size: 20px;
    color: #FFFFFF;
    padding-bottom: 20px;
}
.bam-second-section-bottom .wwo-top-mentor-name{
    font-size: 18px;
    font-weight: 700;
}
.bam-second-section-bottom .wwo-top-mentor-designation, .bam-second-section-bottom .wwo-top-mentor-designation span{
    font-size: 10px;
    color:#777777;
}
.bam-second-section-bottom .wwo-top-mentor-designation{
    display: flex;
    gap: 2px;
}
.bam-second-section-bottom .wwo-top-mentor-image{
    width: 62px;
    height: 62px;
}
.bam-second-section-bottom .wwo-bottom-left-bottom-mentors{
    border-radius: 26px;
    background:#FEC14B;
    width: 320px;
}
.bam-second-section-bottom .wwo-top-mentor-mentor{
    padding: 20px 0;
    gap:19px;
}
.bam-second-section-bottom .wwo-top-mentors-list{
    background-color:  #FFFFFF;
    padding-top: 0px;
}
.bam-second-section-bottom .wwo-bottom-left-bottom-mentors-wrapper{
    padding-top: 20px;
    padding-bottom: 0px;
}
.bam-get-ahead .custom-container-fluid{
    padding: 0;
    padding-bottom: 100px;
}
.bam-get-ahead .container{
    z-index: 1;
}
.bam-get-ahead{
    padding-bottom: 100px;
    position: relative;
}
.bam-get-ahead .insage-knowledge-at-work-left{
    padding-left: 45px;
}
.bam-get-ahead-bg{
    position: absolute;
    right: 0;
    bottom: 15%;
    width: 20%;
}
.bam-get-ahead-bg svg{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.insage-become-a-mentor .about-empowering{
    padding:150px 0 0 0;
}
.insage-become-a-mentor .insage-testimonials{
    background-color: #FF7252;
}
.insage-become-a-mentor .testimonials-content-body h3{
    color: #FFFFFF;
}
.insage-become-a-mentor .testimonials-content-name h6{
    color: #FFFFFF;
}
.insage-become-a-mentor .testimonials-content-designation span{
    color: #FCFCFC;
}
.insage-become-a-mentor .testimonials-content-separator{
    background-color: #FF9881;
}

@media only screen and (max-width:1300px){
    .bam-banner-ticker .marquee-wrap{
        bottom: -7%;
    }
    .insage-bam-banner-left-wrapper {
        width: 61ch;
    }
}

@media only screen and (max-width:1200px){
    .insage-bam-banner-left-wrapper{
        width: 54ch;
    }
}

@media only screen and (max-width:991px){
    .insage-bam-banner-content-wrapper{
        flex-direction: column;
        gap: 70px;
    }
    .insage-bam-banner-left-wrapper{
        width: 50ch;
    }
    .bam-bannner-bg{
        width: 100%;
    }
    .bam-banner-ticker .marquee-wrap{
        bottom: -5%;
    }
    .bam-second-section-bottom-wrapper .bam-second-section-svg{
        width: 30%;
    }
    .bam-second-section-bottom .wwo-bottom-left-bottom-mentors{
        width: auto;
        position: static;
    }
    .bam-second-section-body{
        width: 90%;
    }
    .bam-get-ahead .insage-knowledge-at-work-left{
        padding-left: 0;
    }
    .bam-get-ahead-bg{
        width: 40%;
    }
}

@media only screen and (max-width:767px){
    .insage-bam-banner-left-wrapper{
        width: 100%;
    }
    .bam-banner-heading h2 span{
        top: 10%;
        right: 13%;
    }
    .bam-banner-img-svg{
        width: 70%;
    }
    .bam-second-section-body{
        width: 100%;
    }
    .bam-second-section-bottom {
        width: 100%;
    }
    .bam-second-section-bottom-wrapper .bam-second-section-svg {
        top: 0;
    }
    .insage-become-a-mentor-banner{
        padding: 120px 0 0 0;
    }
    .bam-second-section{
        padding: 150px 0;
    }
    
}