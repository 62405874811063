:root{
  --nunito:'Nunito Sans', sans-serif;
  --switzer:'Switzer', sans-serif;
}
*{
  margin: 0;
  padding: 0;
}

html,body {
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
}
body {
  font-family: var(--nunito);
  font-style: normal;
  font-weight: 400;
  height: 100%;
  color: #222222;
}
body.popup-open{
  overflow: hidden;
}
h1,h2,h3,h4,h5,h6 {
  font-family: var(--switzer);
  font-style: normal;
  margin: 0;
  padding: 0;
  color: black;
}
ul,ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
a:hover,a:focus {
  text-decoration: none;
}
a{
  text-decoration: none;
  color: inherit;
}
input{
  outline: none;
  box-sizing: border-box;
}
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

select{
  appearance: none;
  background-image: url('https://simpletesting.website/insage-images/drop-select.svg');
  background-position: right 15px center;
  background-repeat: no-repeat;
  outline: none;
  padding-right: 20px;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
  transition: .3s ease all;
  background: inherit;
}
h1{
  font-size: 180px;
  line-height: 111.5%; 
  letter-spacing: -0.01em;
  text-transform: uppercase;
  font-feature-settings: 'ss08' on;
}
h2{
  font-size: 72px;
  line-height: 104%;
  text-align: center;
  font-feature-settings: 'ss08' on;
}
h3{
  font-size: 52px;
  line-height: 104%;
  font-feature-settings: 'ss08' on;
}
h4{
  font-weight: 500;
  font-size: 40px;
  line-height: 104%;
  letter-spacing: 0.01em;
  font-feature-settings: 'ss08' on;
}
h5{
  font-weight: 600;
  font-size: 30px;
  line-height: 88%;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  font-feature-settings: 'ss08' on;
}
h6{
  font-weight: 700;
  font-size: 24px;
  line-height: 96%;
  text-transform: capitalize;
  font-feature-settings: 'ss08' on;
}
p{
  font-size: 16px;
  line-height: 144%;
}
p.small{
  font-size: 14px;
}
.label {
  font-family: var(--switzer);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 152%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.primary-btn {
  display: inline-block;
  padding: 10px 32px;
  border-radius: 4px;
  overflow: hidden;
  align-items: center;
  font-family: var(--switzer);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 152%;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  z-index: 0;
  outline: none;
  transition: .8s cubic-bezier(0.9, 0.03, 0.47, 0.89);
  position: relative;
}
.primary-btn:hover::before{
  top: 0;
}
.primary-btn:hover::after{
  top: 0;
  transition-delay: .2s;
}
.primary-btn::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 100%;
    left: 0;
    transition: .4s cubic-bezier(0.9, 0.03, 0.47, 0.89);
    z-index: -1;
}
.primary-btn::after{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 100%;
  left: 0;
  transition: .4s cubic-bezier(0.9, 0.03, 0.47, 0.89);
  z-index: -2;
  transition-delay: .3s;
}
.primary-btn.black::after, .primary-btn.black::before{
  background-color: #FFECA1;
}
.primary-btn.black{
  background-color: #222222;
  color: #FFFFFF;
}
.primary-btn.black:hover{
  color: #222222;
}
.primary-btn.green{
  background-color: #E4F6EA;;
  color: #2A8751;
}
.primary-btn.green:hover{
  color: #fff;
  background-color: #222222;
}
.primary-btn.green::after, .primary-btn.green::before{
  background-color: #222222;
}
.primary-btn.white{
  background-color:#FFFFFF;
  color:#222222;
}
.primary-btn.white::after, .primary-btn.white::before{
  background-color:#222222;
}
.primary-btn.white:hover{
  color: #FFFFFF;
}
.primary-btn.border {
  background-color: transparent;
  color:#222222;
  border: 1px solid #222222;
}
.primary-btn.border.grey {
  color:#222222;
  border-color: #C0C0C0;
}
.primary-btn.border.small {
  padding: 6px 20px;
  font-weight: 500;
}
.primary-btn.border::after, .primary-btn.border::before{
  background-color: #222222;
}
.primary-btn.border:hover{
  border: 1px solid #FFFFFF;
  color:#FFFFFF;
}
.primary-btn.border svg path{
  transition: .8s cubic-bezier(0.9, 0.03, 0.47, 0.89);
}
.primary-btn.border:hover svg path{
  stroke: #ffff;
}
.primary-btn.insage{
  background-color: #FEC14B;
  color:#222222;
}
.primary-btn.insage::after, .primary-btn.insage::before{
  background-color: #24584B;
}
.primary-btn.insage:hover{
  color:#FFFFFF;
}
.primary-btn-2{
  padding: 10px 41px;
}
.popup-btn {
  display: inline-block;
  border-radius: 4px;
  overflow: hidden;
  align-items: center;
  font-family: var(--switzer);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 152%;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  z-index: 0;
  outline: none;
  transition: .3s ease all;
  position: relative;
  padding: 6px 21px;
  color: #222;
  border: 1px solid transparent;
}
.popup-btn.save-btn {
  background-color: #E4F6EA;
  color: #2A8751;
}
.popup-btn.save-btn:hover {
  color: #E4F6EA;
  background-color: #2A8751;
}
.popup-btn.cancel-btn {
  background-color: #F8E0DD;
  color: #F45E5C;
}
.popup-btn.cancel-btn:hover {
  color: #F8E0DD;
  background-color: #F45E5C;
}
.popup-btn.line-btn {
  background-color: #fff;
  color: #222;
  border: 1px solid #C0C0C0;
}
.popup-btn.line-btn:hover {
  color: #fff;
  background-color: #222;
  border-color: #C0C0C0;
}
main {
  background: #FEFBF4;
}
.prev-btn, .next-btn{
  width: 56.87px;
  height: 56.87px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: .4s ease all;
  position: absolute;
  z-index: 9;
}
.prev-btn svg, .next-btn svg{
  width: 60%;
  height: 60%;
  object-fit: cover;
}
.prev-btn.yellow, .next-btn.yellow{
  border: 0.789809px solid #FEC14B;
}
.prev-btn.swiper-button-disabled, .next-btn.swiper-button-disabled{
  opacity: 0.5;
}
.prev-btn.yellow svg path, .next-btn.yellow svg path{
  fill:#FEC14B;
}
.prev-btn.black, .next-btn.black{
  border: 0.789809px solid #222222;
}
.prev-btn.black svg path, .next-btn.black svg path{
  fill:#222222;
}
span{
  display: block;
}
a.isg-guide-load-more{
  font-family:var(--switzer);
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #222222;
  position: relative;
  padding-bottom: 5px;
}
a.isg-guide-load-more:hover::after{
  animation: width .4s linear;
}
a.isg-guide-load-more::after{
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 1px;
  width: 100%;
  background-color: #222222;
}
a.download-guide-btn{
  display: inline-flex;
  background-color: #222222;
  border-radius: 4px;
  overflow: hidden;
  padding: 6px 21px;
  font-family: var(--switzer);
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #FFFFFF;
}
.custom-sort-btn {
    padding: 6px 12px;
    border-radius: 4px;
    border: 1px solid #727272;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
}
.Toastify{
  position: relative;
  z-index: 999999999999;
}
.custom-toast-top span{
  color: #299F60;
  font-family: var(--switzer);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.28px;
}
.Toastify__toast.custom-toast-container-error .custom-toast-top span{
  color: #F45E5C;
}
.custom-toast-top{
  gap: 8px;
}
.custom-toast-top svg{
  width: 16px;
  height: 16px;
}
.Toastify__toast-body{
  margin: 0px !important;
  padding: 0px !important;
  align-items: start !important;
}
.Toastify__toast{
  border-radius: 4px !important;
  border: 1px solid #299F60 !important;
  background: #E4F6EA !important;
}
.Toastify__toast.custom-toast-container-error{
    border-radius: 4px !important;
    border: 1px solid #F45E5C !important;
    background: #F8E0DD !important;
}
.Toastify__toast::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 100%;
  background-color: #299F60;
  left: 0;
  top: 0;
}
.custom-toast-container-error.Toastify__toast::before{
  background-color: #F45E5C;
}
.Toastify__toast-icon{
  display: none !important;
}
.custom-toast{
  display: flex;
  flex-direction: column;
  gap:4px;
  margin-left: 8px;
}
.custom-toast-bot{
  margin-left: 24px;
}
.custom-toast-bot span{
  color: #53AF7E;
  font-family: var(--nunito);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 152%; /* 18.24px */
  letter-spacing: 0.24px;
}
.Toastify__toast.custom-toast-container-error .custom-toast-bot span{
  color: #E78382;
}
.Toastify__close-button--light{
  opacity: 1 !important;
}
.Toastify__close-button--light svg path{
  fill: #299F60;
}
.Toastify__toast.custom-toast-container-error .Toastify__close-button--light svg path{
  fill: #F45E5C;
}
.Toastify__progress-bar{
  opacity: 0 !important;
}
@keyframes width {
  from{
    width: 0;
  }
  to{
    width: 100%;
  }
  
}
/* Utilities */
.flex{
  display: flex;
}
.flex-c{
  flex-direction: column;
}
.flex-jsc{
  justify-content: center;
}
.flex-jsb{
  justify-content: space-between;
}
.flex-jse{
  justify-content: end;
}
.align{
  align-items: center;
}
.align-e{
  align-items: end;
}
.align-b{
  align-items: baseline;
}
.align-s{
  align-items: start;
}
.custom-container-fluid {
  padding: 0 30px;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
}
.container {
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 96px;
}
.pad-top{
  padding-top: 140px;
}
.pad-l-0{
  padding-left: 0 !important;
}
.pad-r-0{
  padding-right: 0;
}
.pad-vot{
  padding-bottom: 140px;
}
.img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.img-cont{
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.text-align{
  text-align: center;
}
.head-white{
  color: #FFFEFB;
}
.col-white{
  color: #FFFFFF;
}
.col-p{
  color:#555555;
}

/* Static Page Css */

.static-page .heading{
  text-align: center;
}
.static-page{
  padding-top: 160px;
  padding-bottom: 135px;
}
.static-page .static-content h6{
  color: #484848;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 144%; /* 25.92px */
  letter-spacing: 0.36px;
}
.static-page .static-content .scw-para{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.static-page .static-content .scw-para p{
  color: #555;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
  letter-spacing: 0.18px;
}
.static-page .static-content-wrapper{
  display: flex;
  flex-direction: column;
  gap: 90px;
  margin: 0 auto;
  margin-top: 96px;
  max-width: 82ch;
}
.loadmore-btn {
  margin-top: 60px;
  text-align: center;

}

/* Static Page Css */

/* Responsive CSS */

@media only screen and (max-width:1400px){
  .container{
    padding: 0 60px;
  }
}

@media only screen and (max-width:1300px){
 
  h1{
    font-size: 160px;
  }
  h2{
    font-size: 68px;
  }
  h3{
    font-size: 48px;
  }
  h4{
    font-size: 38px;
  }
  h5{
    font-size: 28px;
  }
  .container{
    padding: 0 40px;
  }
}

@media only screen and (max-width:1200px){
  h1{
    font-size: 140px;
  }
  h2{
    font-size: 64px;
  }
  h3{
    font-size: 44px;
  }
  h6{
    font-size: 22px;
  }
  h5{
    font-size: 26px;
  }
  .static-page .static-content .scw-para p, .static-page .static-content h6{
    font-size: 16px;
  }
  .static-page {
    padding-top: 140px;
    padding-bottom: 115px;
  }
  .static-page .static-content-wrapper{
    max-width: 73ch;
    margin-top: 90px;
  }
  .static-page .static-content-wrapper{
    gap: 80px;
    margin-top: 75px;
  }
  .pad-vot {
    padding-bottom: 100px;
  }
}

@media only screen and (max-width:1100px){
  h1{
    font-size: 120px;
  }
  h3{
    font-size: 42px;
  }
}

@media only screen and (max-width:991px){
  .custom-container-fluid{
    padding: 0 20px;
  }
  p{
    font-size: 14px;
  }
  p.small{
    font-size: 12px;
  }
  h1{
    font-size: 90px;
  }
  h2{
    font-size: 60px;
  }
  .pad-top{
    padding-top: 110px;
  }
  h3{
    font-size: 40px;
  }
  h4{
    font-size: 36px;
  }
  h5{
    font-size: 24px;
  }
  .container{
    padding: 0 30px;
  }
  .prev-btn, .next-btn{
    width: 46px;
    height: 46px;
  }

}

@media only screen and (max-width:767px){
  h2{
    font-size: 56px;
  }
  h3{
    font-size: 36px;
  }
  h4{
    font-size: 34px;
  }
  h5{
    font-size: 22px;
  }
  .container{
    padding: 0 20px;
  }
  .static-page {
    padding-top: 120px;
    padding-bottom: 95px;
  }
  .static-page .static-content-wrapper {
    gap: 55px;
    margin-top: 60px;
  }
  .static-page .static-content .scw-para p, .static-page .static-content h6 {
    font-size: 14px;
  }
  .static-page .static-content .scw-para {
    margin-top: 16px;
    gap: 16px;
  }
}
@media only screen and (max-width:575px){
  h1{
    font-size: 84px;
  }
  h2{
    font-size: 52px;
  }
  h3{
    font-size: 30px;
  }
  .label{
    font-size: 12px;
  }
}
