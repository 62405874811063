.blog-container{
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 20px;
}
.blogInnersim .blog-container{
    margin-left: 0;}
.blog-container-for-line {
    margin: 0 auto;
    padding: 0 20px;
    margin-right: calc((100vw - 900px)/2);
}
.blogs-all-wrapper{
    padding-top: 100px;
    padding-bottom: 100px;
}
.bb-wrapper{
    margin-top: 72px;
    display: flex;
    align-items: flex-start;
    gap: 32px;
}
.bb-wrapper .bb-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    inset: 0;
}
.bb-wrapper .bb-img{
    width: 50%;
    position: relative;
    padding-top: 45%;
    border-radius: 8px;
    overflow: hidden;
}
.bb-wrapper .bb-txt{
    width: 50%;
}
.auth-img{
    height: 22px;
    width: 22px;
    border-radius: 50%;
    overflow: hidden;
}
.auth-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.cat-name p{
    color: #222;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    text-transform: capitalize;
}
.cat-name{
    border-radius: 99px;
    background: #FFE3C2;
    overflow: hidden;
    padding: 4px 14px;
    display: inline-flex;
}
.bb-wrapper .bb-txt .blog-name{
    margin-top: 10px;
}
.bb-wrapper .bb-txt .blog-name h3{
    font-size: 40px;
    font-weight: 600;
}
.bb-wrapper .bb-txt .blog-para{
    margin-top: 16px;
    max-width: 40ch;
}
.bb-wrapper .bb-txt .blog-para > p{
    color: #222;
    overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}
.bb-wrapper .bb-txt .blog-auth-wrapper{
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}
.bb-wrapper .bb-txt .blog-auth-wrapper p{
    color: #777;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%; /* 18.24px */
    letter-spacing: 0.24px;
}
.auth-name p{
    color: #222;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
    font-family: var(--switzer);
}
.bb-wrapper .bb-txt .blog-auth-wrapper .dot{
    width: 2px;
    height: 2px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #C0C0C0;
}
.bb-wrapper .bb-txt a{
    margin-top: 40px;
}
section.blog-create .createBlog{
    background: #24584B;
    padding: 24px;
    text-align: center;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9;
}
section.blog-create .write-blog svg{
    width: 12px;
    height: 12px;
}
section.blog-create .cb-head {
    max-width: 40ch;
    margin: 0 auto;
}
section.blog-create .cb-head h5{
    color: #FFF;
    text-align: center;
    font-family: var(--switzer);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
section.blog-create .write-blog{
    display: inline-flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    padding: 5px 13px;
    margin-top: 14px;
    border-radius: 8px;
    border: 1px solid #2B6456;
}
section.blog-create .write-blog p{
    color: #FFF;
    text-align: center;
    font-family: var(--nunito);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 152%; /* 18.24px */
    letter-spacing: 0.24px;
}
section.blog-create .primary-btn{
    margin-top: 28px;
}
section.blog-create .background1 img, section.blog-create .background2 img{
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
}
section.blog-create .background1{
    position: absolute;
    left: 10%;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    z-index: -1;
}
section.blog-create .background1 .back1{
    width: 14px;
    height: 14px;
    transform: rotate(175deg);
}
section.blog-create .background1 .back2{
    width: 40.439px;
    height: 42.267px; 
    margin-top: -39px;
    margin-right: 30px;
}
section.blog-create .background1 .back3{
    width: 15px;
    height: 15px;
}
section.blog-create .background2{
    position: absolute;
    right: 10%;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    z-index: -1;
}
section.blog-create .background2 .back1{
    width: 42px;
    height: 42px;
    transform: rotate(75deg);
}
section.blog-create .background2 .back2{
    width: 35.538px;
    height: 37.285px;
    margin-right: -25px;
    margin-top: 55px;
}
section.blog-create .background2 .back3{
    width: 14px;
    height: 14px;
    margin-right: 80px;
    margin-top: -20px;
}
section.blog-create .background2 .back3 img{
    transform: rotate(-100deg);
}
section.blog-create .background2 .back2 img{
    transform: rotate(-56deg);
}
.blogs-all-wrapper .baw-wrapper{
    display: flex;
    flex-direction: column;
    gap: 88px;
    border-left: 1px solid #E3E3E3;
}
.blog-posts .tab-dropdown .tabs-wrapper{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
.blog-posts .tab-dropdown .tabs-wrapper .tabs{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    border-radius: 4px;
    border: 1px solid #727272;
    cursor: pointer;
    transition: .2s ease all;
}
.blog-posts .tab-dropdown .tabs-wrapper .tabs button {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
    color: #222;
    transition: .2s ease all;
    font-family: var(--nunito);
}
.blog-posts .tab-dropdown .tabs-wrapper .tabs svg {
    width: 10px;
    height: 10px;
    margin-right: 5px;
}
.blog-posts .tab-dropdown .tabs-wrapper .tabs.active-tabs svg path {
    stroke: #fff;
}
.blog-posts .tab-dropdown .tabs.active-tabs {
    background: #24584B;
    border-color: #24584B;
}
.blog-posts .tab-dropdown .tabs-wrapper .tabs.active-tabs button {
    color: #fff;
}
.blog-posts .tab-inner-wrapper{
    margin-top: 48px;
}
.blog-posts .tab-inner-wrapper .content{
    display: none;
}
.blog-posts .tab-inner-wrapper .content.active-content{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 41px;
    grid-row-gap: 96px;
}
.blog-posts .tab-inner-wrapper .content .img-wrapper {
    position: relative;
    padding-top: 100%;
    border-radius: 8px;
    overflow: hidden;
}
.blog-posts .tab-inner-wrapper .content .img-wrapper .bookmark{
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    background: #222;
    overflow: hidden;
    width: 34px;
    height: 34px;
    cursor: pointer;
}
.blog-posts .tab-inner-wrapper .content .img-wrapper .bookmark svg{
    width: 100%;
    height: auto;
    max-height: 100%;
}
.blog-posts .tab-inner-wrapper .content .img-wrapper .bookmark .yes-bookmark{
    display: none;
}
.blog-posts .tab-inner-wrapper .content .img-wrapper .bookmark.active .not-bookmark{
    display: none;
}
.blog-posts .tab-inner-wrapper .content .img-wrapper .bookmark.active .yes-bookmark{
    display: block;
}
.blog-posts .tab-inner-wrapper .content .img-wrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    inset: 0;
}
.blog-posts .tab-inner-wrapper .content .item{
    display: flex;
    flex-direction: column;
    gap: 22px;
}
.blog-posts .tab-inner-wrapper .content .item .auth-cat{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
}
.blog-posts .tab-inner-wrapper .content .item .auth-cat .auth-img-name{
    display: flex;
    align-items: center;
    gap: 5px;
}
.blog-posts .tab-inner-wrapper .content .item .blog-desp{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.blog-posts .tab-inner-wrapper .content .item .blog-title h5{
    color: #222;
    font-family: var(--switzer);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* text-decoration: underline; */
    text-transform: capitalize;
}
.blog-posts .tab-inner-wrapper .content .item .blog-para{
    max-width: 25ch;
}
.blog-posts .tab-inner-wrapper .content .item .blog-para > p {
    overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}
.blog-posts .tab-inner-wrapper .content .item .blog-para p {
    color: #555;
    font-family: var(--nunito);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
    
}
.tab-dropdown{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sort-by-dropdown .sort-icon{
    width: 17px;
    height: 17px;
    margin-right: 5px;
}
.dropdown-icon {
    transition: transform 0.3s; /* Add a CSS transition */
  }
  
.rotate {
    transform: rotate(180deg); /* Rotate the SVG when the dropdown is open */
}
.sort-by-dropdown{
    display: flex;
    align-items: center;
    position: relative;
    padding: 6px 18px 6px 12px;
    border-radius: 4px;
    border: 1px solid #727272;
    min-width: 200px;
}
.dropdown-options {
    position: absolute;
    top: 42px;
    z-index: 1;
    width: 100%;
    left: -1px;
    background: #fff;
    border-top: 0;
    border-radius: 4px;
    box-shadow: 1px 1px 7px 0px rgba(84, 84, 84, 0.06);
    max-height: 130px;
    overflow: hidden;
    overflow-y: auto;
}
.dropdown-options::-webkit-scrollbar {
    width: 4px;
}
.dropdown-options::-webkit-scrollbar-track {
    background: #f1f1f1;
} 
.dropdown-options::-webkit-scrollbar-thumb {
    background: #888;
}
.dropdown-options div {
    cursor: pointer;
    padding: 12px 12px 0 12px;
}
.dropdown-options div:last-child{
    padding-bottom: 12px;
}
.sort-by-dropdown label, .sort-by-dropdown button, .dropdown-options div{
    color: #222;
    text-align: left;
    font-family: var(--nunito);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
    text-transform: capitalize;
}
.sort-by-dropdown button{
    display: flex;
    align-items: center;
}
.sort-by-dropdown button .dropdown-icon{
    width: 8px;
    height: auto;
    position: absolute;
    right: 10px;
}
.sort-by-dropdown.modified label {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: 700;
}
.sort-by-dropdown.modified select {
    border: none;
    width: 100%;
    max-width: 200px;
    padding-right: 0;
    background-size: 8px;
    background-position: right;
    margin-left: 5px;
    font-family: var(--nunito);
    font-weight: 600;
    text-transform: uppercase;
    padding-right: 20px;
    font-size: 12px;
    cursor: pointer;
    background: transparent;
    color: #000;
}
.blog-posts .tab-inner-wrapper .content .img-wrapper .share-wrapper{
    position: absolute;
    top: 10px;
    right: 55px;
}
.blog-posts .tab-inner-wrapper .content .img-wrapper .share-wrapper .sw-icon{
    border-radius: 50%;
    background: #222;
    overflow: hidden;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.blog-posts .tab-inner-wrapper .content .img-wrapper .share-wrapper .sw-con{
    position: relative;
    cursor: pointer;
}
.blog-posts .tab-inner-wrapper .content .img-wrapper .share-wrapper .sw-con .share-links{
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.10);
    padding: 8px 10px;
    position: absolute;
    left: -70px;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    min-width: 162px;
    transition: .4s ease all;
}
.blog-posts .tab-inner-wrapper .content .img-wrapper .share-wrapper .sw-con:hover .share-links{
    opacity: 1;
    visibility: visible;
    z-index: 2;
}
.blog-posts .tab-inner-wrapper .content .img-wrapper .share-wrapper .sw-dd{
    position: absolute;
    top: 40px;
    border-radius: 4px;
    background: #FFF;
    padding: 8px 0px;
    min-width: 160px;
    right: -55px;
    clip-path: inset(0% 0 100% 0);
    transition: 1s clip-path ease;
}
.blog-posts .tab-inner-wrapper .content .img-wrapper .share-wrapper .sw-con .share-links .share-links-container img {
    width: 12px;
    height: 12px;
    position: relative;
}
.blog-posts .tab-inner-wrapper .content .img-wrapper .share-wrapper.active .sw-dd{
    clip-path: inset(0% 0 0% 0);
}
.blog-posts .tab-inner-wrapper .content .img-wrapper .share-wrapper .sw-dd ul{
    display: flex;
    flex-direction: column;
}
.blog-posts .tab-inner-wrapper .content .img-wrapper .share-wrapper .sw-dd ul a{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    color: #666;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 152%; /* 18.24px */
    letter-spacing: 0.24px;
    text-transform: capitalize;
}
.share-wrapper .sw-dd ul{
    padding-left: 10px;
}
.share-wrapper .sw-dd ul li:first-child a{
    padding-bottom: 7px;
    padding-top: 0;
    border-bottom: 1px solid #F1F1F1;
}
.share-wrapper .sw-dd ul li a{
    padding: 12px 0;
}
.share-wrapper .sw-dd ul li:nth-child(2) a{
    padding-top: 7px;
}
.noresultsfound {
    grid-column-start: 1;
    grid-column-end: 4;
}
.bb-txt .blog-para h1,
.bb-txt .blog-para h2,
.bb-txt .blog-para h3,
.bb-txt .blog-para h4,
.bb-txt .blog-para h5,
.bb-txt .blog-para h6 {
    display: none;
}
.bp-wrapper .blog-para h1,
.bp-wrapper .blog-para h2,
.bp-wrapper .blog-para h3,
.bp-wrapper .blog-para h4,
.bp-wrapper .blog-para h5,
.bp-wrapper .blog-para h6 {
    display: none;
}

/* Max Width 1300px */

@media (max-width: 1300px) {
    .bb-wrapper .bb-txt .blog-name h3{
        font-size: 36px;
    }
}
@media (max-width: 991px) {
    .bb-wrapper .bb-txt .blog-name h3 {
        font-size: 30px;
    }
    .blogs-all-wrapper .baw-wrapper{
        border: none;
    }
    .blog-posts .tab-inner-wrapper .content.active-content{
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 76px;
    }
}
@media (max-width: 767px) {
    .bb-wrapper .bb-img{
        width: 100%;
        padding-top: 65%;
    }
    .bb-wrapper .bb-txt{
        width: 100%;
    }
    .bb-wrapper{
        gap: 16px;
        margin-top: 45px;
        flex-direction: column;
    }
    .tab-dropdown{
        flex-direction: column;
        align-items: flex-start;
        gap: 25px;
    }
    .blog-posts .tab-dropdown .tabs-wrapper{
        overflow: hidden;
        overflow-x: scroll;
    }
    .blog-posts .tab-inner-wrapper .content.active-content{
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
    }
    .sort-by-dropdown.modified select {
        max-width: 100%;
    }
}

/* MAx Width 1300px Ends */