.mentee-profile-main{
    background:#FFFFFF;
}
.mentee-profile{
    padding-top: 84px;
    padding-bottom: 100px;
}
.mentee-profile-background{
    position: relative;
    padding-top: 20%;
}
.mentee-profile-background img{
    position: absolute;
    inset: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.mentee-profile-display-pic{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    border: 7px solid #FDFDFD;
    margin-top: -10%;
    position: relative;
    background-color: #fec14b; 
    
}
.mentee-profile-display-pic img{
    height: 100%;
    width: 100%;
    object-fit: cover;   
}
.mentee-name{
    color: #222;
    font-family: var(--switzer);
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}
.mentee-time span{
    color: #222;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 144%; /* 20.16px */
    letter-spacing: 0.28px;
    text-transform: uppercase;
    display: block;
}
.mentee-time .time-mentee-dot{
    width: 2px;
    height: 2px;
    background-color: #AAA;
    border-radius: 50%;
}
.mentee-name-time{
    gap: 12px;
}
.mentee-time{
    gap: 4px;
}
.mentee-profile-edit-btn{
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: #F6F6F6;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.mentee-profile-left-details{
    width: 50%;
}
.mentee-profile-details-wrapper{
    align-items: start;
    gap: 100px;
}
.mentee-designation-name, .mentee-designation-company{
    color: #333;
    font-family: var(--switzer);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%;
    letter-spacing: 0.36px;
}
.mentee-designation-name{
    gap: 8px;
}
.mentee-designation-dot{
    width: 4px;
    height: 4px;
    background-color: #AAA;
    border-radius: 50%;
}
.mentee-profile-designation{
    gap: 10px;
    padding-top: 2px;
}
.mentee-profile-left-details{
    padding-top: 11px;
}
.mentee-profile-head{
    color: #333;
    font-family: var(--switzer);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-transform: capitalize;
}
.mentee-profile-overview{
    padding-top: 80px;
    padding-bottom: 60px;
    border-bottom: 2px solid #B4B4B4;
}
.mentee-profile-btn .primary-btn{
    padding: 12px 21px;
    border: 1px solid #C0C0C0;
    text-transform: capitalize;
    font-weight: 500;
}
.mentee-profile-overview-head-wrap{
    padding-bottom: 26px;
    border-bottom: 2px solid #B4B4B4;
}
.mentee-profile-about{
    padding-top: 24px;
    padding-bottom: 31px;
    border-bottom: 1px solid #E3E3E3;
}
.mentee-profile-about-head{
    color: #777;
    font-family: var(--switzer);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%;
    letter-spacing: 0.36px;
}
.mentee-profile-about-body p{
    color: #222;
    font-family: var(--nunito);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    padding-top: 20px;
    max-width: 55ch;
}
.mentee-profile-about-show-btn{
    color: #24584B;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
    padding-top: 10px;
    cursor: pointer;
}
.mentee-profile-guidance{
    padding: 31px 0;
    border-bottom: 1px solid #E3E3E3;

}
.mentee-profile-guidance-tag{
    display: inline-flex;
    padding: 4px 20px;
    border-radius: 99px;
    border: 1px solid #8F8F8F;
    color: #222;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%;
    letter-spacing: 0.24px;
}
.mentee-profile-guidance-tags{
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 55ch;
}
.mentee-profile-industry{
    width: 50%;
}
.mentee-profile-languages{
    width: 50%;
}
.mentee-profile-indus-lang{
    padding-top: 31px;
}
.mentee-profile-educations{
    padding: 60px 0;
    border-bottom: 2px solid #B4B4B4;
}
.mentee-profile-education-img{
    width: 65px;
    height: 65px;
    border-radius: 6px;
    background-color: #C4EEE3;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mentee-profile-education-name{
    color: #222;
    font-family: var(--switzer);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}
.mentee-profile-education-year{
    gap: 5px;
}
.mentee-profile-education-year span{
    color: #666;
    font-family: var(--nunito);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: block;
    letter-spacing: 0.12px;
}
.mentee-profile-education-year .mentee-profile-education-dot{
    width: 2px;
    height: 2px;
    background-color: #AAA;
    border-radius: 50%;    
}
.mentee-profile-education{
    gap: 14px;
}
.mentee-profile-education-name-year{
    gap: 8px;
}
.mentee-profile-educations-wrap{
    padding-top: 40px;
}
.mentee-profile-educations-wrap > div:not(:last-child){
    padding-bottom: 24px;
    border-bottom: 1px solid #E3E3E3;
}
.mentee-profile-educations-wrap > div:not(:first-child){
    padding-top: 24px;
}
.mentee-profile-experience{
    padding-top: 60px;
}
.mentee-profile-experience .mentee-profile-education-img{
    background-color: #FFF3DC;
}
.mentee-profile-right-details{
    border-radius: 8px;
    background-color: #FEFBF4;
    width: 50%;
}
.mentee-profile-right-details-wrap{
    padding: 36px 47px;
}
.mentee-profile-stats-head-wrap{
    padding-bottom: 20px;
    border-bottom: 1px solid #E3E3E3;
}
.mentee-profile-statistic-name{
    color: #222;
    font-family: var(--switzer);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 144%; 
    letter-spacing: 0.36px;
}
.mentee-profile-statistic-label{
    color: #888;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%;
    letter-spacing: 0.24px;
}
.mentee-profile-statistic{
    gap: 14px;
    width: 50%;
}
.mentee-profile-statistics{
    padding-top: 20px;
    flex-wrap: wrap;
    row-gap: 26px;
}
.complete-profile-pop-head{
    color: #333;
    font-family: var(--switzer);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 144%;
    letter-spacing: 0.36px;
}
.complete-profile-pop-accordions{
    padding-top: 32px;
}
.complete-profile-pop-accordions .complete-profile-pop-accordion:not(:last-child){
    padding-bottom: 16px;
    border-bottom: 1px solid #E3E3E3;
}
.complete-profile-pop-accordions .complete-profile-pop-accordion:not(:first-child){
    padding-top: 16px;
}
.complete-profile-pop-body{
    display: grid;
    grid-template-rows: 0fr;
    opacity: 0;
    --webkit-transition: all .4s;
    transition: all .4s;
}
.complete-profile-pop-accordion.active .complete-profile-pop-body{
    grid-template-rows: 1fr;
    opacity: 1;
}
.complete-profile-pop-body div{
    overflow: hidden;
}
.complete-profile-pop-body p{
    color: #555;
    font-family: var(--nunito);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 152%;
    letter-spacing: 0.24px;
    padding-top: 10px;
}
.complete-profile-pop-body .primary-btn, .overview-profile-pop-btn .primary-btn{
    padding: 6px 21px;
    background-color: #E4F6EA;
    color: #2A8751;
    margin-top: 16px;
}
.overview-profile-pop-btn{
    padding-top: 42px;
}
.overview-profile-pop-btn .primary-btn{
    width: 100%;
    justify-content: center;
}
.complete-profile-pop-head-arrow{
    cursor: pointer;
}
.complete-profile-pop-head-arrow svg{
    transition: .2s ease all;
}
.complete-profile-pop-head-arrow.active svg{
    transform: rotate(180deg);
}
.complete-profile-pop, .overview-profile-pop, .intro-profile-pop, .education-profile-pop, .experience-profile-pop{
    display: flex;
    align-items: center;
    justify-content: center;
}
.overview-profile-pop-form{
    padding-top: 32px;
}
.experience-edit-box{
    border-radius: 4px;
    border: 1px solid #E3E3E3;
}
.experience-edit-box-wrap{
    padding: 13px 11px;
    position: relative;
    transition: .2s ease all;
    opacity: 1;
    height: auto;
}
.experience-edit-box-wrap.active{
    opacity: 0;
    height: 0;
    padding: 0;
}
.experience-edit-text-head{
    color: #222;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
}
.experience-edit-text{
    gap: 6px;
}
.experience-edit-text-comp-year{
    gap: 5px;
}
.experience-edit-text-comp-year span{
    color: #666;
    font-family:var(--nunito);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 152%;
    letter-spacing: 0.24px;
    display: block;
}
.experience-edit-text-comp-year .experience-edit-text-dot{
    width: 2px;
    height: 2px;
    background-color: #AAA;
    border-radius: 50%;
}
.experience-edit-icon{
    width: 35px;
    height: 35px;
    border-radius: 6px;
    background-color: #FFF3DC;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.experience-edit-icon-text{
    gap: 5px;
}
.experience-edit-delete-btn{
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: #F6F6F6;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.experience-edit-delete-btns{
    gap: 8px;
}
.overview-profile-pop-form .experience-edit-box:not(:first-child){
    margin-top: 16px;
}
.experience-add-another-btn{
    color: #222;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    text-transform: capitalize;
    gap: 5px;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    --webkit-transition: all .4s;
    transition: all .4s;
}
.experience-add-another-btn.active{
   opacity: 0;
   visibility: hidden;
   z-index: -2;
}
.experience-add-another-form-buttons{
    gap: 9px;
}
.experience-add-cancel-btn .primary-btn{
    padding: 6px 21px;
    background-color: transparent;
    border: 1px solid #E3E3E3;
    color: #222;
    width: 100%;
}
.experience-add-save-btn .primary-btn{
    padding: 6px 21px;
    width: 100%;
} 
.experience-add-another-form{
    width: 100%;
    display: grid;
    grid-template-rows: 0fr;
    opacity: 0;
    --webkit-transition: all .4s;
    transition: all .4s;
}
.experience-add-another-form.active{
    grid-template-rows: 1fr;
    opacity: 1;
}
.experience-add-another-form div{
    overflow: hidden;
}
.experience-add-another-form-buttons{
    padding-top: 22px;
}
.experience-add-another-form-wrap{
    padding: 12px;
}
.experience-add-another-form-wrap .login-form-input select{
    width: 40%;
}
.experience-add-another-form-wrap .login-form-input .is-present-checkbox{
    display: flex;
    align-items: center;
    gap: 6px;
    flex-direction: column;
    justify-content: center;
}
.education-edit-box .experience-edit-icon{
    background-color: #C4EEE3;
}
.loading-indicator{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    transition: .4s ease all;
    inset: 0px;
    background: rgba(255, 255, 255, 0.75);
}
.loading-indicator svg{
    width: 50px;
    height: 50px;
    position: relative;
}
.mentee-profile-social-link{
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 4px 7px 0px #EFEFEF;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
}
.mentee-profile-social-link-cont{
    margin: 10px;
    width: 20px;
    height: 20px;
    border-radius: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0A66C2;
}
.mentee-profile-social-link{
    margin-top: 16px;
}
.mentee-profile-social{
    padding-top: 31px;
    padding-bottom: 31px;
    border-bottom: 1px solid #E3E3E3;
}
.mentee-profile-video{
    padding-top: 31px;
}
.mentee-profile-video-wrapper{
    border-radius: 8px;
    overflow: hidden;
    margin-top: 20px;
}
.mentee-profile-video-wrapper video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.overview-video-cont{
    padding-top: 24px;
}
.overview-video-wrap video{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.overview-video-wrap{
    border-radius: 8px;
    overflow: hidden;
}
.overview-video-text{
    text-align: center;
    padding-top: 12px;
}
.overview-video-btn{
    padding-top: 12px;
}
.overview-video-btn label{
    border-radius: 4px;
    border: 1px solid #222;
    width: 100%;
    padding: 6px 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
}
.mentee-profile-about-body{
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: .2s ease all;
}
.mentee-profile-about-body.expanded{
    -webkit-line-clamp:inherit;
}
.login-form-input.dimmed{
    opacity: 0.5;
    pointer-events: none;
}
.numberOfReviews{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #E3E3E3;
    font-family: var(--switzer);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.015em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mentor-reviews .micro{
    gap: 10px;
}
.mentor-reviews .review-stars li{
    display: flex;
}
.mentor-reviews .re-in .rb-defined{
    display: flex;
    gap: 50px;
}
.mentor-reviews{
    padding-top: 0px;
}
.mentor-reviews .re-in{
    padding-top: 12px;
}
.mentor-reviews .rbd-stars .review-stars{
    justify-content: start;
}
.mentor-reviews .rbd-stars{
    padding-top: 5px;
}
.mentor-reviews .rb-defined-in .rbd-tag{
    color: #222222;
}
.mentor-user-review-top-img{
    width: 57px;
    height: 57px;
    border-radius: 50%;
    overflow: hidden;
}
.mentor-user-review-top-img img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.mentor-user-review-top-left{
    display: flex;
    align-items: center;
    gap: 10px;
}
.mentor-user-review-top-name-stars{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.mentor-user-review-top-right{
    font-family: var(--switzer);
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #777777;
}
.mentor-user-review-top{
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding-bottom: 12px;
    border-bottom: 1px solid #E3E3E3;
}
.mentor-user-review-bottom .rb-defined{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mentor-user-review-bottom{
    padding-top: 12px;
}
.mentor-user-review-bottom .rbd-stars .review-stars {
    justify-content: center;
}
.mentor-user-review-bottom .rb-defined-in .rbd-tag{
    color: #24584B;
}
.mentor-user-review-message p{
    font-family:var(--nunito);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #777777;
}
.mentor-user-review-message {
    padding-top: 12px;
}
.mentor-user-review-message span{
    display: inline;
    margin-right: 2px;
    color: #444444;
    font-weight: 600;
}
.mentor-user-review{
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    padding: 20px 18px;
}
.mentor-user-reviews{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 48px;
    gap: 28px;
}
@media only screen and (max-width:1400px){
    .mentee-name{
        font-size: 36px;
        word-break: break-word;
    }
    .mentee-designation-name, .mentee-designation-company {
        font-size: 16px;
    }
    .mentee-profile-head{
        font-size: 26px;
    }
    .mentee-profile-btn .primary-btn{
        padding: 10px 21px;
    }
    .mentee-profile-about-head {
        font-size: 16px;
    }
    .mentee-profile-about-body p{
        font-size: 14px;
    }
    .mentee-profile-statistic-name{
        font-size: 16px;
    }
    .mentee-profile-education-name{
        font-size: 18px;
    }
}

@media only screen and (max-width:1100px){
    .mentee-profile-details-wrapper{
        gap: 50px;
    }
    .mentee-profile-details-wrapper{
        flex-direction: column;
    }
    .mentee-profile-left-details{
        width: 100%;
    }
    .mentee-profile-right-details{
        width: 100%;
    }
    .mentee-profile-background{
        padding-top: 30%;
    }
}
@media only screen and (max-width:991px){
    .mentee-name {
        font-size: 32px;
    }
}

@media only screen and (max-width:767px){
    .mentee-name {
        font-size: 28px;
    }
    .mentee-designation-name, .mentee-designation-company {
        font-size: 14px;
    }
    .mentee-profile-head{
        font-size: 22px;
    }
    .mentee-profile-about-head {
        font-size: 14px;
    }
    .mentee-profile-statistic-name{
        font-size: 14px;
    }
    .mentee-profile-education-name{
        font-size: 16px;
    }
    .mentee-name-time{
        flex-direction: column;
    }
    .mentee-profile-designation{
        padding-top: 12px;
    }
    .mentee-profile-background {
        padding-top: 50%;
    }
    .mentee-profile-display-pic{
        margin: -15% auto 0 auto;
    }
    .mentee-name-time-btn-wrap{
        align-items: baseline;
    }
    .mentee-profile-right-details-wrap {
        padding: 30px 20px;
    }
    .complete-profile-pop-head {
        font-size: 16px;
    }
    .experience-edit-box-wrap{
        flex-direction: column;
        align-items: start;
        gap: 20px;
    }
    .experience-add-save-btn .primary-btn{
        padding: 6px 17px;
    }
    .mentor-user-reviews{
        grid-template-columns: repeat(1, 1fr);
    }
    .mentor-reviews .re-in .rb-defined{
        flex-wrap: wrap;
        row-gap: 20px;
    }
    .mentor-user-review-bottom .rb-defined{
        flex-wrap: wrap;
        gap: 10px;
    }
    .mentor-sticky-designation-deets span{
        font-size: 12px;
    }

}

@media only screen and (max-width: 400px){
    .select-wrapper{
        flex-wrap: wrap;
    }
    .experience-add-another-form-wrap .login-form-input select{
        width: 45%;
    }
}