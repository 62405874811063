.dash-session-list {
    margin-top: 30px;
    margin-bottom: 30px;
}
.dsltabs-tabs ul {
    display: flex;
    border: none;
    border-bottom: 1px solid #E3E3E3;
    padding-bottom: 10px;
    margin-bottom: 30px;
}
.dsltabs-tabs ul li {
    border: none;
    padding: 0;
    margin-right: 30px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.24px;
    text-transform: capitalize;
    color: #888;
    position: relative;
    transition: .3s ease all;
}
.dsltabs-tabs ul li:after {
    content: '';
    background-color: #E3E3E3;
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 1px;
    transition: .3s ease all;
}
.dsltabs-tabs ul li:focus:after {
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #E3E3E3;
}
.dsltabs-tabs ul li.react-tabs__tab--selected {
    color: #24584B;
}
.dsltabs-tabs ul li.react-tabs__tab--selected:after {
    background-color: #24584B;
}
.dsltabs-tabs ul li.react-tabs__tab--selected:focus:after {
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #24584B;
}
.dsltabs-data .react-tabs__tab-panel {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.favourite-mentors-list-button .isg-guide-load-more {
    font-family: var(--switzer);
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: inline-block;
    cursor: pointer;
    position: relative;
    color: #222222;
    padding-bottom: 2px;
    transition: .3s ease all;
}
.favourite-mentors-list-button .isg-guide-load-more::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #222222;
    transition: .3s ease all;
}
@media(max-width:767px){
    .dsltabs-tabs ul {
        overflow: hidden;
        overflow-x: auto;
        white-space: nowrap;
    }
}