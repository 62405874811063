.logo-name a {
    font-family: var(--switzer);
    font-weight: 600;
    font-size: 22px;
    line-height: 110.5%;
    text-align: right;
    text-transform: lowercase;
    font-feature-settings: 'liga' off;
    color: #222222;
    display: flex;
}
.logo-name a span {
    margin-right: 10px;
}
.logo-name a span svg path {
    transition: .3s ease all;
}
.hp-main .header .logo-name a span svg path {
    fill: #222222;
}
.hp-main .header.active .logo-name a span svg path {
    fill: #FEC14B;
}
.header-left-nav {
    gap: 35px;
}
.header-left-dropdown {
    gap: 10px;
    cursor: pointer;
    position: relative;
}
.header-left-dropdown > a.label {
    transition: .2s ease all;
    display: flex;
    align-items: center;
}
.header-left-dropdown > a.label svg {
    margin-left: 5px;
    display: inline-block;
    transition: .2s ease all;
}
.header-left-dropdown > a.label.active svg {
    transform: rotate(180deg);
}
.header-left-dropdown ul {
    position: absolute;
    width: max-content;
    top: -100%;
    background: #fff;
    border-radius: 5px;
    padding: 14px 22px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    box-shadow: 0 1px 6px 0 rgba(0,0,0,0.2);
    opacity: 0;
    visibility: hidden;
    transition: .3s ease all;
}
.header-left-dropdown ul a.label {
    color: #000!important;
    transition: .2s ease all;
}
.header-left-dropdown ul a.label:hover {
    color: #FEC14B!important;
}
.header-left-dropdown ul.dropdown.active {
    opacity: 1;
    visibility: visible;
    top: 35px;
}
.header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.header-right-nav{
    gap: 35px;
    justify-content: flex-end;
}
.header-right .header-btn button, .header-right .header-btn a {
    display: flex;
    align-items: center;
}
.header-right .header-btn button span, .header-right .header-btn a span {
    display: flex;
    margin-left: 8px;
}
.header-right .header-btn button span svg path, .header-right .header-btn a span svg path {
    transition: .8s cubic-bezier(0.9, 0.03, 0.47, 0.89);
}
.header-right .header-btn button:hover span svg path, .header-right .header-btn a:hover span svg path {
    stroke: #fff;
}
.header-right .header-btn .loggedinProfile {
    position: relative;
}
.header-btn .loggedinProfile .dropdown  {
    position: absolute;
    width: max-content;
    top: -100%;
    background: #fff;
    border-radius: 5px;
    padding: 14px 22px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    box-shadow: 0 1px 6px 0 rgba(0,0,0,0.2);
    opacity: 0;
    visibility: hidden;
    transition: .3s ease all;
}
.header-btn .loggedinProfile .dropdown.active {
    opacity: 1;
    visibility: visible;
    top: 40px;
}
.header{
    padding: 23px 0;
    position: fixed;
    z-index: 999999999999;
    width: 100%;
    top: 0;
    transition: .2s ease all;
}
.header.active{
   background-color: #FFFFFF;
   box-shadow: 0 1px 6px 0 rgba(0,0,0,0.2);
}
.header-hamburger-icon svg{
    height: 30px;
    width: 30px;
    cursor: pointer;
}
.header-hamburger-menu{
    display: none;
    height: 25px;
    display: flex;
    display: none;
    align-items: center;
    cursor: pointer;
}
.header-hamburger-icon-top, .header-hamburger-icon-middle, .header-hamburger-icon-bottom{
    border: 1.5px solid black;
    width: 25px;
    border-radius: 8px;
    transition: .4s ease all;
  }
  .header-hamburger-menu.active .header-hamburger-icon-top{
    transform: rotate(45deg);
  }
  .header-hamburger-menu.active .header-hamburger-icon-bottom{
    transform: rotate(-45deg);
    position: absolute;
  }
  .header-hamburger-menu.active .header-hamburger-icon-middle{
    position: absolute;
    opacity: 0;
    left: -100px;
  }  
.header-hamburger-icon{
      gap: 6px;
}
.mobile-search{
    position: relative;
}
.mobile-search svg{
    position: absolute;
    right: 10px;
    top: 19%;
    height: 20px;
    width: 20px;
}
.mobile-search input{
    width: 100%;
    background: transparent;
    border: 1px solid #222222;
    border-radius: 8px;
    padding: 7px 19px;
}
.mobile-menu-nav{
    gap: 26px;
    margin: 35px 0;
}
.header-mobile-menu {
    position: fixed;
    left: 0;
    top: 70px;
    z-index: -1;
    width: 100%;
    height: 100%;
    transition: .6s ease-in-out;
    clip-path: polygon(0% 0%,100% 0%,100% 0%,0% 0%);
    visibility: hidden;
    box-shadow: 0 1px 6px 0 rgba(0,0,0,0.2);
    background-color: #FFFFFF;
    overflow: hidden;
    z-index: 999;
}
.header-mobile-menu.active{
    visibility: visible;
    clip-path: polygon(0% 0%,100% 0%,100% 100%,0% 100%);
    transition: .4s ease-in-out;
}
.header-mobile-menu-wrapper{
    padding-top:20px;
    padding-bottom:20px;
}
.header-link .label {
    white-space: nowrap;
}
.header-search {
    width: 0%;
    min-width: 40px;
    position: relative;
    height: 34px;
    overflow: hidden;
    transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.header-search .hs-icon {
    position: relative;
}
.header-search .search-input {
    width: 0%;
    height: 100%;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 0px 12px;
    padding-left: 35px;
    background: transparent;
    transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
}
.header-search svg {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 0;
    cursor: pointer;
    transition: .3s ease all;
}
.header-search.active {
    overflow: unset;
    width: 200px;
}
.header-search.active .search-input {
    border-color: #333333;
    width: 100%;
}
.header-search.active svg {
    width: 18px;
    height: 18px;
    left: 10px;
}
.mobile-menu-links .dropdown{
   display: flex;
   flex-direction: column;
   gap: 10px;
   margin-top: 10px;
   padding-left: 10px;
}
.mobile-btn .primary-btn.border{
    width: 100%;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    gap: 8px;
    align-items: center;
}
.mobile-btn .primary-btn.border span{
    display: flex;
}
.mobile-btn{
    position: absolute;
    width: 95%;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 100px;
}
.mobile-btn .mnav-buttons {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}
.mobile-menu-links .label{
    font-size: 18px;
}
@media(max-width:1200px){
    .header-left-nav {
        gap: 25px;
    }
    .header-right-nav {
        gap: 25px;
    }
    .header-search svg {
        width: 22px;
        height: 26px;
    }
}
@media only screen and (max-width:991px) {
    .header-right{
        display: none;
    }
    .header-hamburger-menu{
        display: flex;
    }
    .header-left-dropdown{
        display: none;
    }
    
}
@media only screen and (max-width:575px){
    .mobile-menu-links .label{
        font-size: 18px;
    }
}