.allMentors {
    padding: 100px 0;
    overflow: hidden;
}
.mentorGrid {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 30px 20px;
    width: 90%;
    margin: 0 auto;
}
.all-mentors-filters {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    margin-bottom: 46px;
    flex-wrap: wrap;
    width: calc(100% - 40px);
    margin-left: auto;
    margin-right: auto;
}
.sort-by-search{
    display: flex;
    gap: 10px;
    padding: 11px 21px;
    border: 1px solid #727272;
    border-radius: 8px;
    min-width: 270px;
}
.sort-by-search svg{
    cursor: pointer;
}
.sort-by-search input{
    border: none;
    width: 100%;
} 
.all-mentors-filters .sort-by-dropdown {
    padding: 10px 18px 10px 12px;
    border-radius: 8px;
}
.all-mentors-filters .sort-by-dropdown button{
    text-transform: uppercase;
    font-weight: 700;
}
@media(max-width:1200px){
    .mentorGrid {
        width: 100%;
    }
}
@media(max-width:991px){
    .mentorGrid {
        grid-template-columns: repeat(3,1fr);
    }
}
@media(max-width:767px){
    .allMentors {
        padding-top: 20px;
    }
    .mentorGrid {
        grid-template-columns: repeat(2,1fr);
    }
    .all-mentors-filters .sort-by-dropdown {
        width: 100%;
    }
    .sort-by-search{
        width: 100%;
    }
}
@media(max-width:560px){
    .mentorGrid {
        grid-template-columns: repeat(1,1fr);
    }
}