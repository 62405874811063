.insage-knowledge-at-work{
    padding: 260px 0px 200px 0px;
    position: relative;
}
.insage-knowledge-at-work-label{
    background-color: #24584B;
    border-radius: 0px 4px 4px 0px;
    width: 32px;
    height: 224px;
    position: absolute;
    left: 0;
}
.insage-knowledge-at-work-content-body{
    padding-top: 112px;
}
.insage-knowledge-at-work-left{
    padding-left: 105px;
    width: 47%;
}
.insage-knowledge-at-work-label div{
    writing-mode: vertical-lr;
    transform: rotate(-180deg);
    white-space: nowrap;
    text-align: center;
    padding: 32px 6px;
}
.insage-knowledge-at-work-content-body-text{
    padding-top: 12px;
}
.insage-knowledge-single-data-head{
    font-family: var(--switzer);
    font-weight: 700;
    font-size: 88px;
    line-height: 111.5%;
    letter-spacing: -0.01em;
    display: flex;
}
.insage-knowledge-single-data-head span{
    display: block;
}
.insage-knowledge-top-data .insage-knowledge-single-data:nth-child(2) .insage-knowledge-single-data-head span:nth-child(2){
    /* display: none; */
 }
 .insage-knowledge-top-data .insage-knowledge-single-data:nth-child(3) .insage-knowledge-single-data-head span:nth-child(2){
    /* display: none; */
 }
.insage-knowledge-single-data-body{
    font-family: var(--switzer);
    font-weight: 600;
    font-size: 24px;
    line-height: 96%;
    text-transform: math-auto;
    font-feature-settings: 'ss08' on;
}
.insage-knowledge-top-data{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 90px;
}
.insage-knowledge-bottom-data{
  gap: 90px;
}
.insage-knowledge-at-work-right{
    padding-left: 60px;
    position: relative;
    z-index: 1;
}
.insage-knowledge-at-work-right-wrapper{
    gap: 53px;
}
.insage-knowledge-at-work-content-heading{
    overflow: hidden;
    padding-bottom: 5px;
}
.insage-knowledge-at-work-content-body-label{
    overflow: hidden;
}
.insage-knowledge-at-work-content-body-text{
    overflow: hidden;
}
.insage-knowledge-single-data{
    overflow: hidden;
    padding-bottom: 5px;
}
@media only screen and (max-width:1300px){
    .insage-knowledge-single-data-head{
        font-size: 80px;
    }
    .insage-knowledge-single-data-body{
        font-size: 22px;
    }
}
@media only screen and (max-width:1200px){
    .insage-knowledge-single-data-head{
        font-size: 72px;
    }
    .insage-knowledge-single-data-body{
        font-size: 20px;
    }
    .insage-knowledge-at-work{
        padding: 100px 0;
    }
}
@media only screen and (max-width:1100px){
    .insage-knowledge-single-data-head{
        font-size: 64px;
    }
    .insage-knowledge-single-data-body{
        font-size: 20px;
    }
    .insage-knowledge-at-work-content-body {
        padding-top: 50px;
    }
    .insage-knowledge-at-work-left{
        padding-left: 60px;
    }
    .insage-knowledge-top-data {
        gap: 30px;
    }
}
@media only screen and (max-width:991px){
    .insage-knowledge-at-work {
        padding: 70px 0 100px 0;
    }
    .insage-knowledge-at-work-left{
        padding-left: 0;
        width: 70%;
    }
    .insage-knowledge-at-work-wrapper{
        flex-direction: column;
        gap: 50px;
    }
    .insage-knowledge-at-work-right{
        padding-left: 0;
        width: 70%;
    }
    .insage-knowledge-single-data-body{
        font-size: 18px;
    }
}

@media only screen and (max-width:767px){
    .insage-knowledge-at-work-content-body{
        padding-top: 40px;
    }
    .insage-knowledge-at-work-label{
        width: 200px;
        height: 32px;
        align-items: center;
        position: static;
        border-radius: 4px;
    }
    .insage-knowledge-at-work-left{
        width: 100%;
        flex-direction: column;
        align-items: start;
        gap: 20px;
    }
    .insage-knowledge-at-work-label div{
        writing-mode: horizontal-tb;
        transform: rotate(0deg);
        padding: 0;
    }
    .insage-knowledge-at-work-right{
        width: 100%;
    }
    .insage-knowledge-single-data-head{
        font-size: 56px;
    }
    .insage-knowledge-single-data-body{
        font-size: 16px;
    }
}