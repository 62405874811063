.insage-grid-layout{
    display: grid;
    grid-template-columns: .5fr 3.5fr;
    padding-top: 84.23px;
}
.insage-grid-layout > div{
    border:1px solid #E3E3E3;
}

@media only screen and (max-width:991px){
    .insage-grid-layout{
        padding-top: 74.23px;
    }
}