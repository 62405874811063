.industry-experts-card{
    background-color: #FFFEFB;
    border-radius: 6px;
    overflow: hidden;
}
.industry-experts-card-wrapper{
    padding: 50px 30px 30px 30px;
}
.industry-experts-cards-wrapper{
    gap:24px;
}
.industry-experts-card{
    width: 32%;
}
.industry-experts-card-content{
    padding-top: 37px;
}
.industry-experts-card-heading, .industry-experts-card-body{
    text-align: center;
}
.industry-experts-card-body{
   margin-top: 15px;
   padding: 0 20px;
}
.industry-experts-card-image{
    height: 110px;
}

@media only screen and (max-width:1100px){
    .industry-experts-cards-wrapper{
        scroll-snap-type: x mandatory;
        overflow-x: scroll;
        padding-bottom: 20px;
    }
    .industry-experts-cards-wrapper::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 1px;
        height: 4px;
      }
      .industry-experts-cards-wrapper::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .4);
      }
    .industry-experts-card{
        flex-shrink: 0;
        scroll-snap-align: start;
        width: 42%;
    }
}
@media only screen and (max-width:767px){
    .industry-experts-card{
        width: 80%;
    }
    .industry-experts-card-body{
        padding: 0 5px;
    }
}