.footer{
    position: relative;
}
.footer-arrow-img{
    position: absolute;
    top: 0px;
    right: 0;
    width: 30%;
    max-width: 480px;
}
.footer-arrow-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.footer-top{
    background-color: #FEC14B;
}
.footer-top-content-wrapper{
    padding: 47px 0;
}
.footer-top-content-body{
    padding-top: 10px;
}
.footer-top-content-body p{
    color: #444444;
}
.footer-top-content-button{
    padding-top: 30px;
}
.footer-bottom{
    background: #222222;
}
.footer-bottom-wrapper{
    padding-bottom: 34px;
    padding-top: 120px;
}
.footer-bottom-wrapper .footer-social {
    margin-top: 30px;
}
.footer-social ul {
    display: flex;
    align-items: center;
    gap: 20px;
}
.footer-social ul li svg {
    width: 30px;
}
.footer-social ul li svg path {
    fill:#fff;
    transition: .3s ease all;
}
.footer-social ul li:hover svg path {
    fill:#FEC14B;
}
.footer-bottom-columns{
    gap: 62px;
    flex-wrap: wrap;
}
.footer-column-heading{
    font-family: var(--switzer);
    font-weight: 700;
    font-size: 18px;
    line-height: 144%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FEC14B;
    margin-bottom: 17px;
}
.footer-column-link a {
    font-weight: 600;
    font-size: 16px;
    line-height: 144%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    transition: .2s ease all;
}
.footer-column-link a:hover {
    color: #fec14b;
}
.footer-column-links{
    gap: 13px;
}
.footer-logo-links-wrapper{
    gap: 27px;
}
.footer-logo-links{
    gap: 27px;
}
.footer-logo-link p{
    color: #FFFFFF;
}
.footer-logo-link a p{
    transition: .2s ease all;
}
.footer-logo-link a:hover p{
    color: #fec14b;
}
.footer-insageXsp{
    color: #FFFFFF;
    gap: 4px;
    font-size: 14px;
    line-height: 144%;
}
.footer-insageXsp a{
    transition: .2s ease all;
}
.footer-insageXsp a:hover{
    color: #fec14b;
}
.footer-bottom-bottom{
    padding-top: 130px;
}
@media only screen and (max-width:1300px){
    .footer-column-heading{
        font-size: 16px;
    }
    .footer-column-link a{
        font-size: 14px;
    }
}
@media only screen and (max-width:1200px){
    .footer-arrow-img{
        display: none;
    }
}
@media only screen and (max-width:991px){
    .footer-column-heading{
        font-size: 14px;
    }
    .footer-column-link a{
        font-size: 12px;
    }
    .footer-logo img{
        height: 35px;
    }
    .footer-insageXsp{
        font-size: 12px;
    }
    
}

@media only screen and (max-width:767px){
    .footer-logo-links-wrapper{
        flex-direction: column;
    }
    .footer-bottom-bottom-wrapper{
        flex-direction: column;
        gap: 27px;
    }
    .footer-bottom-columns{
        justify-content: start;
    }
    .footer-social ul {
        gap: 14px;
    }
    .footer-social ul li svg {
        width: 24px;
    }
}
@media only screen and (max-width:480px){
    .footer-logo-links{
        flex-direction: column;
    }
    .footer-bottom-column{
        width: 40%;
    }
    .footer-bottom-columns{
        gap: 50px;
    }
}