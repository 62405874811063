.insage-testimonials{
    background-color: #FFECA2;
    padding: 100px 0;
    position: relative;
}
.insage-testimonials .marquee-wrap {
    margin-top: -130px;
    z-index: 99;
    top: -45px;
}
.insage-testimonials::after{
    content: "";
    width: 100%;
    height: 200px;
    bottom: 0;
    background-color: #FEFBF4;
    position: absolute;
}
.insage-testimonials .container{
    position: relative;
    z-index: 2;
}
.testimonials-image{
    overflow: hidden;
    border-radius: 8px;
    width: 50%;
    position: relative;
    padding-top: 70%;
    height: 0;
    transition: 0.9s cubic-bezier(0.17, 0.55, 0.55, 1);
    clipPath: inset(0 100% 0 0);
}
.testimonials-image img{
    position: absolute;
    inset: 0px;
}
.swiper-slide-active .testimonials-image{
    clipPath: inset(0 0 0 0);
}
.testimonials-content-separator{
    width: 41px;
    height: 8px;
    background: #FFFFFF;
    margin-top: 36px;
}
.testimonials-content-designation span{
   color: #555555;
}
.testimonials-content{
    width: 50%;
    padding-top: 20px;
}
.testimonials-wrapper{
    gap: 73px;
    align-items: start;
}
.testimonials-content-details{
    padding-top: 32px;
    transition: 0.9s cubic-bezier(0.17, 0.55, 0.55, 1);
    transform: translateY(100px);
    opacity: 0;
    transition-delay: .5s;
}
.swiper-slide-active .testimonials-content-details{
    transform: translateY(0px);
    opacity: 1;
}
.testimonials-swiper{
    position: relative;
}
.testimonials-swiper .prev-btn{
    right: 70px;
    bottom: 215px;
}
.testimonials-swiper .next-btn{
    right: 0px;
    bottom: 215px;
}
.testimonials-content-body{
    overflow: hidden;
}
.testimonials-content-body h3{
    transition: 0.9s cubic-bezier(0.17, 0.55, 0.55, 1);
    transform: translateY(100%);
    opacity: 0;
    font-size: 46px;
}
.swiper-slide-active .testimonials-content-body h3{
    transform: translateY(0%);
    opacity: 1;
}
.insage-testimonials .swiper-slide{
    opacity: 0 !important;
}
.insage-testimonials .swiper-slide.swiper-slide-active{
    opacity: 1 !important;
}
@media only screen and (max-width:1300px){
    .testimonials-content-body h3{
        font-size: 42px;
    }
}
@media only screen and (max-width:1200px){
    .testimonials-swiper .prev-btn, .testimonials-swiper .next-btn{
        bottom: 175px;
    }
    .testimonials-content-body h3{
        font-size: 38px;
    }
}
@media only screen and (max-width:1100px){
    .insage-testimonials::after {
        height: 120px;
    }
    .insage-testimonials .marquee-wrap{
        top: 15px;
    }
    .testimonials-swiper .prev-btn, .testimonials-swiper .next-btn{
        bottom: 125px;
    }
    .testimonials-content-body h3{
        font-size: 34px;
    }
}

@media only screen and (max-width:991px){
    .insage-testimonials::after {
        display: none;
    }
    .testimonials-wrapper{
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }
    .testimonials-image{
        width: 65%;
        padding-top: 80%;
    }
    .testimonials-content{
        width: 65%;
    }
    .insage-testimonials .marquee-wrap{
        top: 150px;
    }
    .testimonials-swiper .prev-btn, .testimonials-swiper .next-btn{
        bottom: 0px;
    }
}

@media only screen and (max-width:767px){
    .testimonials-image{
        width: 100%;
        padding-top: 100%;
    }
    .testimonials-content{
        width: 100%;
    }
    .testimonials-content-body h3{
        font-size: 30px;
    }
    .testimonials-swiper .prev-btn, .testimonials-swiper .next-btn {
        position: relative;
    }
    .testimonials-swiper .prev-btn {
        left: 0;
        top: 0px;
        display: inline-flex;
        margin-top: 20px;
        margin-right: 15px;
    }
    .testimonials-swiper .next-btn {
        left: 0;
        top: 0px;
        display: inline-flex;
        margin-top: 20px;
    }
}