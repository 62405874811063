.calendar {
    width: 100%;
    max-width: 380px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.10);
}

.calendar .month {
    margin-bottom: 20px;
    padding: 24px 30px;
}

.calendar .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f0f0f0;
}

.calendar .days-header {
    display: flex;
    margin-top: 12px;
    justify-content: space-between;
}

.calendar .day-label {
    color: #AAA;
    text-align: center;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%;
    letter-spacing: 0.24px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.calendar .days {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.calendar .day {
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%;
    letter-spacing: 0.28px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #444;
    cursor: pointer;
}

.calendar .day.disabled {
    color:#AAA;
    cursor: not-allowed;
}
.calendar .day.selected {
    border-radius: 50%;
    color: #FFF;
    font-weight: 600;
    background: #222;
    cursor:default;
}

.calendar .day.current {
    background-color: blue; /* Set the background color for the current date */
    color: white; /* Set the text color for the current date */
}
.top-calender-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px solid #E3E3E3;
}
.top-calender-wrap h2{
    color: #222;
    text-align: center;
    font-family:var(--switzer);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 144%; 
    letter-spacing: 0.36px;
}
.calender-buttons{
    display: flex;
    gap: 20px;
    align-items: center;
}
.calender-buttons button{
    display: flex;
    width: 24px;
    height: 24px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #FFF;
    box-shadow: 0px 3px 4px 0px #F6F6F6;
}

.calendar-clear-continue-button{
    display: flex;
    gap: 12px;
    padding-top: 16px;
    margin-top: 16px;
    border-top: 1px solid #E3E3E3;
}
.calendar-clear-continue-button a{
    width: 50%;
    justify-content: center;
}
.calendar-clear-continue-button a:nth-child(1){
    border: 1px solid #C0C0C0;
    color: #222;
    background-color: transparent;
    text-transform: capitalize;
    font-weight: 500;
}
.calendar-clear-continue-button a:nth-child(2){
    background-color:#E4F6EA;
    color: #2A8751;
    font-weight: 700;
}
