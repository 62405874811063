.isg-guide-img{
    position: relative;
    padding-top: 80%;
    border-radius: 8px;
    overflow: hidden;
}
.isg-guide:hover img{
 transform: scale(1.2);
}
.isg-guide-img img{
    position: absolute;
    inset: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .4s ease all;
}
.isg-guide-title h2{
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    text-transform: capitalize;
    color: #222222;
    text-align: left;
}
.isg-guide-body p{
    line-height: 160%;
}
.isg-guide-title{
    padding-top: 22px;
}
.isg-guide-body{
    padding-top: 12px;
}
.isg-guide-content{
    width: 90%;
}