.mentor-bookinggrid{
    margin-top: 86px;
    border-top: 1px solid #E3E3E3;
    min-height: 100vh;
}
.mentor-bookinggrid-wrap{
    display: flex;
}
.mentor-bookinggrid-left{
    width: 35%;
    height: 100%;
    min-height: 100vh;
    background: linear-gradient(180deg, #F9F9F9 0%, rgba(249, 249, 249, 0.00) 100%);
}
.mentor-bookinggrid-left-wrap{
    padding: 28px 60px 20px 154px;
}
.mentor-bookinggrid-right{
    width: 65%;
    height: 100%;
    background-color: #FFF;
    border-left: 1px solid #E3E3E3;
}
.mentor-booking-mentorDeets-pic{
    width: 67px;
    height: 67px;
    border-radius: 8px;
    overflow: hidden;
}
.mentor-booking-mentorDeets-pic img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.mentor-booking-mentorDeets-name-desig{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
}
.mentor-booking-mentorDeets-desig{
    display: flex;
    align-items: center;
    gap: 10px;
}
.mentor-booking-mentorDeets-desig-sep{
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #AAA;
}
.mentor-booking-mentorDeets-name{
    color: #222;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.mentor-booking-mentorDeets-desig-price{
    color: #222;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
}

.mentor-booking-mentorDeets-desig-time{
    color: #666;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 152%; /* 18.24px */
    letter-spacing: 0.24px;
}
.mentor-booking-mentorDeets-experience-top , .mentor-booking-mentorDeets-experience-bottom{
    display: flex;
    align-items: center;
    gap: 6px;
}
.mentor-booking-mentorDeets-experience-text{
    color: #333;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
}
.mentor-booking-mentorDeets-experience{
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.mentor-booking-mentorDeets{
    padding-bottom: 22px;
    border-bottom: 1px solid #E3E3E3;
}
.mentor-bookinggrid-left-wrap .mentee-profile-about{
    border-bottom: none;
}
.mentor-bookinggrid-right-wrap{
    padding-top: 28px;
    width: 90%;
    padding-bottom: 22px;
}
.mentor-bookinggrid-right-top{
    padding-left: 66px;
}
.mentor-bookinggrid-right-top .login-head{
    padding-top: 10px;
}
.mentor-bookinggrid-right-sep{
    width: 100%;
    height: 1px;
    background-color: #E3E3E3;
    margin: 24px 0;
}
.mentor-bookinggrid-select-date{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mentor-bookinggrid-select-date p{
    font-size: 12px;
    font-weight: 400;
}
.mentor-bookinggrid-right-middle{
    padding-left: 66px;
}
.mentor-bookinggrid-select-date{
    padding-bottom: 20px;
    border-bottom: 1px solid #E3E3E3;
}
.mentor-bookinggrid-select-calendar{
    padding-top: 20px;
}
.mentor-bookinggrid-select-date .dash-empty-sess-body{
    max-width: 32ch;
}
.mentor-bookinggrid-select-calendar-left .top-calender-wrap h2{
    color: #222;
    text-align: center;
    font-family: var(--switzer);
    font-size: 10.906px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.872px;
    text-transform: uppercase;
}
.mentor-bookinggrid-select-calendar-left .top-calender-wrap .calender-buttons{
    width: 100%;
    justify-content: space-between;
}
.mentor-bookinggrid-select-calendar-left .calendar .day.selected{
    color: #2A8751;
    background-color: #E4F6EA;
}
.mentor-bookinggrid-select-calendar-left .calendar{
    margin: 0;
}
.mentor-bookinggrid-select-calendar-left .calendar .month{
    margin-bottom: 0;
    padding: 9px;
}
.mentor-bookinggrid-select-calendar{
    display: flex;
    gap: 56px;
    align-items: center;
}
.mentor-bookinggrid-select-calendar-right-bottom-date-label{
    color: #999;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%;
    letter-spacing: 0.24px;
}
.mentor-bookinggrid-select-calendar-right-bottom-date-text{
    color: #444;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
    padding-top: 6px;
}
.mentor-bookinggrid-select-calendar-right-bottom-sep{
    width: 10px;
    height: 2px;
    background-color: #E3E3E3;
    margin: 16px 0;
}
.mentor-bookinggrid-select-calendar-right-bottom-box{
    border-radius: 8px;
    background: #FEFBF4;
    box-shadow: 1px 4px 8px 0px rgba(244, 243, 240, 0.60);
    display: inline-flex;
}
.mentor-bookinggrid-select-calendar-right-bottom-box-wrap{
    padding: 12px;
}
.mentor-bookinggrid-select-calendar-right-bottom-box-label{
    color: #858179;
    font-family:var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%;
    letter-spacing: 0.24px;
}
.mentor-bookinggrid-select-calendar-right-bottom-box-top{
    display: flex;
    gap: 6px;
    align-items: center;
}
.mentor-bookinggrid-select-calendar-right-bottom-box-top .mentor-bookinggrid-select-calendar-right-bottom-box-icon {
    display: flex;
}
.mentor-bookinggrid-select-calendar-right-bottom-box-bottom{
    padding-top: 14px;
}
.mentor-bookinggrid-select-calendar-right-bottom-box-head{
    color: #333;
    font-family: var(--switzer);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}
.mentor-bookinggrid-select-calendar-right-bottom-boxes{
    display: flex;
    gap: 8px;
}
.mentor-bookinggrid-select-calendar-right-top .slot-booking-note{
    background-color: #FEFBF4;
}
.mentor-bookinggrid-select-calendar-right-bottom{
    padding-top: 27px;
}
.mentor-bookinggrid-right-bottom{
    padding-left: 66px;
}
.mentor-bookinggrid-right-bottom-slots ul{
    display:flex;
    flex-wrap: wrap;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E3E3E3;
}
.mentor-bookinggrid-right-bottom-slots ul li{
    border-radius: 99px;
    background: #FFF;
    display: inline-flex;
    align-items: center;
    width: 104px;
    padding: 2px 0;
    justify-content: center;
    box-shadow: 1px 4px 8px 0px #F3F3F3;
    color: #454545;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%; /* 18.24px */
    letter-spacing: 0.24px;
    cursor: pointer;
    border: 2px solid #FFF;
}
.mentor-bookinggrid-right-bottom-slots ul li.fully-disabled{
    cursor: not-allowed;
    border: 1px solid #EAEAEA;
    background: #FCFCFC;
    /* pointer-events: none; */
}
.mentor-bookinggrid-right-bottom-slots ul li.selected-slot .slot-selected-icon{
    display: flex;
}
.mentor-bookinggrid-right-bottom-slots ul li.selected-slot{
    border: 2px solid #222;
}
.mentor-bookinggrid-button button{
    background-color: #E4F6EA;
    color: #2A8751;
}
.mentor-bookinggrid-button{
    text-align: end;
    padding-top: 40px;
}
.mentor-bookinggrid-session-details-head{
    color: #222;
    font-family: var(--switzer);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 144%;
    letter-spacing: 0.36px;
}
.mentor-bookinggrid-session-details{
    padding-left: 66px;
}
.mentor-bookinggrid-session-details-inputs{
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 32px;
}
.mentor-bookinggrid-session-details-input{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.mentor-bookinggrid-session-details-input input, .mentor-bookinggrid-session-details-input textarea{
    padding: 13px 15px;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    background: #F9F9F9;
    color: #777;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 152%;
    letter-spacing: 0.28px;
    max-width: 398px;
    box-sizing: border-box;
}
.mentor-bookinggrid-session-details-input textarea{
    resize: none;
    min-height: 154px;
    outline: none;
}
.mentor-bookinggrid-session-details-input-label{
    color: #555;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%;
    letter-spacing: 0.28px;
}
.mentor-bookinggrid-session-details-btn{
    padding-top: 25px;
}
.mentor-bookinggrid-session-details-btn button{
    background-color: #E4F6EA;
    color: #2A8751;
    width: 100%;
    max-width: 398px;
}
.mentor-bookinggrid-session-details-btn-2{
    padding-top: 12px;
}
.mentor-bookinggrid-session-details-btn-2 button{
    max-width: 398px;
    width: 100%;
    display: inline-flex;
    padding: 6px 6px;
    align-items: center;
    justify-content: center;
    color: #222;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%; /* 18.24px */
    letter-spacing: 0.24px;
    gap: 9px;
}
.mentor-bookinggrid-confirmPop .download-guide-btn{
    display: inline-flex;
    padding: 6px 21px;
    background-color: #E4F6EA;
    color: #2A8751;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.96px;
    text-transform: uppercase;
}
.mentor-bookinggrid-confirmPop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: .4s ease all;
}
.mentor-bookinggrid-confirmPop.active{
    opacity: 1;
    visibility: visible;
    z-index: 9999999999;
}
.mentorBooking{
    background-color: #FFF;
}
.mentor-booking-mentorDeets-note{
    padding-top: 27px;
}
.mentor-booking-mentorDeets-note .slot-booking-note-wrap{
    align-items: center;
}
.mentor-booking-mentorDeets-session-details{
    padding-top: 6px;
    display: flex;
    gap: 6px;
    align-items: center;
}
.mentor-booking-mentorDeets-session-details-sep{
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #AAA;
}
.mentor-booking-mentorDeets-session-head{
    color: #999;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%;
    letter-spacing: 0.24px;
}
.mentor-booking-mentorDeets-session-details-text{
    color: #444;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
}
.mentor-booking-mentorDeets-session-deets{
    padding-top: 27px;
}
.mentor-booking-mentorDeets-note .mentor-bookinggrid-select-calendar-right-bottom-box{
    background-color: #FFF;
}
.mentor-bookinggrid-paymentPop{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: .4s ease all;
}
.mentor-bookinggrid-paymentPop.active{
    opacity: 1;
    visibility: visible;
    z-index: 9999999999;
}
.mentor-bookinggrid-paymentPop button{
    background-color: #E4F6EA;
    color: #2A8751;
    padding: 6px 10px;
    text-transform: capitalize;
}
.mentor-bookinggrid-paymentPop > div{
    max-width: 371px;
    width: 100%;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.10);
    position: relative;
    padding: 42px 36px;
}
.mentor-bookinggrid-paymentPop iframe .InputContainer .InputElement{
    padding: 13px 15px !important;
    border-radius: 4px !important; 
    border: 1px solid #F0F0F0 !important;
    background: #F9F9F9 !important; 
}
.stripe-card-element{
    padding: 20px 0;
}
@media(max-width:1200px){
    .mentor-bookinggrid-left-wrap {
        padding: 28px 60px;
    }
    .mentor-bookinggrid-right-wrap {
        width: 95%;
    }
    .mentor-bookinggrid-select-calendar-right-bottom-boxes {
        flex-direction: column;
    }
}
@media(max-width:991px){
    .mentor-bookinggrid-wrap {
        flex-direction: column;
    }
    .mentor-bookinggrid-left {
        width: 100%;
        height: auto;
        min-height: inherit;
    }
    .mentor-bookinggrid-right {
        width: 100%;
        height: auto;
        border: none;
    }
}
@media(max-width:767px){
    .mentor-bookinggrid-select-calendar {
        flex-direction: column;
    }
    .mentor-bookinggrid-right-wrap {
        width: auto;
        max-width: 100%;
        padding: 0 20px 30px 20px;
    }
    .mentor-bookinggrid-right-top {
        padding: 0;
    }
    .mentor-bookinggrid-right-middle {
        padding: 0;
    }
    .mentor-bookinggrid-select-date {
        flex-direction: column;
        align-items: flex-start;
    }
    .mentor-bookinggrid-right-bottom {
        padding: 0;
    }
    .mentor-bookinggrid-left-wrap {
        padding: 28px 20px;
    }
    .mentor-bookinggrid-select-calendar-left .calendar {
        max-width: 100%;
    }
}