.ob-content {
    background-color: #fff;
    height: calc(100svh - 75px);
    overflow: hidden;
}
.obcleft-in {
    width: 402px;
    margin: 0 auto;
    padding-top: 50px;
}
.obc-right {
    width: 70ch;
    height: 100%;
}
.obc-left{
    width: 60%;
    overflow-y:auto;
    padding-bottom: 100px;
}
.obc-right{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.obc-right-grid1, .obc-right-grid4{
    position: relative;
    display:flex;
    height: calc(50vh - 37.5px);

}
.obc-right-grid1 img , .obc-right-grid4 img{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.obc-right-grid1{
    background-color: #FEC14B;
}
.obc-right-grid4{
    background-color: #24584B;
}
.obc-right-grid2, .obc-right-grid3{
    background-color: #EFEAD8;
    height: calc(50vh - 37.5px);
}
.obc-right-grid2 img, .obc-right-grid3 img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.stepLine {
    position: absolute;
    top: 75px;
    left: 0;
    width: 100%;
    height: 6px;
    background-color: #F2F2F2;
    z-index: 9999;
}
.stepLine .slFilled {
    background-color: #FF7252;
    border-radius: 0px 8px 8px 0px;
    position: absolute;
    left: 0;
    top: 0;
    width: 20%;
    height: 6px;
    transition: .2s ease all;
}
.stepLine .slFilled.width30{
    width: 30%;
}
.stepLine .slFilled.width40{
    width: 40%;
}
.stepLine .slFilled.width50{
    width: 50%;
}
.stepLine .slFilled.width60{
    width: 60%;
}
.stepLine .slFilled.width70{
    width: 70%;
}
.stepLine .slFilled.width80{
    width: 80%;
}
.stepLine .slFilled.width90{
    width: 90%;
}
.stepLine .slFilled.width100{
    width: 100%;
}
.obStepDetails {
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    font-feature-settings: 'liga' off;
    color: #555555;
    margin-bottom: 15px;
}
.obHeading h5 {
    max-width: 20ch;
    line-height: 120%;
}
.obc-right-grid-wrapper{
    position: relative;
    z-index: 1;
    padding: 25px;
}
.obc-right-grid-text span{
    color: #FFF;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 104%;
}
.obFormData{
    padding-top: 36px;
}
.css-13cymwt-control{
    border-radius: 4px !important;
    border: 1px solid #F0F0F0 !important;
    background-color: #F9F9F9 !important;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 152%;
    letter-spacing: 0.28px;
    width: 100%;
    box-sizing: border-box;
}
.obFromBackBtn{
    gap: 9px;
}
.obFormSkip{
    display: inline-flex;
    border-radius: 4px;
    padding: 6px 21px;
    border: 1px solid #C0C0C0;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    background-color: transparent;
}
.obBodyWrapper{
    padding-top: 10px;
}
.obBodyWrapper p{
    color: #666;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}
.select-wrapper{
    gap: 8px;
}
.bio-label{
    gap: 5px;
}
.login-profile-image{
    width: 68px;
    height: 68px;
    overflow: hidden;
    border-radius: 50%;
}
.login-profile-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.initial-letter{
    background-color: #24584B;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--switzer);
    font-size: 36px;
    height: 100%;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; 
    text-transform: capitalize;
    color: #FFF;
}
input[type="file"]{
    display: none;
}
.login-profile-btn label{
    color: #222;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    border-bottom: 1px solid  #222;
    cursor: pointer;
    display: inline-block;
}
.login-profile-btn span{
    color: #555;
    font-family:  var(--nunito);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 152%; /* 18.24px */
    letter-spacing: 0.24px;
}
.login-form-profile-wrapper{
    gap: 20px;
}
.login-form-bio-input{
    height: 142px;
}
.login-form-input textarea{
    resize: none;
    height: 142px;
    border: 1px solid #F0F0F0;
    background: #F9F9F9;
    color: #777;
}
.label-input-radio input[type="radio"]{
    width: auto;
    width: 18px;
    height: 18px;
}
.login-form-radio-wrap{
    gap: 25px;
}
.login-form-motivated-radio input[type="radio"]{
    position: absolute;
    height: 100%;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
}
.login-form-motivated-radio:has( > input[type="radio"]:checked){
    border: 2px solid #FFE3C2;
}
.login-form-motivated-radios{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
}
.login-form-motivated-radio{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    padding: 26px 12px;
    position: relative;
    transition: .2s ease all;
}
.login-form-motivated-radio-content-head{
    color: #222;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
    padding-top: 18px;
}
.login-form-motivated-radio-content-body{
    color: #555;
    font-family: var(--nunito);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 152%;
    letter-spacing: 0.24px;
    padding-top: 6px;
}
input#mentorTopic{
    height: 66px;
}
.login-form-input.dimmed{
    opacity: 0.5;
    pointer-events: none;
}
.experience-add-another-form-wrap .login-form-input select{
    width: 35%;
}
.login-form-input .is-present-checkbox{
    display: flex;
    align-items: center;
    gap: 6px;
}
@media only screen and (max-width:991px){
    .ob-content{
        flex-direction: column;
        height: auto;
        gap: 70px;
    }
    .obc-left{
        width: 100%;
    }
    .obc-right{
        width: 100%;
    }
    .obc-right-grid-text span{
        font-size: 26px;
    }
    .obc-left{
        padding-bottom: 0px;
    }
}

@media only screen and (max-width:767px){
    .obc-right-grid-text span{
        font-size: 24px;
    }
    .obcleft-in{
        width: 90%;
    }
    .obc-right-grid-wrapper{
        padding: 20px;
    }
    .login-profile-image {
        width: 54px;
        height: 54px;
    }
}
