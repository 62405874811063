.about-banner-heading span{
    color: #FEC14B;
}
.about-banner{
    position: relative;
    padding: 400px 0;
}
.about-banner-heading{
    position: relative;

    overflow: hidden;
}
.about-banner-heading h2{
    max-width: 24ch;
    margin: 0 auto;
}
.about-banner-heading span{
    display: inline-block;
}
.about-banner-heading::before{
    content: "";
    position: absolute;
    width: 35px;
    height: 35px;
    bottom: 65%;
    background-color: #F9E491;
    border-radius: 50%;
    left: 52.5%;
    z-index: 0;
}
.about-banner-body{
    text-align: center;
    width: 78ch;
    margin: 0 auto;
    padding-top: 15px;
    overflow: hidden;
}
.about-banner-body p{
    color: #404040;
}
.about-banner-image{
    width: 152px;
    height: 152px;
    border-radius: 50%;
    position: absolute;
}
.about-banner-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.ab-image-1{
    top: 20%;
    left: 5%;
}
.ab-image-2{
    top: 15%;
    left: 30%;
    scale: 0.8;
}
.ab-image-3{
    top: 19%;
    right: 19%;
}
.ab-image-4{
    top: 25%;
    right: 2%;
    scale: 0.6;
}
.ab-image-5{
    bottom: 28%;
    right: 5%;
    scale: 0.8;
}
.ab-image-6{
    bottom: 3%;
    right: 15%;
    scale: 0.8;
}
.ab-image-7{
    bottom: 10%;
    right: 35%;
}
.ab-image-8{
    bottom: 7%;
    left: 16%;
}
.ab-image-9{
    bottom: 31%;
    left: 2%;
    scale: 0.6;
}

.about-connections{
    background-color: #FF7352;
    position: relative;
}
.about-connections .container{
    position: relative;
    z-index: 2;
}
.about-connections::before{
    content: "";
    position: absolute;
    width: 100%;
    background-color: #FEFBF4;
    height: 72px;
    top: 0;
    z-index: 1;
}
.about-connections-ticker .marquee-wrap{
    bottom: -45px;
}
.about-inspires .insage-knowledge-at-work-content-body{
    padding-top: 59px;
}
.about-inspires, .about-meaningful{
    padding-top: 120px;
}
.about-meaningful .custom-container-fluid{
    padding-left: 0;
}
.about-inspires .custom-container-fluid{
    padding-right: 0;
}
.about-inspires  .insage-get-ahead-image, .about-meaningful .insage-get-ahead-image{
        margin-top: -50px;
    }
.about-inspires   .insage-get-ahead-label, .about-meaningful .insage-get-ahead-label{
      background-color:  #24584B;
    }
.about-meaningful .insage-knowledge-at-work-wrapper{
    flex-direction: row-reverse;
}
.about-meaningful .insage-knowledge-at-work-right{
        padding-left: 0px;
    }
.about-meaningful .insage-get-ahead-label{
        right: 0;
        left: inherit;
        border-radius: 4px 0px 0px 4px;
    }
.about-meaningful .insage-knowledge-at-work-left{
        padding-right: 105px;
        padding-left: 60px;
    }

.about-empowering-bottom{
    padding-top: 62px;
}


@media only screen and (max-width:1200px){
    .about-banner-image{
        width: 120px;
        height: 120px;
    }
}

@media only screen and (max-width:991px){
    .about-inspires .insage-get-ahead-image{
            margin-top: 0px;
        }
    .about-meaningful .insage-knowledge-at-work-wrapper{
           flex-direction: column-reverse; 
         }
    .about-meaningful .insage-knowledge-at-work-left{
            padding-left: 0;
            padding-right: 0;
         }
    .about-banner-image{
        width: 90px;
        height: 90px;
    }
}

@media only screen and (max-width:767px){
    .about-banner-heading h2{
        width: 100%;
    }
    .about-banner-body{
        width: 95%;
    }
    .ab-image-1{
        top: 10%;
    }
    .ab-image-3 {
        top: 10%;
        right: 5%;
    }
    .ab-image-2{
        top: 20%;
    }
    .ab-image-4{
        left: 4%;
        right: inherit;
    }
    .ab-image-9{
        bottom: 24%;
    }
    .ab-image-5 {
        bottom: 26%;
        right: 10%;
    }
    .ab-image-7 {
        bottom: 17%;
        right: 40%;
    }
    .ab-image-8{
        left: 5%;
    }
    .ab-image-6 {
        bottom: 6%;
        right: 6%;
    }  
    .about-banner-heading::before{
        left: 82.5%;
        bottom: 75%;
    }
    .about-meaningful .insage-knowledge-at-work-left{
        padding-left: 40px;
    }
}

@media only screen and (max-width:575px){
    .about-banner-heading span::before{
        width: 25px;
        height: 25px;
    }
}