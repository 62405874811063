.insage-get-ahead{
    padding: 317px 0px 200px 0px;
}
.insage-get-ahead-label{
    background-color: #FF7252;
    border-radius: 0px 4px 4px 0px;
    width: 32px;
    height: 224px;
    position: absolute;
    left: 0;
}
.insage-get-ahead-label div{
    writing-mode: vertical-lr;
    transform: rotate(-180deg);
    white-space: nowrap;
    text-align: center;
    padding: 32px 6px;
}
.insage-get-ahead-btn{
    padding-top: 40px;
}
@media only screen and (max-width:1200px){
    .insage-get-ahead{
        padding: 100px 0;
    }
}
@media only screen and (max-width:767px) {
    .insage-get-ahead-label{
        width: 200px;
        height: 32px;
        align-items: center;
        position: static;
        border-radius: 4px;
    }
    .insage-get-ahead-label div{
        writing-mode: horizontal-tb;
        transform: rotate(0deg);
        padding: 0;
    }
    .insage-get-ahead .insage-knowledge-at-work-right{
        padding-left: 0;
    }
    .insage-get-ahead{
        padding: 120px 0;
    }
}