.mentee-profile-main{
    background:#FFFFFF;
}
.mentee-profile{
    padding-top: 84px;
    padding-bottom: 100px;
}
.mentee-profile-background{
    position: relative;
    padding-top: 20%;
}
.mentee-profile-background img{
    position: absolute;
    inset: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.mpp-outer {
    position: relative;
}
.mentee-profile-display-pic{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    border: 7px solid #FDFDFD;
    margin-top: -10%;
    position: relative;
}
.mentee-profile-display-pic img{
    height: 100%;
    width: 100%;
    object-fit: cover;   
}
.mpp-outer .edit-icon {
    position: absolute;
    bottom: 10px;
    left: 150px;
    background-color: #F6F6F6;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .3s ease all;
}
.mpp-outer .edit-icon:hover {
    background-color: rgb(255, 172, 51);
}
.mpp-outer .edit-icon svg {
    width: 15px;
    height: 15px;
}
.mpp-outer .edit-icon svg path {
    transition: .3s ease all;
}
.mpp-outer .edit-icon:hover svg path {
    stroke: #fff;
}
.mentee-name{
    color: #222;
    font-family: var(--switzer);
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}
.mentee-time span{
    color: #222;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 144%; /* 20.16px */
    letter-spacing: 0.28px;
    text-transform: uppercase;
    display: block;
}
.mentee-time .time-mentee-dot{
    width: 3px;
    height: 3px;
    background-color: #AAA;
    border-radius: 50%;
}
.mentee-name-time{
    gap: 12px;
}
.mentee-time{
    gap: 4px;
}
.mentee-profile-edit-btn{
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: #F6F6F6;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.mentee-profile-left-details{
    width: 50%;
}
.mentee-profile-details-wrapper{
    align-items: start;
    gap: 100px;
}
.mentee-designation-name, .mentee-designation-company{
    color: #333;
    font-family: var(--switzer);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%;
    letter-spacing: 0.36px;
}
.mentee-designation-name{
    gap: 8px;
}
.mentee-designation-dot{
    width: 4px;
    height: 4px;
    background-color: #AAA;
    border-radius: 50%;
}
.mentee-profile-designation{
    gap: 10px;
    padding-top: 2px;
}
.mentee-profile-left-details{
    padding-top: 11px;
}
.mentee-profile-head {
    color: #333;
    font-family: var(--switzer);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-transform: capitalize;
}
.mentee-profile-overview {
    padding-top: 50px;
    padding-bottom: 60px;
    border-bottom: 2px solid #B4B4B4;
}
.mentee-profile-btn .primary-btn{
    padding: 12px 21px;
    border: 1px solid #C0C0C0;
    text-transform: capitalize;
    font-weight: 500;
}
.mentee-profile-overview-head-wrap{
    padding-bottom: 26px;
    border-bottom: 2px solid #B4B4B4;
}
.mentee-profile-about{
    padding-top: 24px;
    padding-bottom: 31px;
    border-bottom: 1px solid #E3E3E3;
}
.mentee-profile-about-head{
    color: #777;
    font-family: var(--switzer);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%;
    letter-spacing: 0.36px;
}
.mentee-profile-about-body p{
    color: #222;
    font-family: var(--nunito);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    padding-top: 20px;
    max-width: 55ch;
}
.mentee-profile-about-show-btn{
    color: #24584B;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
    padding-top: 10px;
    cursor: pointer;
}
.mentee-profile-guidance{
    padding: 31px 0;
    border-bottom: 1px solid #E3E3E3;

}
.mentee-profile-guidance-tag{
    display: inline-flex;
    padding: 4px 20px;
    border-radius: 99px;
    border: 1px solid #8F8F8F;
    color: #222;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%;
    letter-spacing: 0.24px;
}
.mentee-profile-guidance-tags{
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 55ch;
}
.mentee-profile-industry{
    width: 50%;
}
.mentee-profile-languages{
    width: 50%;
}
.mentee-profile-indus-lang{
    padding-top: 31px;
}
.mentee-profile-educations{
    padding: 60px 0;
    border-bottom: 2px solid #B4B4B4;
}
.mentee-profile-education-img{
    width: 65px;
    height: 65px;
    border-radius: 6px;
    background-color: #C4EEE3;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mentee-profile-education-name{
    color: #222;
    font-family: var(--switzer);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}
.mentee-profile-education-year{
    gap: 5px;
}
.mentee-profile-education-year span{
    color: #666;
    font-family: var(--nunito);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: block;
    letter-spacing: 0.12px;
}
.mentee-profile-education-year .mentee-profile-education-dot{
    width: 2px;
    height: 2px;
    background-color: #AAA;
    border-radius: 50%;    
}
.mentee-profile-education{
    gap: 14px;
}
.mentee-profile-education-name-year{
    gap: 8px;
}
.mentee-profile-educations-wrap{
    padding-top: 40px;
}
.mentee-profile-educations-wrap > div:not(:last-child){
    padding-bottom: 24px;
    border-bottom: 1px solid #E3E3E3;
}
.mentee-profile-educations-wrap > div:not(:first-child){
    padding-top: 24px;
}
.mentee-profile-experience{
    padding-top: 60px;
}
.mentee-profile-experience .mentee-profile-education-img{
    background-color: #FFF3DC;
}
.mentee-profile-right-details{
    border-radius: 8px;
    background-color: #FEFBF4;
    width: 50%;
}
.mentee-profile-right-details-wrap{
    padding: 36px 47px;
}
.mentee-profile-stats-head-wrap{
    padding-bottom: 20px;
    border-bottom: 1px solid #E3E3E3;
}
.mentee-profile-statistic-name{
    color: #222;
    font-family: var(--switzer);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 144%; 
    letter-spacing: 0.36px;
}
.mentee-profile-statistic-label{
    color: #888;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%;
    letter-spacing: 0.24px;
}
.mentee-profile-statistic{
    gap: 14px;
    width: 50%;
}
.mentee-profile-statistics{
    padding-top: 20px;
    flex-wrap: wrap;
    row-gap: 26px;
}
.complete-profile-pop-head{
    color: #333;
    font-family: var(--switzer);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 144%;
    letter-spacing: 0.36px;
}
.complete-profile-pop-accordions{
    padding-top: 32px;
}
.complete-profile-pop-accordions .complete-profile-pop-accordion:not(:last-child){
    padding-bottom: 16px;
    border-bottom: 1px solid #E3E3E3;
}
.complete-profile-pop-accordions .complete-profile-pop-accordion:not(:first-child){
    padding-top: 16px;
}
.complete-profile-pop-body{
    display: grid;
    grid-template-rows: 0fr;
    opacity: 0;
    --webkit-transition: all .4s;
    transition: all .4s;
}
.complete-profile-pop-accordion.active .complete-profile-pop-body{
    grid-template-rows: 1fr;
    opacity: 1;
}
.complete-profile-pop-body div{
    overflow: hidden;
}
.complete-profile-pop-body p{
    color: #555;
    font-family: var(--nunito);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 152%;
    letter-spacing: 0.24px;
    padding-top: 10px;
}
.complete-profile-pop-body .primary-btn, .overview-profile-pop-btn .primary-btn{
    padding: 6px 21px;
    background-color: #E4F6EA;
    color: #2A8751;
    margin-top: 16px;
}
.overview-profile-pop-btn{
    padding-top: 42px;
}
.overview-profile-pop-btn .primary-btn{
    width: 100%;
    justify-content: center;
}
.complete-profile-pop-head-arrow{
    cursor: pointer;
}
.complete-profile-pop-head-arrow svg{
    transition: .2s ease all;
}
.complete-profile-pop-head-arrow.active svg{
    transform: rotate(180deg);
}
.complete-profile-pop-head-arrow.active {
    pointer-events: none;
}
.complete-profile-pop, .overview-profile-pop, .intro-profile-pop, .education-profile-pop, .experience-profile-pop{
    display: flex;
    align-items: center;
    justify-content: center;
}
.overview-profile-pop-form{
    padding-top: 32px;
}
.experience-edit-box{
    border-radius: 4px;
    border: 1px solid #E3E3E3;
}
.experience-edit-box-wrap{
    padding: 13px 11px;
    position: relative;
    transition: .2s ease all;
    opacity: 1;
    height: auto;
}
.experience-edit-box-wrap.active{
    opacity: 0;
    height: 0;
    padding: 0;
}
.experience-edit-text-head{
    color: #222;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
}
.experience-edit-text{
    gap: 6px;
}
.experience-edit-text-comp-year{
    gap: 5px;
    flex-wrap: wrap;
}
.experience-edit-text-comp-year span{
    color: #666;
    font-family:var(--nunito);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 152%;
    letter-spacing: 0.24px;
    display: block;
}
.experience-edit-text-comp-year .experience-edit-text-dot{
    width: 2px;
    height: 2px;
    background-color: #AAA;
    border-radius: 50%;
}
.experience-edit-icon{
    width: 35px;
    height: 35px;
    border-radius: 6px;
    background-color: #FFF3DC;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 4px;
}
.experience-edit-icon-text{
    gap: 8px;
    align-items: flex-start;
}
.experience-edit-delete-btn{
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: #F6F6F6;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.experience-edit-delete-btns{
    gap: 8px;
}
.overview-profile-pop-form .experience-edit-box:not(:first-child){
    margin-top: 16px;
}
.experience-add-another-btn{
    color: #222;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    text-transform: capitalize;
    gap: 5px;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    --webkit-transition: all .4s;
    transition: all .4s;
}
.experience-add-another-btn.active{
   opacity: 0;
   visibility: hidden;
   z-index: -2;
}
.experience-add-another-form-buttons{
    gap: 9px;
}
.experience-add-cancel-btn .primary-btn{
    padding: 6px 21px;
    background-color: transparent;
    border: 1px solid #E3E3E3;
    color: #222;
    width: 100%;
}
.experience-add-save-btn .primary-btn{
    padding: 6px 21px;
    width: 100%;
} 
.experience-add-another-form{
    width: 100%;
    display: grid;
    grid-template-rows: 0fr;
    opacity: 0;
    --webkit-transition: all .4s;
    transition: all .4s;
}
.experience-add-another-form.active{
    grid-template-rows: 1fr;
    opacity: 1;
}
.experience-add-another-form div{
    overflow: hidden;
}
.experience-add-another-form-buttons{
    padding-top: 22px;
}
.experience-add-another-form-wrap{
    padding: 12px;
}
.education-edit-box .experience-edit-icon{
    background-color: #C4EEE3;
}
.loading-indicator{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    transition: .4s ease all;
    inset: 0px;
    background: rgba(255, 255, 255, 0.75);
}
.loading-indicator svg{
    width: 50px;
    height: 50px;
    position: relative;
}
.mentee-profile-social-link{
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 4px 7px 0px #EFEFEF;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
}
.mentee-profile-social-link-cont{
    margin: 10px;
    width: 20px;
    height: 20px;
    border-radius: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0A66C2;
}
.mentee-profile-social-link{
    margin-top: 16px;
}
.mentee-profile-social{
    padding-top: 31px;
    padding-bottom: 31px;
    border-bottom: 1px solid #E3E3E3;
}
.mentee-profile-video{
    padding-top: 31px;
}
.mentee-profile-video-wrapper{
    border-radius: 8px;
    overflow: hidden;
    margin-top: 20px;
}
.mentee-profile-video-wrapper video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.overview-video-cont{
    padding-top: 24px;
}
.overview-video-wrap video{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.overview-video-wrap{
    border-radius: 8px;
    overflow: hidden;
}
.overview-video-text{
    text-align: center;
    padding-top: 12px;
}
.overview-video-btn{
    padding-top: 12px;
}
.overview-video-btn label{
    border-radius: 4px;
    border: 1px solid #222;
    width: 100%;
    padding: 6px 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
}
.overview-video-btn .error-name-onb {
    text-align: center;
    margin-top: 4px;
}
.videoremove {
    margin-top: 10px;
    text-align: center;
}
.mentee-sticky{
    border-bottom: 1px solid #E3E3E3;
    background-color: #FFF;
    position: fixed;
    top: 84px;
    z-index: 99;
    transition: .4s ease all;
    width: 100%;
    padding: 20px 0;
    visibility: hidden;
    opacity: 0;
    transition: .3s ease all;
}
.mentee-sticky.active{
    visibility: visible;
    opacity: 1;
}
.mentor-sticky-img{
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
}
.mentor-sticky-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.mentor-sticky-details-wrap{
    gap: 12px;
}
.mentor-sticky-name-link{
    gap: 9px;
}
.mentor-sticky-name-link a{
    display: flex;
}
.mentor-sticky-name{
    color: #222;
    font-family: var(--switzer);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}
.mentor-sticky-name-details{
    gap: 6.5px;
}
.mentor-sticky-designation-deets{
    gap: 6px;
}
.mentor-sticky-designation-deets .time-mentee-dot{
    width: 4px;
    height: 4px;
    background-color: #AAA;
    border-radius: 50%;
}
.mentor-sticky-designation-deets span{
    color: #666;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%;
    letter-spacing: 0.28px;
}
.mentee-profile-about-body{
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: .2s ease all;
}
.mentee-profile-about-body.expanded{
    -webkit-line-clamp:inherit;
}
.mentee-profile-overview .favMessage {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.favMessage .favmesTop {
    display: grid;
    grid-template-columns: 4fr 3fr 1fr;
    align-items: center;
    gap: 15px;
}
.favmesTop .fvtPrice {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    padding: 12px 10px;
}
.fvtPrice .fvpriceIcon {
    margin-right: 8px;
    display: inline-flex;
}
.fvpricePrice {
    color: #222;
    font-weight: 500;
    font-size: 32px;
    font-family: var(--switzer);
}
.fvpricedata {
    padding-left: 20px;
    position: relative;
    margin-left: 10px;
    display: inline-block;
    padding-top: 2px;
    font-size: 13px;
}
.fvpricedata:before {
    content: '';
    position: absolute;
    left: 5px;
    top: 10px;
    width: 3px;
    height: 3px;
    background-color: #AAA;
    border-radius: 50%;
}
.fvtBook {
    border-radius: 4px;
    background: #E4F6EA;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 68px;
}
.fvtBook button {
    padding: 6px 21px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    color: #2A8751;
    width: 100%;
    height: 100%;
    transition: .2s ease all;
}
.fvtBook button:hover {
    background-color: #2A8751;
    color: #fff;
}
.fvtFavourite {
    border-radius: 4px;
    background: #fff;
    text-align: center;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 4px 7px 0px #EFEFEF;
}
.fvtFavourite button {
    width: 100%;
    height: 100%;
}
.fvtFavourite button.filled {
    background-color: #FF5656;
}
.fvtFavourite button.filled svg path {
    fill:#fff;
}
.fvtFavourite button.disabled svg path {
    fill:#FF5656;
}
.favMessage .favmesBottom {
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    background: linear-gradient(277deg, rgba(252, 252, 252, 0.23) 3.87%, rgba(252, 252, 252, 0.23) 99.1%);
    padding: 20px 22px;
}
.mentorMessageInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.mentorMessageInner .mmi-left {
    display: flex;
    align-items: center;
    gap: 10px;
}
.mmi-icon {
    display: flex;
}
.mmi-text {
    color: #666;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.24px;
    max-width: 34ch;
}
button:disabled {
    pointer-events: none;
    background-color: #E5E5E5!important;
    color: #fff!important;
}
.mentorMessageInner .mmi-right button.disabled {
    background-color: #E5E5E5;
}
.mentee-profile-wrapper .mentor-reviews .slciic-reviews {
    margin-top: 60px;
    padding-top: 60px;
    border-top: 2px solid #B4B4B4;
}


@media only screen and (max-width:1400px){
    .mentee-name{
        font-size: 36px;
    }
    .mentee-designation-name, .mentee-designation-company {
        font-size: 16px;
    }
    .mentee-profile-head{
        font-size: 26px;
    }
    .mentee-profile-btn .primary-btn{
        padding: 10px 21px;
    }
    .mentee-profile-about-head {
        font-size: 16px;
    }
    .mentee-profile-about-body p{
        font-size: 14px;
    }
    .mentee-profile-statistic-name{
        font-size: 16px;
    }
    .mentee-profile-education-name{
        font-size: 18px;
    }
}

@media only screen and (max-width:1100px){
    .mentee-profile-details-wrapper{
        gap: 50px;
    }
    .mentee-profile-details-wrapper{
        flex-direction: column;
    }
    .mentee-profile-left-details{
        width: 100%;
    }
    .mentee-profile-right-details{
        width: 100%;
    }
    .mentee-profile-background{
        padding-top: 30%;
    }
}
@media only screen and (max-width:991px){
    .mentee-name {
        font-size: 32px;
    }
    .mentee-sticky{
        top: 71px;
    }
}

@media only screen and (max-width:767px){
    .mentee-name {
        font-size: 28px;
    }
    .mentee-designation-name, .mentee-designation-company {
        font-size: 14px;
    }
    .mentee-profile-head{
        font-size: 22px;
    }
    .mentee-profile-about-head {
        font-size: 14px;
    }
    .mentee-profile-statistic-name{
        font-size: 14px;
    }
    .mentee-profile-education-name{
        font-size: 16px;
    }
    .mentee-name-time{
        flex-direction: column;
    }
    .mentee-profile-designation{
        padding-top: 12px;
    }
    .mentee-profile-background {
        padding-top: 50%;
    }
    .mentee-profile-display-pic{
        margin: -15% auto 0 auto;
    }
    .mentee-name-time-btn-wrap{
        align-items: baseline;
    }
    .mentee-profile-right-details-wrap {
        padding: 30px 20px;
    }
    .complete-profile-pop-head {
        font-size: 16px;
    }
    .experience-edit-box-wrap{
        flex-direction: column;
        align-items: start;
        gap: 20px;
    }
    .experience-add-save-btn .primary-btn{
        padding: 6px 17px;
    }
    .favMessage .favmesTop {
        grid-template-columns: 1fr 1fr;
    }
    .favMessage .favmesTop div:first-child {
        grid-column: 1 / -1;
    }
    .fvtBook button {
        padding: 22px 21px;
    }
    .mentorMessageInner {
        flex-direction: column;
        gap: 15px;
    }
    .mmi-text {
        text-align: center;
    }
    .mentorMessageInner .mmi-left {
        flex-direction: column;
    }
    .mpp-outer .edit-icon {
        left: 0;
        right: -100px;
        margin: 0 auto;
    }
}