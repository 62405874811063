.blog-in-design {
    margin-top: 84px;
    /* border-top: 1px solid #E3E3E3; */
}
.blog-in-design .left-line {
    
}

.blogInner-head{
    padding-top: 40px;
}
.blogInner-head .bih-head h3{
    color: #222;
    font-feature-settings: 'ss08' on, 'clig' off, 'liga' off;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 48px */
}
.blogInner-head .bih-head{
    max-width: 75ch;
    margin: 0 auto;
}
.cat-read-time{
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 12px;
    max-width: 75ch;
    margin-left: auto;
    margin-right: auto;
}
.cat-read-time .read-time{
    color: #888;
    font-family: var(--nunito);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.035px;
}
.blogauth-name{
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 28px;
    max-width: 75ch;
    margin-left: auto;
    margin-right: auto;
}
.blogauth-name .bi-img{
    width: 49px;
    height: 49px;
    border-radius: 50%;
    overflow: hidden;
}
.blogauth-name .bi-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.blogauth-name .bi-auth .auth{
    color: #222;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
}
.blogauth-name .bi-auth .date{
    color: #888;
    font-family: var(--nunito);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.035px;
    margin-top: 4px;
}
.blogInner-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.blogInner-img .bii-img {
    width: 100%;
    height: auto;
    overflow: hidden;
    aspect-ratio: 6/2;
}
.blogInner-img .bii-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.bit-wrapper{
    max-width: 65ch;
    margin: 0 auto;
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 26px;
}
.ql-editor h1, .bit-wrapper h1 {
    color: #222;
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 30px;
    text-transform: inherit;
    text-align: inherit;
}
.ql-editor h2, .bit-wrapper h2 {
    color: #222;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 30px;
    text-transform: inherit;
    text-align: inherit;
}
.ql-editor h3, .bit-wrapper h3 {
    color: #222;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 30px;
    text-transform: inherit;
    text-align: inherit;
}
.ql-editor h4, .bit-wrapper h4 {
    color: #222;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 30px;
    text-transform: inherit;
    text-align: inherit;
}
.ql-editor h5, .bit-wrapper h5{
    color: #222;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 30px;
    text-align: inherit;
}
.bit-wrapper h6 {
    
}
.bit-wrapper ul, .bit-wrapper ol {
    padding: 10px 0 10px 20px;
    list-style: revert;
}
.bit-wrapper li {
    color: #222;
    font-family: var(--nunito);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 28.8px */
    letter-spacing: 0.18px;
}
.bit-wrapper blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
}
.bit-wrapper p{
    color: #222;
    font-family: var(--nunito);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 28.8px */
    letter-spacing: 0.18px;
    margin-bottom: 12px;
}
.bit-wrapper p .ql-size-large {
    font-size: x-large;
    line-height: 130%;
}
.bit-wrapper p .ql-size-huge {
    font-size: xxx-large;
    line-height: 130%;
}
.bit-wrapper p img {
    width: 100%;
    margin: 30px 0;
}
.bit-wrapper p a {
    color: #FEC14B!important;
    transition: .3s ease all;
}
.bit-wrapper p a:hover {
    color: #24584b!important;
}
.bit-wrapper p span {
    display: inline-block;
}
.ql-align-right {
    text-align: right;
}
.ql-align-center {
    text-align: center;
}
.bit-wrapper .bit{
    display: flex;
    flex-direction: column;
    /* gap: 30px; */
}
.bit-wrapper .bit-mid{
    margin-top: 60px;
}
.bit-wrapper .bit-img {
    position: relative;
    padding-top: 57%;
    margin: 50px 0;
    border-radius: 8px;
    overflow: hidden;
}
.bit-wrapper .bit-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    inset: 0;
}
.blogInnertxt .blog-container-for-line {
    margin-right: 0;
}
.blogInnertxt{
    margin-top: 50px;
    margin-bottom: 50px;
}
.like-share {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 60px;
}
.like-share .likes{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.like-share .likes p{
    color: #666;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
    margin-left: 14px;
}
.like-share .share-bookmark{
    display: flex;
    align-items: center;
    gap: 38px;
}
.like-share .share-bookmark .share{
    cursor: pointer;
    position: relative;
}
.like-share .share-bookmark .bookmark{
    cursor: pointer;
}
.like-share .share-bookmark .bookmark svg:nth-child(2){
    display: none;
  }
  .like-share .share-bookmark .bookmark.active svg:nth-child(2){
    display: block;
  }
  .like-share .share-bookmark .bookmark.active svg:nth-child(1){
      display: none;
  }
.like-share .share-bookmark .share-links{
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.10);
    position: absolute;
    left: -70px;
    min-width: 162px;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: .4s ease all;
}
.like-share .share-bookmark .share:hover .share-links{
    opacity: 1;
    visibility: visible;
    z-index: 2;
}
.like-share .share-bookmark .share-links-container{
    padding: 8px 10px;
}
.share-facebook-text, .share-linkedin-text, .share-twitter-text, .copy-link-text{
    color: #666;
    font-family:var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    text-transform: capitalize;
}
.copy-link, .share-twitter, .share-linkedin, .share-facebook{
    gap: 10px;
}
.copy-link{
    padding-bottom: 12px;
    border-bottom: 1px solid #f1f1f1;
}
.share-twitter{
    padding-top: 12px;
}
.share-linkedin{
    padding: 14px 0;
}
.similar-blogs {
    gap: 41px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 50px;
}
.similar-blogs .item a{
    display: flex;
    flex-direction: column;
    gap: 22px;
}
.similar-blogs .item .img-wrapper {
    position: relative;
    padding-top: 100%;
    border-radius: 8px;
    overflow: hidden;
}
.similar-blogs .item .img-wrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    inset: 0;
}
.similar-blogs .item .auth-cat .auth-img-name {
    display: flex;
    align-items: center;
    gap: 5px;
}
.similar-blogs .item .blog-desp {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.similar-blogs .item .auth-cat {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}
.similar-blogs .item .blog-title h5 {
    color: #222;
    font-family: var(--switzer);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: underline;
    text-transform: capitalize;
}
.similar-blogs .item .blog-para {
    max-width: 25ch;
}
.similar-blogs .item .blog-para h1,
.similar-blogs .item .blog-para h2,
.similar-blogs .item .blog-para h3,
.similar-blogs .item .blog-para h4,
.similar-blogs .item .blog-para h5,
.similar-blogs .item .blog-para h6 {
    display: none;
}
.similar-blogs .item .blog-para p {
    color: #555;
    font-family: var(--nunito);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}
.similar-blogs .item .blog-para img {
    display: none;
}
.similar-blogs .item .blog-para > p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}
.blogInnersim h3{
    color: #222;
    font-feature-settings: 'ss08' on, 'clig' off, 'liga' off;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 48px */
}
.blogInnersim{
    padding-top: 100px;
    padding-bottom: 180px;
}
.blogInnercomment {
    margin: 0 auto;
    padding: 0 20px;
    /* margin-right: calc((100vw - 900px)/2); */
    margin-bottom: 100px;
}
.blogInnercomment .comment-wrap {
    max-width: 65ch;
    margin: 0 auto;
}
.comment-wrap .comment-top {
    margin-bottom: 20px;
}
.comment-top h4 {
    margin-bottom: 10px;
    font-weight: 600;
}
.comment-top p {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    color: #222;
    flex-wrap: wrap;
}
.comment-top p span {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    font-family: var(--switzer);
    border-bottom: 1px solid #222;
    display: inline-flex;
    margin: 0 5px;
    cursor: pointer;
}
.comment-wrap .comment-bottom {
    margin-top: 30px;
}
.comment-bottom .comment-addition {
    margin-top: 30px;
}
.comment-addition .commentBox-outer {
    border-radius: 8px;
    background: #F8F8F8;
    padding: 13px 0px;
}
.commentBox-in {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.commentBox-in .cbi-top {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 15px;
}
.cbi-top .cbit-image {
    width: 45px;
    height: 45px;
    overflow: hidden;
    border-radius: 50%;
}
.cbit-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.cbi-top .cbit-name {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 144%; /* 25.92px */
    letter-spacing: 0.36px;
    font-family: var(--switzer);
}
.cbi-bottom {
    padding: 0 15px;
}
.cbi-bottom textarea {
    border: none;
    width: 100%;
    background-color: transparent;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    color: #777;
}
.cbi-bottom textarea:focus-visible {
    outline: none;
}
.cbi-bottom .cbib-post {
    border-top: 1px solid #E3E3E3;
    padding-top: 15px;
}
.cbib-post .post-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
}
.post-buttons .clear {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    font-family: var(--switzer);
    border-bottom: 1px solid #222;
}
.comment-bottom .comment-listing {
    /* margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid #E3E3E3; */
}
.comment-listing .item {
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid #E3E3E3;
}
.comment-item .ci-top {
    display: flex;
    align-items: center;
    gap: 15px;
    position: relative;
}
.ci-top .ci-user-image {
    width: 45px;
    height: 45px;
    overflow: hidden;
    border-radius: 50%;
}
.ci-top .ci-user-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.ci-top .ci-user-details .ci-user-details-name {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
    font-family: var(--switzer);
}
.ci-user-details-name span {
    padding: 2px 10px;
    align-items: flex-start;
    border-radius: 14px;
    background: #E4F6EA;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: #222;
}
.ci-user-details-time {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%; /* 18.24px */
    letter-spacing: 0.24px;
    font-family: var(--switzer);
    color: #666;
}
.ci-bottom {
    margin-top: 20px;
}
.ci-bottom p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    color: #222;
}
.cbit-report{
    position: absolute;
    right: 0;
    cursor: pointer;
}
.cbit-report .tooltiptext{
    color: #FFF;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    text-transform: capitalize;
    padding: 4px 12px;
    border-radius: 4px;
    background: #4E4E4E;
    position: relative;
}
.cbit-report .tooltiptext svg{
    position: absolute;
    top: -3px;
    right: -.5px;
}
.cbit-report div{
    position: absolute;
    right: 0;
    opacity: 0;
    transition: .4s ease all;
}
.cbit-report:hover div{
    opacity: 1;
}
.report-pop-blog .insage-login.dashboard-popups{
    width: 432px;
}
.report-pop-blog .insage-login.dashboard-popups .login-form-input textarea{
    height: 98px;
}
.comm-pop-blog .insage-login.dashboard-popups{
    width: 444px;
}
.comm-pop-blog .insage-login.dashboard-popups .popup-description p{
    color: #555;
}
.community-guidleines-swiper{
    margin-top: 38px;
    border-radius: 8px;
    background: #FBFBFB;
    padding: 20px 29px;
}
.community-guidleines-swiper > .swiper{
    position: relative;
}
.community-slider-navigation{
    position: absolute;
    right: 0;
    top: 0;
    gap:5px;
    z-index: 9;
}
.community-guidelines-slide-top-icon{
    width: 28px;
    height: 28px;
    border-radius: 4px;
    background: #EAEAEA;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.community-guidelines-slide-top-head{
    color: #222;
    font-family: var(--switzer);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}
.community-guidelines-slide-top{
    gap: 8px;
}
.community-guidelines-slide-bottom{
    padding-top: 13px;
}
.community-guidelines-slide-bottom p{
    color: #555;
}
.community-slider-left, .community-slider-right{
    cursor: pointer;
}
.community-slider-left.swiper-button-disabled, .community-slider-right.swiper-button-disabled{
    opacity: .5;
    cursor: not-allowed;
}
.community-slider-left svg{
    transform: rotate(-180deg);
}
.community-guidelines-button{
    padding-top: 18px;
}
.community-guidelines-button button.primary-btn.border{
    border: 1px solid #C0C0C0;
    padding: 6px 21px;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    text-transform: capitalize;
}
.emptyspacer {
    height: 100px;
}
/* Responsive Css Starts */
/* Max Width 1800px */
@media(max-width: 1800px){
}
/* Max Width 1800px Ends */
/* Max Width 1700px */
@media(max-width: 1700px){
}
/* Max Width 1700px Ends */
/* Max Width 1600px */
@media(max-width: 1600px){

}
/* Max Width 1600px Ends */
/* Max Width 1500px */
@media(max-width: 1500px){
}
/* Max Width 1500px Ends */
/* Max Width 1400px */
@media(max-width: 1400px){
}
/* Max Width 1400px Ends */
/* Max Width 1300px */
@media(max-width: 1300px){
    .blogInner-head .bih-head h3, .blogInnersim h3{
        font-size: 36px;
    }
}
/* Max Width 1300px Ends */
/* Max Width 1200px */
@media(max-width: 1200px){
    .blog-in-design .left-line {
        margin-left: 0;
        border: none;
    }
    .blog-container-for-line {
        margin-right: auto;
    }
}
/* Max Width 1200px Ends */
/* Max Width 1100px */
@media(max-width: 1100px){
}
/* Max Width 1100px Ends */
/* Max Width 991px */
@media(max-width: 991px){
    .blogInner-head .bih-head h3, .blogInnersim h3{
        font-size: 30px;
    }
    .similar-blogs{
        grid-template-columns: 1fr 1fr;
    }
    .comm-pop-blog .insage-login.dashboard-popups {
        width: 100%;
    }
}
/* Max Width 991px Ends */
/* Max Width 767px */
@media(max-width: 767px){
    .blogInner-head {
        padding-top: 60px;
    }
    .blogInnersim {
        padding-top: 100px;
        padding-bottom: 140px;
    }
    .blogInnercomment {
        margin-right: 0;
    }
    .blogInner-img .bii-container {
        padding-left: 0;
    }
    .blogInner-img {
        margin-top: 40px;
    }
    .blogInner-img .bii-img {
        width: 100%;
        height: auto;
        max-height: inherit;
    }
    .blogInnertxt {
        margin-top: 44px;
    }
    .bit-wrapper p {
        font-size: 16px;
    }
}
/* Max Width 767px Ends */
/* Responsive Css Ends */