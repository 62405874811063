.insage-login.dashboard-popups {
    width: 530px;
}
.insage-login.dashboard-popups.extended-popup {
    width: 700px;
}
.popup-description {
    margin-top: 10px;
}
.popup-description .pd-para {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%; /* 25.92px */
    letter-spacing: 0.36px;
    color: #333;
}
.dashboard-popups .overview-profile-pop-form {
    border-top: 1px solid #E3E3E3;
    margin-top: 30px;
}
.dashboard-popups .overview-profile-pop-form.no-border {
    border: none;
    margin-top: 0;
    padding-top: 0;
}
.overview-profile-pop-form.no-border .overview-profile-pop-btn {
    padding-top: 30px;
}
.overview-profile-pop-form .slci-content {
    height: auto;
    max-height: inherit;
    opacity: 1;
}
.overview-profile-pop-form .slci-in-content {
    margin-top: 0;
}
.overview-profile-pop-form .slciic-reviews .reviewBox {
    background: #FBFBFB;
}
.insage-login-wrapper .review-stars li {
    margin-top: 0;
}
.overview-profile-pop-btn.flexbuttons {
    display: flex;
    align-items: center;
    gap: 10px;
}
.pd-para span {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 144%; /* 25.92px */
    letter-spacing: 0.36px;
    color: #24584C;
    display: inline;
    font-family: var(--switzer);
}
.insage-login-wrapper .pd-datetime {
    display: flex;
    align-items: center;
    gap: 35px;
    margin-top: 12px;
}
.pd-datetime .pd-date, .pd-datetime .pd-time {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.035px;
}
.pd-date span, .pd-time span {
    display: flex;
    align-items: center;
    margin-right: 4px;
}
.pd-time .timer {
    display: flex;
    align-items: center;
}
.timer .duration {
    padding-left: 10px;
    margin-left: 10px;
    position: relative;
}
.timer .duration::before {
    content: '';
    position: absolute;
    left: 0;
    top: 7px;
    width: 3px;
    height: 3px;
    background-color: #BABABA;
    border-radius: 50%;
}
.popup-reminder {
    border-radius: 4px;
    border: 1px solid #E3E3E3;
    padding: 17px;
    background: #FFF;
}
.popup-reminder .prm-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.28px;
    display: flex;
    align-items: center;
    gap: 6px;
    color: #222;
    font-family: var(--switzer);
}
.prm-label span {
    display: flex;
}
.prm-content {
    margin-top: 8px;
    padding-left: 20px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    color: #777;
}
.expanded-session-pop {
    display: flex;
    align-items: center;
    gap: 15px;
}
.expanded-session-pop .esp-image {
    width: 56px;
    height: 56px;
    overflow: hidden;
    border-radius: 50%;
}
.esp-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.esp-data > div {
    margin-top: 0!important;
}
.esp-data .pd-datetime {
    padding-top: 5px;
}
.foot-upcoming .foot-cancel {
    display: flex;
    align-items: center;
    gap: 10px;
}
.writereviewWrapper .login-head {
    margin-bottom: 20px;
}
.write-review-pop {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
}
.write-review-pop .wrp-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
}
.wrp-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.write-review-pop .wrp-data {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.wrp-data .mentor-name {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    font-family: var(--switzer);
}
.wrp-data .mentor-details {
    display: flex;
    align-items: center;
    gap: 8px;
}
.mentor-details .wrpm-data {
    display: flex;
    align-items: center;
    gap: 0px;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
    color: #777;
}
.overview-profile-pop-form .put-ratings-wrap {
    border-top: 1px solid #E3E3E3;
    padding-top: 20px;
}
.put-ratings-wrap .prw-head {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%; /* 21.28px */
    letter-spacing: 0.28px;
    color: #555;
    font-family: var(--switzer);
    margin-bottom: 15px;
}
.put-ratings-wrap .prr-ratings {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.prr-ratings .pr-rate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}
.pr-rate .prr-head {
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
    text-transform: capitalize;
    color: #333;
}
.pr-rate .prr-content {
    display: flex;
    align-items: center;
    gap: 2px;
}
.prr-content > span {
    display: inline-flex;
}
.prr-content svg {
    width: 16px;
    height: 16px;
}
.prr-reset {
    display: flex;
}
.prr-reset button {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    text-transform: capitalize;
    color: #24584B;
    font-family: var(--switzer);
}
.review-submit {
    margin-top: 30px;
}
.review-submit button {
    display: block;
    width: 100%;
    text-align: center;
}
.insage-login.dashboard-popups.postingpopup {
    width: 65%;
}
.postingpopup .overview-profile-pop-form {
    border: none;
    margin-top: 0;
}
.postpopup-wrap {
    display: grid;
    grid-template-columns: 3fr 4fr;
    gap: 70px;
}
.postpopup-wrap .pp-colm {

}
.pp-colm .ppc-head {
    margin-bottom: 20px;
}
.pp-colm .ppc-head h6 {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%; /* 25.92px */
    letter-spacing: 0.36px;
    display: flex;
    align-items: center;
}
.pp-colm .ppc-head h6 span {
    display: inline-block;
    margin-left: 4px;
    font-weight: 600;
}
.ppc-content > input {
    display: block;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #222;
}
.ppc-content .imgbox {
    height: 0;
    padding-top: 50%;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
}
.imgbox img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.ppc-content .imageinfo {
    color: #999;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 152%; /* 18.24px */
    letter-spacing: 0.24px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 8px;
}
.ppc-content .note-text {
    margin-top: 15px;
}
.ppc-content .login-form-input label {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 28.8px */
    letter-spacing: 0.18px;
    color: #222;
}
.ppc-content .login-form-input p {
    color: #666;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 152%; /* 18.24px */
    letter-spacing: 0.24px;
}
.ppc-content .login-form-input label {
    
}
.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.inputfile + label {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    text-transform: capitalize;
    display: block;
    border-radius: 4px;
    border: 1px solid #222;
    text-align: center;
    padding: 8px;
    transition: .2s ease all;
    font-family: var(--switzer);
}
.inputfile:focus + label,
.inputfile + label:hover {
    background-color: #222;
    color: #fff;
}
.inputfile + label {
	cursor: pointer; /* "hand" cursor */
}
.inputfile:focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}
.inputfile + label * {
	pointer-events: none;
}
.ppc-content .overview-profile-pop-btn {
    padding-top: 10px;
}
.ppc-content .overview-profile-pop-btn .primary-btn {
    width: -webkit-fill-available;
    padding: 14px 21px;
}
span.errormessage {
    color: red;
    font-weight: 500;
    font-size: 14px;
}

@media(max-width:1200px){
    .postpopup-wrap {
        gap:30px;
    }
    .pp-colm .ppc-head h6 {
        font-size: 16px;
    }
    .ppc-content .login-form-input label {
        font-size: 16px;
    }
}
@media(max-width:991px){
    .insage-login.dashboard-popups.postingpopup {
        width: 90%;
    }
    .editor-content .post-title input {
        font-size: 30px;
    }
}

@media(max-width:767px){
    .postpopup-wrap {
        grid-template-columns: 1fr;
    }
    .insage-login.dashboard-popups, .insage-login.dashboard-popups.extended-popup {
        width: 95%;
    }
    .expanded-session-pop {
        flex-direction: column;
        align-items: flex-start;
    }
    .insage-login-wrapper .pd-datetime {
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
    }
}