.insage-quote{
    padding: 200px 0;
}
.insage-quote-author{
    padding-top: 36px;
    overflow: hidden;
}
.insage-quote-author span{
    display: block;
}
.insage-quote-text h2 {
    max-width: 25ch;
    margin-left: auto;
    margin-right: auto;
}
.insage-quote-text{
    overflow: hidden;
    padding-bottom: 5px;
}
@media only screen and (max-width:1200px){
    .insage-quote{
        padding: 130px 0;
        padding-top: 100px;
    }
}
@media only screen and (max-width:991px){
    .insage-quote {
        padding: 80px 0;
        padding-top: 50px;
    }
}

@media only screen and (max-width:575px){
    .insage-quote-text h2{
        font-size: 30px;
    }
    .insage-quote {
        padding: 80px 0;
        padding-top: 50px;
    }
}