.container-900 {
    max-width: 930px;
    margin: 0 auto;
    padding: 0 15px;
}
.Community_post_wrapper {
    width: 100%;
}
.Community-tabs ul.react-tabs__tab-list {
    border: none;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0 !important;
    flex-wrap: wrap;
}
.Community-tabs .react-tabs__tab {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    border-radius: 4px;
    border: 1px solid #727272;
    cursor: pointer;
    transition: .2s ease all;
}
.Community-tabs .react-tabs__tab button{
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
    color: #222;
    transition: .2s ease all;
    font-family: var(--nunito);
}
.Community-tabs .react-tabs__tab button svg{
    width: 10px;
    height: 10px;
    margin-right: 5px;
}
.Community-tabs .react-tabs__tab.react-tabs__tab--selected button{
    color: #fff;
}
.Community-tabs .react-tabs__tab svg path {
    transition: .2s ease all;
}
.Community-tabs .react-tabs__tab.react-tabs__tab--selected {
    background: #24584B;
    border-color: #24584B;
    color: #fff;
}
.Community-tabs .react-tabs__tab.react-tabs__tab--selected svg path {
    stroke: #fff;
}
.Commmunity-tab-flex {
    display: flex;
    justify-content: space-between;
}
.Community_post_box {
    display: flex;
    gap: 16px;
    padding: 34px 0;
}
.communuity_post_box_author_img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
}
.communuity_post_box_author_img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.Community_post_box_content {
    width: calc(100% - 50px);
    margin-top: 10px;
}
.Community_post_box_wrapper {
    margin-top: 48px;
}
.Community_post_blog_info_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Community_post_blog_info {
    display: flex;
    align-items: center;
}
.Community_post_blog_info .cpbi-auth {
    display: flex;
    align-items: center;
}
.Community_post_author_name h6 {
    color: #222;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}
.Community_post_date p {
    color: #777;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.035px;
    position: relative;
    margin-left: 5px;
    padding-left: 10px;
}
.Community_post_date p:before{
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    background-color: #777;
    border-radius: 50%;
    left: 0;
    top: 7px;
}
.Community_post_category {
    color: #222;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    text-transform: capitalize;
    background-color: #FFE3C2;
    padding: 4px 12px;
    margin-left: 10px;
    border-radius: 30px;
}
.Commuity_popup_button span {
    margin-bottom: 8px;
    cursor: pointer;
}
.Community_post-content_data {
    margin-top: 22px;
}
.Community_post-content_data img{
    width: 100%;
    height: 100%;
}
.Community_post-content_data p {
    color: #444;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0.18px;
}
.show_more_div {
    color: #24584B;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
    margin-top: 10px;
    cursor: pointer;
    font-family: var(--switzer);
}
.Community_Main-Img {
    width: 100%;
    position: relative;
    height: 0;
    padding-top: 50%;
    overflow: hidden;
    margin-top: 33px;
    border-radius: 8px;
}
.Community_Main-Img img{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    object-fit: cover;
    top: 0;
}
.Community_Ppost-comment-flex {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Community_post_option {
    display: flex;
    gap: 22px;
    align-items: center;
}
.Community_post_option div {
    display: flex;
    gap: 8px;
    align-items: center;
    color: #666;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
    cursor: pointer;
    font-family: var(--switzer);
}
.Community_post_option div span {
    display: flex;
    align-items: center;
}
.Community_share-option {
    display: flex;
    gap: 26px;
    align-items: center;
}
.Community_post_box_wrapper .Community_post_box:first-child {
    padding-top: 0px;
}
.Community_post_box_wrapper .Community_post_box:last-child {
    padding-bottom: 0px;
    border: none;
}
section.Community_post_section {
    padding-bottom: 180px;
}
.Community-share-link{
    cursor: pointer;
    position: relative;
}
.Community-share-link .share-links{
    position: absolute;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.10);
    padding: 8px 10px;
    position: absolute;
    left: -70px;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    min-width: 162px;
    transition: .4s ease all;
}
.Community-share-link:hover .share-links{
    opacity: 1;
    visibility: visible;
    z-index: 2;
}
.Community-fav-link{
    cursor: pointer;
}
.Community-fav-link svg:nth-child(2){
    display: none;
}
.Community-fav-link.active svg:nth-child(2){
    display: block;
}
.Community-fav-link.active svg:nth-child(1){
    display: none;
}
/* Community Inner Section start */
section.Community_post_section_inner {
    background: #fff;
    padding: 80px 0;
}

.Trending_title.flex-title h1 {
    display: flex;
    align-items: center;
    gap: 5px;
}
/* .Community_post-inner_wrappper {
    width: 70%;
} */
.Community_post-inner_wrappper .Community_post_box{
    flex-direction: column;
}
.Community_post-inner_wrappper .Community_Ppost-comment-flex {
    margin-top: 29px;
    padding: 24px 0;
    border-bottom: 1px solid #E3E3E3;
    border-top: 1px solid #e3e3e3;
}
.Trending_title.flex-title {
    width: 60%;
    padding-bottom: 24px;
    border-bottom: 1px solid #e3e3e3;
}
.Community_comment_box form {
    padding: 8px 16px;
    display: flex;
    border-radius: 8px;
    border: 1px solid #F0F0F0;
    background: #F9F9F9;
    justify-content: space-between;
    align-items: center;
}

.Community_comment_box {
    padding: 32px 30px;
    display: flex;
    gap: 10px;
    background-color: #f9f9f9;
    margin: 20px 0;
    border-radius: 8px;
}
.Community_comment_box .emoji_btn {
    margin-top: -3px;
    display: none;
}
.Community-textarea {
    width: calc(100% - 100px);
    display: flex;
}
.Community-textarea textarea{
    width: 100%;
    border: none;
    outline: none;
    resize: none;
    background: transparent;
    padding: 4px 0;
}
textarea.Community-comment-textarea {
    background: transparent;
    border: none;
    width: 100%;
    outline: none;
    resize: none;
}
input.Comment_Submit {
    border-radius: 4px;
    background: #E4F6EA;
    border: none;
    color: #2A8751;
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    padding: 6px 21px;
}
.Commuity_popup_button {
    position: relative;
}
.threedotsMenu {
    position: absolute;
    top: 25px;
    right: 0;
    z-index: 1;
    pointer-events: none;
    opacity: 0;
    transition: .1s ease all;
}
.Commuity_popup_button:hover .threedotsMenu{
    opacity: 1;
    pointer-events: all;
}
.threedotsMenu .report-button {
    padding: 4px 12px;
    border-radius: 4px;
    background: #4E4E4E;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    text-transform: capitalize;
    color: #FFF;
    font-family: var(--switzer);
    position: relative;
    cursor: pointer;
}
.threedotsMenu .report-button:after {
    content: '';
    position: absolute;
    top: -3px;
    right: 2px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #4E4E4E;
}
.Community_comment-author-img{
    width: 45px;
    height: 45px;
    overflow: hidden;
    border-radius: 50%;
}
.Community_comment-author-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.Community_comment_author-info{
    display: flex;
    align-items: center;
    gap: 10px;
}
.Community_comment_author-info .authorname{
    color: #222;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
}
.Community_comment_author-info .Comment-date{
    color: #666;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%; /* 18.24px */
    letter-spacing: 0.24px;
}
.Community-comment_author-infor-box{
    display: flex;
    gap: 6px;
    align-items: start;
}
.author-Category{
    border-radius: 14px;
    background: #E4F6EA;
    color: #222;
    font-feature-settings: 'liga' off;
    font-family: var(--switzer);
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    padding: 0 10px;
}
.Commuity-top-comment-bar{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Community-comment-info-wrapper .Community_comment_info_box{
    border-top: 1px solid #E3E3E3;
    padding: 24px 0;
}
.Comment-Info{
    padding-top: 23px;
}
.Comment-Info p{
    color: #222;
    font-family: var(--nunito);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
}
.Community-tabs{
    display: flex;
}
/* Community Inner Section End */

@media(max-width:1200px){
    .Community_post-content_data p {
        font-size: 16px;
    }
    .Community_post_box_content {
        width: calc(100% - 100px);
    }
}
@media(max-width:991px){
    .Community_post_wrapper {
        width: 100%;
    }
    .Community_post-content_data p {
        font-size: 14px;
    }
    .Trending_title.flex-title{
        width: 100%;
    }
}
@media(max-width:767px){
    .Community_post_blog_info {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
    }
    .Community_post_category {
        margin-left: 0;
        margin-top: 4px;
    }
    .Community_post_box_content {
        margin-top: 0;
    }
    /* .Commmunity-tab-flex{
        overflow:scroll;
    } */
    section.Community_post_section{
        overflow: hidden;
    }
    .Community_Ppost-comment-flex{
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
    }
    section.Community_post_section {
        padding-bottom: 100px;
    }
    section.blog-create .background2 .back1, section.blog-create .background1 .back1, section.blog-create .background2 .back2, section.blog-create .background2 .back3, section.blog-create .background1 .back2, section.blog-create .background1 .back3 {
        opacity: 0.5;
    }
    .Community_post_box_content{
        width: calc(100% - 70px);
    }
    .Trending_title h1{
        font-size: 22px;
    }
    .Trending_title.flex-title span{
        display: flex;
    }
    .threedotsMenu{
        top: 9px;
    }
    .Commmunity-tab-flex{
        flex-direction: column;
        gap: 12px;
    }
    .Community_post_blog_info .cpbi-auth {
        flex-direction: column;
        gap: 6px;
        align-items: flex-start;
    }
    .Community_post_blog_info_flex {
        align-items: flex-start;
    }
}