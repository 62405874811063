.marquee-wrap {
  overflow: hidden;
  padding: 24px 0;
  position: relative;
}
.insage-marquee{
    transform: rotate(1.54deg);
    user-select: none;
    overflow: hidden;
    gap: 10px;
    z-index: 9;
}
.insage-marquee-wrapper{
    flex-shrink: 0;
    padding: 19px 0;
    gap: 10px;
    overflow: hidden;
    white-space: nowrap;
    animation: ticker-animation 40s linear infinite;
    display: inline-flex;
}
.insage-marquee-text{
    font-family: var(--switzer);
    font-weight: 700;
    font-size: 18px;
    line-height: 144%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
}
.insage-marquee-text-wrapper{
    gap: 10px;
    flex-shrink: 0;
    white-space: nowrap;
}
@keyframes ticker-animation {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 10px ));
  }
  }

@media only screen and (max-width:1300px){
  .insage-marquee-text{
    font-size: 16px;
  }
  .insage-marquee-wrapper{
    padding: 14px 0;
  }
}
@media only screen and (max-width:991px){
  .insage-marquee-text{
    font-size: 14px;
  }
  .insage-marquee-wrapper{
    padding: 10px 0;
  }
} 