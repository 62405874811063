.insage-explore-mentors{
    padding-top: 160px;
}
.insage-explore-mentors .insage-top-categories-label{
    background-color: #FF7252;
}
.mentors-cards-wrapper{
    gap: 24px;
    scroll-snap-type: x mandatory;
    overflow: hidden;
    overflow-x: auto;
}
.mentors-card-image{
    overflow: hidden;
    border-radius: 6px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: relative;
    padding-top: 85%;
}
.mentors-card-image-wrapper{
    position: relative;
}
.mentor-card-label{
    background: #FEC14B;
    border-radius: 4px;
    position: absolute;
    bottom: -10px;
    left: 11px;
}
.mentor-card-label span{
    padding: 6px 32px;
}
.mentors-card{
    flex-shrink: 0;
    scroll-snap-align: start;
}
.mentors-swiper .swiper-wrapper {
    align-items: stretch!important;
}
.swiper-autoheight .swiper-wrapper {
    height: auto!important;
}
.mentor-details{
    background: #FFFEFB;
    border-radius: 0px 0px 6px 6px;
    height: 100%;
}
.mentor-details-wrapper{
    padding: 27px 18px;
    height: calc(100% - 54px);
}
.mentor-name-designation-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.mentor-name-designation-wrapper .mentor-name {
    margin-bottom: 4px;
}
.designation{
    font-family: var(--switzer);
    font-weight: 700;
    font-size: 10px;
    line-height: 154%;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    font-feature-settings: 'liga' off;
    color: #222222;
}
.mentor-designation-separator{
    width: 20px;
    height: 1px;
    margin: 2px 0;
    background-color: #222222;
}
.mentor-designation-experience{
    gap: 11px;
    padding-top: 6px;
}
.mentor-separator {
    width: 37.1px;
    height: 2.85px;
    background: #FFCA63;
    margin-top: 20px;
    
}
.mentor-body{
    width: 95%;
    margin-top: 20px;
}
.mentor-body p{
    color:#555555;
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2;
   -webkit-box-orient: vertical;
}
.mentors-card-image img{
    object-position: top;
    position: absolute;
    inset: 0px;
}
.mentors-cards{
    padding-top: 62px;
}
.mentors-swiper{
    position: relative;
    padding-bottom: 100px;
}
.mentors-swiper .prev-btn{
    bottom: 0;
    left: 0;
    right: 80px;
    margin: auto;
}
.mentors-swiper .next-btn{
    bottom: 0;
    left: 80px;
    right: 0;
    margin: auto;
}
.insage-top-categories-heading{
    overflow: hidden;
}
.insage-top-categories-body{
    overflow: hidden;
}
.mentors-swiper .mentors-card {
    height: 100%;
}
.mentors-swiper .mentors-card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
/* .mentor-name-designation-wrapper */
@media(max-width: 1500px){
    .mentor-name-designation-wrapper .mentor-name h3 {
        font-size: 42px;
    }
}
@media(max-width:1200px) {
    .insage-explore-mentors {
        padding-top: 60px;
    }
}
@media only screen and (max-width:991px){
    .insage-explore-mentors{
        padding-top: 140px;
    }
}
@media only screen and (max-width:767px){
    .mentors-swiper .prev-btn{
        right: 70px;
    }
    .mentors-swiper .next-btn{
        left: 70px;
    }
}