.hiw-new-account{
    padding: 270px 0 170px 0;
}
.hiw-our-new-way{
    padding: 170px 0;
}
.hiw-our-new-way .insage-get-ahead-label{
    background-color: #24584B;
}
.hiw-we-are-on .insage-knowledge-at-work-wrapper{
    flex-direction: row-reverse;
}
.hiw-we-are-on .insage-get-ahead-label{
    right: 0;
    left: inherit;
    background-color: #FEC14B;
}
.hiw-we-are-on .insage-knowledge-at-work-left{
    padding-right: 105px;
    padding-left: 60px;
}
@media only screen and (max-width:1300px){
    .hiw-new-account{
        padding: 100px 0 80px 0;
    }
    .hiw-our-new-way {
        padding: 80px 0;
    }
}
@media only screen and (max-width:1200px){
    .hiw-new-account{
        padding: 0px 0 80px 0;
    }
    .hiw-our-new-way {
        padding: 80px 0;
    }
}
@media only screen and (max-width:991px){
    .hiw-we-are-on .insage-knowledge-at-work-wrapper{
        flex-direction: column;
    }
}
@media only screen and (max-width:767px){
    .banner-platform-left{
        padding-top: 40px;
    }
    .hiw-we-are-on .insage-knowledge-at-work-left{
        padding-left: 0;
        padding-right: 0;
    }
}