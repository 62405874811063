.contact-main{
    background-color: #FFFFFF;
}
.insage-contact-banner{
    background-color: #FEFBF4;
    padding-top: 200px;
    position: relative;
}
.insage-contact-banner::after{
    content: "";
    position: absolute;
    height: 100px;
    width: 100%;
    z-index: 1;
    background-color: #FFFFFF;
    bottom: 0;
}
.insage-contact-banner-wrapper{
    position: relative;
    z-index: 2;
}
.contact-image-1{
    border-radius: 99px;
    background-color: #FFB7A6;
    width: 275px;
    height: 112px;
    overflow: hidden;
}
.contact-image-1 img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.contact-image{
    width: 115px;
    height: 115px;
    overflow: hidden;
    border-radius: 50%;
}
.contact-image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.contact-top-images .contact-image:nth-child(1){
 background-color: #EAEAB0;
}
.contact-top-images .contact-image:nth-child(2){
    background-color: #FCCA6A;
}
.contact-top-images .contact-image:nth-child(3){
    background-color: #F3EBD8;
}
.contact-top-images, .contact-bottom-images{
    gap: 42px;
}
.contact-bottom-images{
    padding-top: 32px;
}
.contact-bottom-images .contact-image:nth-child(1){
    background-color:#24584C;
   }
   .contact-bottom-images .contact-image:nth-child(2){
       background-color:#FCCA6A;
   }
   .contact-bottom-images .contact-image:nth-child(3){
       background-color: #F3EBD8;
   }
   .contact-bottom-images .contact-image:nth-child(4){
    background-color: #FFB7A6;
}
.insage-contact-form{
    padding: 140px 0;
}
.insage-contact-form-wrapper{
    width: 90%;
    max-width: 1017px;
    margin: 0 auto;
}
.isg-con-form-wrapper{
    padding-top: 52px;
}
.isg-con-form-wrapper form div{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.isg-con-form-wrapper form label{
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0.01em;
    color: #222222;
}
.isg-con-form-wrapper form input, 
.isg-con-form-wrapper form select,
.isg-con-form-wrapper form textarea{
    background-color: #F9F9F9;
    border: 1px solid #F0F0F0;
    border-radius: 4px;
    padding: 16px 15px;
    outline: none;
    color: #999999;
    font-family: var(--nunito);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 144%;
    letter-spacing: 0.32px;
}
.isg-con-form-wrapper form textarea{
    resize: none;
    height: 140px;
}
.isg-con-form-wrapper form input#userMessage{
    padding-bottom: 140px;
}
.isg-con-form-wrapper form div:not(:first-child){
    padding-top: 24px;
}
.isg-con-form-wrapper form button{
    margin-top: 30px;
}
.isg-con-left-wrapper{
    padding-right: 58px;
}
.isg-con-right-wrapper{
    padding-left: 58px;
}
.isg-con-from-left{
    border-right: 1px dashed #E3E3E3;
    width: 60%;
}
.isg-con-from-right{
    width: 45%;
}
.isg-con-right-top{
    border-bottom: 1px dashed #D2D2D2;
}
.isg-con-right-top-wrapper{
    padding-bottom: 48px;
}
.isg-con-right-middle-wrapper{
    padding: 48px 0;
}
.isg-con-right-bottom-wrapper{
    padding-top: 48px;
}
.isg-con-right-top-head h6{
    font-family: var(--switzer);
    font-weight: 600;
    font-size: 30px;
    line-height: 120%;
    text-transform: capitalize;
    font-feature-settings: 'ss08' on;
    color: #444444;
}
.isg-con-right-top-body{
    padding-top: 24px;
}
.isg-con-right-top-body p{
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0.01em;
    color: #333333;
}
.isg-con-right-middle{
    border-bottom: 1px dashed #D2D2D2;
}
.isg-con-right-middle-icons{
    padding-top: 40px;
}
.isg-con-right-middle-details-head{
    font-family: var(--switzer);
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    text-transform: capitalize;
    color: #777777
}
.isg-con-right-middle-details-body a{
    font-family: var(--switzer);
    font-weight: 600;
    font-size: 18px;
    line-height: 144%;
    letter-spacing: 0.02em;
    color: #555555;
    transition: .2s ease all;
}
.isg-con-right-middle-details-body a:hover{
    color: #222222;
}
.isg-con-right-middle-icons{
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 32px 0;
}
.isg-con-right-middle-icons > div{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.isg-con-right-bottom-icons{
    gap: 14px;
    padding-top: 40px;
}
.isg-con-right-middle-icon {
    height: 24px;
}
.isg-con-right-bottom-icon svg{
    transition: .4s ease all;
}
.isg-con-right-bottom-icon img {
    transition: .4s ease all;
    width: 52px;
}
.isg-con-right-bottom-icon:hover svg, .isg-con-right-bottom-icon:hover img{
    transform: scale(0.9);
}

@media only screen and (max-width:991px){
    .insage-contact-form-wrapper{
        flex-direction: column;
        gap: 58px;
    }
    .isg-con-from-left{
        width: 100%;
        border-bottom: 1px dashed #E3E3E3;
        border-right: none;
    }
    .isg-con-from-right{
        width: 100%;
    }
    .isg-con-left-wrapper{
        padding-right: 0px;
        padding-bottom: 58px;
    }
    .isg-con-right-wrapper{
        padding-left: 0px;
    }
    .contact-image-2{
        display: none;
    }
    .isg-con-right-top-body p{
        font-size: 16px;
    }
    .isg-con-right-top-head h6{
        font-size: 28px;
    }
    .insage-contact-banner::after{
        height: 50px;
    }
}

@media only screen and (max-width:767px){
    .contact-image-1{
        display: none;
    }
    .contact-image{
        width: 100%;
        height: 100%;
    }
    .insage-contact-banner{
        padding-top: 140px;
    }
    .insage-contact-form{
        padding: 120px 0;
    }
    .isg-con-form-wrapper form label{
        font-size: 16px;
    }
    .isg-con-right-top-body p{
        font-size: 14px;
    }
    .isg-con-right-top-head h6{
        font-size: 26px;
    }
    .isg-con-right-middle-details-head{
        font-size: 18px;
    }
    .isg-con-right-middle-details-body a{
        font-size: 16px;
    }
}