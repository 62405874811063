.mentor-transactions-tab {
    width: 90%;
}
.mentor-trans-wrap .mtw-headings {
    margin-bottom: 20px;
}
.mtw-headings .mtw-row {
    display: grid;
    grid-template-columns: 1fr 1.5fr 2fr 2fr 2fr;
    gap: 10px;
}
.mtw-row .mtw-colum {
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%;
    letter-spacing: 0.28px;
    color: #333;
}
.mentor-trans-wrap {
    
}
.mtw-data .transaction-card {
    border: 1px solid transparent;
    transition: .2s ease all;
}
.mtw-data .transaction-card:nth-child(odd) {
    background-color: #F9F9F9;
}
.transaction-card-in .transaction-card-header {
    padding: 12px 14px;
}
.transaction-card.active {
    border-color: #F2F2F2;
}
.transaction-card-header .tch-in {
    display: grid;
    grid-template-columns: .5fr 2fr 2fr 2fr 2fr;
    gap: 10px;
    position: relative;
    align-items: center;
}
.transaction-card-header .tch-in .tch-column:last-child {
    position: absolute;
    right: 0;
    top: 5px;
    cursor: pointer;
}
.tch-column .tchc-data {
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%;
    letter-spacing: 0.28px;
    color: #333;
}
.tch-column .tchc-data span {
    display: flex;
    transition: .2s ease all;
    transform: rotate(-90deg);
}
.tch-column .tchc-data .mentee-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
}
.tch-column .tchc-data .mentee-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.tch-column .tchc-data .mentee-name {
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%;
    letter-spacing: 0.28px;
}
.transaction-card .transaction-card-expand {
    overflow-y: hidden;
    max-height: 0;
    transition: max-height 0.4s ease;
    border-top: 1px solid transparent;
}
.transaction-card.active .transaction-card-expand {
    max-height: 1000px;
    transition: .4s ease all;
    border-color: #E3E3E3;
}
.transaction-card.active .tch-column .tchc-data span {
    transform: rotate(0);
}
.tchc-data .tstatus {
    font-family: var(--switzer);
    padding: 4px 12px;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: uppercase;
}
.tchc-data .tstatus.green {
    background-color: #E4F6EA;
    color: #2A8751;
}
.tchc-data .tstatus.blue {
    background-color: #222222;
    color: #fff;
}
.tchc-data .tstatus.red {
    background-color: #F8E0DD;
    color: #F45E5C;
}
.tchc-data .tstatus.yellow {
    background-color: #FAF0C9;
    color: #333;
}
.tcein-wrap {
    display: grid;
    grid-template-columns: 5fr 2fr;
    width: 80%;
    margin: 0 auto;
    padding: 20px 0;
    gap: 40px;
}
.tcicolm-dates .sgcmt-lower {
    margin-top: 0;
    padding-top: 0;
}
.tcicolm-dates .sgcmt-lower::before {
    content: none;
}
.sgd-text {
    display: flex;
    align-items: center;
    gap: 5px;
}
.tciwrap-colm.right-colm {
    position: relative;
}
.tciwrap-colm.right-colm::before {
    content: '';
    position: absolute;
    left: -30px;
    width: 1px;
    height: 100%;
    top: 0;
    background-color: #E3E3E3;
}
.tciwrap-colm .tci-disc-topic {
    margin-top: 18px;
}
.tci-disc-topic .tci-disc-topic-in {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.tci-disc-topic-in h6 {
    color: #24584B;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%; /* 18.24px */
    letter-spacing: 0.24px;
}
.tci-disc-topic-in p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 152%; /* 18.24px */
    letter-spacing: 0.24px;
    color: #777;
}
.sess-cuts .sess-note {
    border-radius: 4px;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 3px 4px 0px #F6F6F6;
    background: #FFF;
    padding: 10px 12px;
}
.sess-note .sessnote-in {
    display: flex;
    align-items: center;
    gap: 12px;
}
.sessnote-in p {
    color: #777;
    font-family: var(--switzer);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 168%;
    letter-spacing: 0.2px;
}
.sessnote-in p b {
    color: #222;
    font-weight: 400;
}
.sesscut-details {
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin-top: 20px;
}
.sesscut-details .sesscut-details-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.035px;
    color: #777;
}
.sesscut-details-list.final {
    color: #222;
    position: relative;
    margin-top: 10px;
}
.sesscut-details-list.final::before {
    content: '';
    position: absolute;
    top: -10px;
    width: 100%;
    height: 1px;
    background-color: #E3E3E3;
}
@media(max-width:767px){
    .mentor-transactions-tab {
        overflow: hidden;
        overflow-x: auto;
    }
    .mentor-transactions-tab .mentor-trans-wrap {
        width: 700px;
    }
    .mentor-transactions-tab {
        width: 100%;
    }
}