.resources-card {
    padding: 18px 22px;
    border-radius: 8px;
    background: #F8F8F8;
    position: relative;
}
.resources-card .resource-card-tag {
    position: absolute;
    right: 0;
    top: 8px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 4px 13px;
    text-transform: uppercase;
    font-family: var(--switzer);
    letter-spacing: 1px;
    color: #333333;
    font-size: 10px;
    font-weight: 700;
    z-index: 2;
}
.resource-card-tag.published {
    background-color: #E4F6EA;
}
.resource-card-tag.drafts {
    background-color: #FFE3C2;
}
.resource-card-tag.pending {
    background-color: #FAF0C9;
}
.resource-card-tag.rejected {
    background-color: #FFBFB6;
}
.resources-card-in {
    display: flex;
    gap: 20px;
}
.resources-card-in .rci-image {
    width: 120px;
    background-color: green;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    aspect-ratio: 2/3;
}
.resources-card-in .rci-image::before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,236,162,.6);
    opacity: 0;
    transition: .2s ease all;
    z-index: 1;
}
.resources-card:hover .resources-card-in .rci-image::before {
    opacity: 1;
}
.resources-card-in .rci-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.resources-card-in .rci-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    width: calc(100% - 140px);
}
.resources-card-in .rci-content.no-image {
    width: 100%;
}
.rci-content .rcic-head {
    display: flex;
    flex-direction: column;
}
.rcich-top {
    display: flex;
    align-items: center;
}
.rcich-top .author-details {
    display: flex;
    align-items: center;
    gap: 6px;
}
.author-details .author-pic {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
}
.author-pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    position: absolute;
    top: 0;
    left: 0;
}
.author-details .author-name {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 152%;
    letter-spacing: 0.24px;
    color: #222;
}
.rcich-top .post-date {
    color: #888;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 152%;
    letter-spacing: 0.24px;
    position: relative;
    padding-left: 5px;
    margin-left: 5px;
}
.rcich-top .post-date::before {
    content: '';
    position: absolute;
    top: 7px;
    left: -2px;
    width: 2px;
    height: 2px;
    background-color: #888;
}
.rcich-bottom {
    margin-top: 10px;
}
.rcich-bottom .rcic-blog-heading {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* text-transform: capitalize; */
    color: #222;
    font-family: var(--switzer);
    transition: .2s ease all;
}
.resources-card:hover .rcich-bottom .rcic-blog-heading {
    color: #24584B;
}
.rcich-bottom .rcic-blog-para {
    color: #777;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 152%;
    letter-spacing: 0.24px;
    margin-top: 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.rcic-blog-para h1,
.rcic-blog-para h2,
.rcic-blog-para h3,
.rcic-blog-para h4,
.rcic-blog-para h5,
.rcic-blog-para h6 {
    display: none;
}
.rcic-foot {
    margin-top: 5px;
    padding-top: 20px;
    border-top: 1px solid #DBDBDB;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.rcic-foot .rcicf-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 14px;
}
.rcicf-left .category-tag {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}
.category-tag .ctag {
    padding: 2px 14px;
    border-radius: 99px;
    background: #FFE3C2;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    text-transform: capitalize;
    font-family: var(--switzer);
}
.rcicf-left .read-time {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 152%; /* 18.24px */
    letter-spacing: 0.24px;
    color: #666;
}
.rcic-foot .rcicf-right {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}
button.deleteicon-button {
    width: 22px;
    height: 22px;
    border-radius: 4px;
    background: #E7E7E7;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s ease all;
}
button.deleteicon-button:hover {
    background-color: #F8E0DD;
}
button.deleteicon-button svg path {
    transition: .2s ease all;
}
button.deleteicon-button:hover svg path {
    stroke:#F45E5C;
}
button.deleteicon-button.edit:hover {
    background-color: #E4F6EA;
}
button.deleteicon-button.edit:hover svg path {
    stroke:#2A8751;
}
.rcicf-right .primary-btn {
    padding: 3px 20px;
    font-size: 12px;
}

@media(max-width:767px){
    .resources-card-in {
        flex-direction: column;
    }
    .resources-card-in .rci-image {
        width: 100%;
    }
    .resources-card {
        padding: 15px 14px;
    }
    .resources-card-in .rci-content {
        width: 100%;
    }
    .rcic-foot {
        flex-direction: column;
        align-items: flex-start;
        gap: 14px;
    }
}
@media(max-width:400px){
    .rcic-foot {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }
}