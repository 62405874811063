.insage-faqs{
    padding: 200px 0;
}
.faqs-questions{
    padding-top: 50px;
}
.insage-faqs .answer-heading{
    font-family: var(--switzer);
    font-weight: 600;
    font-size: 24px;
    line-height: 96%;
    font-feature-settings: 'ss08' on;
    color: #333333;
}
.accordion-list li.active .answer-heading{
    color: #FFFFFF;
}
.insage-faqs .answer{
    width: 60%;
    display: grid;
    grid-template-rows: 0fr;
    opacity: 0;
    --webkit-transition: all .4s;
    transition: all .4s;
}
.insage-faqs .answer div{
    overflow: hidden;
}
.insage-faqs .answer p{
    color: #FCFCFC;
    padding-top: 22px;
}
.accordion-list li.active .answer{
   opacity: 1; 
   grid-template-rows: 1fr;
}
.accordion-list{
    gap: 18px;
}
.accordion-list li{
    padding: 32px;
    background: #F9F3E7;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    transition:.4s ease all;
}
.accordion-list li.active{
    background:#24584C;
}
.insage-faqs .answer-arrow{
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.4px solid #222222;
    border-radius: 50%;
    margin-left: 20px;
}
.insage-faqs li.active .answer-arrow{
    border: 1.4px solid #FFFFFF;
}
.faq-minus{
    opacity: 0;
    width: 0;
    transition: .4s ease all;
}
.accordion-list li.active .faq-minus{
    opacity: 1;
    width: auto;
}
.faq-plus{
    opacity: 1;
    width: auto;
    transition: .4s ease all;
}
.accordion-list li.active .faq-plus{
    opacity: 0;
    width: 0;
}
.faq-bg{
    position: absolute;
    top: 0;
    right: 15%;
    opacity: 0;
    transition: .4s ease all;
}
.faq-bg svg{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.faqs-heading{
    overflow: hidden;
    padding-bottom: 6px;
}
@media only screen and (max-width: 1300px){
    .insage-faqs .answer-heading{
        font-size: 22px;
    }
}
@media only screen and (max-width: 1200px){
    .insage-faqs .answer-heading{
        font-size: 20px;
    }
}

@media only screen and (max-width:991px){
    .insage-faqs .answer-heading{
        font-size: 18px;
    }
    .insage-faqs .answer{
        width: 90%;
    }
    .insage-faqs{
        padding: 150px 0;
    }
}

@media only screen and (max-width:767px){
    .insage-faqs .answer-heading{
        font-size: 16px;
    }
    .faq-plus{
        width: 15px;
    }
    .accordion-list li.active .faq-minus{
        width: 15px;
    }
    .accordion-list li{
        padding: 20px;
    }
    .insage-faqs .answer-arrow{
        width: 20px;
        height: 20px;
    }
    .insage-faqs .answer{
        width: 100%;
    }
    .insage-faqs{
        padding: 120px 0;
    }
}