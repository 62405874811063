.login-head span{
    font-family: var(--switzer);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-transform: capitalize;
}
.login-form-input label{
    color: #555;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%;
    letter-spacing: 0.28px;
    display: flex;
    align-items: center;
    gap: 5px;
}
.login-form-input label span {
    display: flex;
    align-items: center;
}
.login-form-input{
    gap: 10px;
}
.login-form-input:not(:first-child){
    padding-top: 24px;
}
.login-form-input input, .login-form-input select{
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    background-color: #F9F9F9;
    color: #777;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 152%;
    letter-spacing: 0.28px;
    padding: 13px 15px;
    width: 100%;
    box-sizing:border-box;
}
.login-password-wrapper input{
    color: #222222;
}
.login-password-wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.login-password-wrapper svg{
   position: absolute;
   right: 15px;
   top: 17px;
   cursor: pointer;
}
.password-eye{
    transition: .1s ease all;
    visibility: visible;
    opacity: 1;
}
.password-eye.show{
    opacity: 0;
    visibility: hidden;
}
.password-eye-slash{
    transition: .1s ease all;
    visibility: hidden;
    opacity: 0;
}
.password-eye-slash.show{
    visibility: visible;
    opacity: 1;
}
.login-password-wrapper svg::after{
    content: "";
    width: 100%;
    height: 1px;
    background-color: #222;
}
.login-socials-wrapper {
    gap: 15px;
}
.login-socials-wrapper button {
    background: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0px 4px 7px 0px #EFEFEF;
}
.login-socials-wrapper img{
    transition: .4s ease all;
    width: 40px;
}
.login-socials-wrapper a:hover img{
   transform: scale(1.1);
}
.insage-login-wrapper{
    padding: 42px 36px;
}
.insage-login-wrapper ul li {
    margin-top: 10px;
    color: #FF4F4F;
}
.insage-login{
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.10);
    position: relative;
    width: 432px;
    margin: 0 auto;
    opacity: 0;
    transform: translateY(200px);
    transition: .4s ease all;
    overflow: hidden;
    overflow-y: auto;
    max-height: 700px;
}

.login-close-button{
    position: absolute;
    top: 1em;
    right: 1em;
    cursor: pointer;
}
.login-close-button svg{
    transition: .4s ease all;
}
.login-close-button:hover svg{
    transform: rotate(90deg);
}
.login-button{
    width: 100%;
    margin-top: 32px;
    justify-content: center;
}
.forgot-password-btn .fg-pass{
    color: #797979;
    font-family: var(--switzer);
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 144%;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    border-bottom: 1px solid #797979;
    cursor: pointer;
}
.forgot-password-btn span{
    color: #999;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 152%;
    letter-spacing: 0.24px;
    display: block;
}
.forgot-password-btn svg{
    width: 6%;
    height: 100%;
    position: relative;
    top: 2px;
}
.forgot-password-p p{
    color: #555;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}
.forgot-password-p{
    padding-top: 12px;
}
.forgot-password-btn div{
    gap: 6px;
}
.login-form-wrapper{
    padding-top: 32px;
}
.login-with-text{
    padding: 40px 0;
}
.login-with-text span{
    color: #777;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 152%;
    letter-spacing: 0.24px;
}
.login-already-have-text span{
    color: #888;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    text-transform: capitalize;
}
.login-already-have-btn span{
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    color: #222;
    padding-bottom: 3px;
    border-bottom: 1px solid #222;
    display: inline;
    cursor: pointer;
}
.login-already-have{
    gap: 8px;
    padding-top: 48px;
}
.login-sign-up-pop-up{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: .4s ease all;
    background: rgba(255, 255, 255, 0.75);
}
.login-sign-up-pop-up.active{
    z-index: 999999999999999;
    visibility: visible;
    opacity: 1;
}
.login-sign-up-pop-up.active .insage-login{
    opacity: 1;
    transform: translateY(0px);
}
span.error-message{
    color: #FF4F4F;
    font-family:var(--nunito);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 152%;
    letter-spacing: 0.24px;
}
label.label-input-radio{
    display: flex;
    align-items: center;
    gap: 4px;
    color: #222;
    font-family: var(--nunito);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}
@media only screen and (max-width:767px){
    .login-head span{
        font-size: 26px;
    }
    .insage-login-wrapper {
        padding: 40px 20px;
    }
}
@media only screen and (max-width:432px){
    .insage-login{
        width: 95%;
    }
}