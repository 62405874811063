.insage-guide-inner-wrapper{
    padding-top: 51px;
    padding-left: 23px;
}
.insage-guide-inner{
    padding-bottom: 170px;
}
.insage-guide-inner-label span{
    font-family:var(--switzer);
    font-weight: 700;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    font-feature-settings: 'liga' off;
    color: #A7A7A7;
}
.insage-guide-inner-title{
    padding-top: 33px;
    max-width: 80ch;
}
.insage-guide-inner-title h1, .insage-guide-similar-guides-heading h1{
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    font-feature-settings: 'ss08' on;
    color: #222222;
    text-transform: none;
}
.insage-guide-inner-img{
    position: relative;
    padding-top: 35%;
    border-radius: 8px 0px 0px 8px;
    overflow: hidden;
    margin-top: 83px;
    min-width: 980px;
    width: 75%;
    margin-left: auto;
}
.insage-guide-inner-img img{
    position: absolute;
    inset: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.insage-guide-inner-content-wrapper{
    width: 50%;
    min-width: 617px;

}
.insage-guide-inner-content-wrapper p{
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0.01em;
    color: #222222;
}
.insage-guide-inner-content-wrapper ol{
    list-style-type: decimal;
}
.insage-guide-inner-content-wrapper li{
    font-size: 20px;
    font-weight: 600;
    color: #222222;
    margin-top: 30px;
    margin-left: 30px;
}
.insage-guide-inner-content-wrapper p:not(:first-child){
    margin-top: 30px;
}
.insage-guide-inner-content-wrapper ol p, .insage-guide-inner-content-wrapper ul p{
    font-size: 16px;
    margin-top: 15px !important;
    margin-left: 30px;
}
.insage-guide-inner-content{
    padding-top: 83px;
}
.insage-guide-inner-content-wrapper h2{
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: #222222;
    margin-top: 60px;
    text-align: left;
}
.insage-guide-inner-content-img{
    position: relative;
    padding-top: 55%;
    border-radius: 8px;
    overflow: hidden;
    margin: 50px 0px 20px 0;
}
.insage-guide-inner-content-img img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    inset: 0px;
}
.insage-guide-inner-btn{
    text-align: center;
    width: 50%;
    min-width: 617px;
    padding-top: 90px;
}
.insage-guide-similar-guides{
    padding-top: 140px;
}
.insage-guide-similar-guides-grid{
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 41px;
    grid-row-gap: 41px;
    min-width: 980px;
    width: 75%;
    margin-top: 50px;
}

@media only screen and (max-width:1200px){
    .insage-guide-similar-guides-grid{
        min-width: 95%;
    }
    .insage-guide-inner-img{
        min-width: 95%;
    }
}

@media only screen and (max-width:991px){
    .insage-guide-similar-guides-grid{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .insage-guide-inner-content-wrapper p{
        font-size: 16px;
    }
    .insage-guide-inner-title h1, .insage-guide-similar-guides-heading h1{
        font-size: 34px;
    }
    .insage-guide-inner-content-wrapper ol p, .insage-guide-inner-content-wrapper ul p{
        font-size: 14px;
    }
}
@media only screen and (max-width:767px){
    .insage-guide-similar-guides-grid{
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .insage-guide-inner-content-wrapper p{
        font-size: 14px;
    }
    .insage-guide-inner-content-wrapper{
        min-width: 95%;
    }
    .insage-guide-inner-btn{
        min-width: 95%;
    }
    .insage-guide-inner-title h1, .insage-guide-similar-guides-heading h1{
        font-size: 26px;
    }
    .insage-guide-inner-img{
        min-width: 100%;
    }
    .insage-guide-inner-img{
        margin-top: 43px;
        padding-top: 65%;
    }
    .insage-guide-inner-content{
        padding-top: 43px;
    }
    .insage-guide-similar-guides{
        padding-top: 100px;
    }
    .insage-guide-inner-content-wrapper ol p, .insage-guide-inner-content-wrapper ul p{
        font-size: 12px;
    }
    .insage-guide-inner-content-wrapper li{
        font-size: 18px;
    }
}