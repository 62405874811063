.knowledge-insage{
    background-color: #FF7252;
    position: relative;
}
.knowledge-insage .container{
    position: relative;
    z-index: 1;
}
.knowledge-insage::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 230px;
    background-color: #FEFBF4;
    top: 0;
}
.knowledge-insage::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 66px;
    background-color: #FEFBF4;
    bottom:0;
}
.knowledge-insage-image{
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    height: 0;
    padding-top: 55%;
}
.knowledge-insage-image img{
    position: absolute;
    inset: 0px;
}
.knowledge-insage-arrow{
    padding-top: 50px;
}
.knowledge-insage-label{
    padding-top: 41px;
    overflow: hidden;
}
.knowledge-insage-label span{
    display: block;
}
.knowledge-insage-body{
    padding-top: 13px;
    overflow: hidden;
}
.knowledge-insage-btn{
    padding-top: 40px;
}
.knowledge-insage-content-wrapper{
    gap:66px;
}
.knowledge-insage-image{
    width: 50%;
}
.knowledge-insage-content-body{
    width: 50%;
}
.knowledge-insage-content-wrapper{
    margin-top: 128px;
}
.knowledge-insage-content-heading{
    overflow: hidden;
    padding-bottom: 5px;
}
@media only screen and (max-width: 1300px){
   
}
@media only screen and (max-width: 1200px){
    .knowledge-insage-image{
        padding-top: 70%;
    }
    .knowledge-insage-image{
        width: 60%;
    }
}

@media only screen and (max-width:1100px){
    .knowledge-insage-image{
        padding-top: 70%;
    }
}
@media only screen and (max-width:991px){
    .knowledge-insage-arrow{
        padding-top: 20px;
    }
    .knowledge-insage-arrow svg{
        height: 30px;
    }
    .knowledge-insage-label{
        padding-top: 25px;
    }
    .knowledge-insage-content-wrapper{
        flex-direction: column;
        margin-top: 100px;
    }
    .knowledge-insage-image{
        width: 65%;
    }
    .knowledge-insage-content-body{
        width: 65%;
    }
    .knowledge-insage::after{
        display: none;
    }
    .knowledge-insage {
        padding-bottom: 60px;
    }
}

@media only screen and (max-width:767px){
    .knowledge-insage-image{
        width: 100%;
    }
    .knowledge-insage-content-body{
        width: 100%;
    }
    .knowledge-insage-image{
        padding-top: 100%;
    }
}