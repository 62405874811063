section.Trending_post_section {
    padding-top: 40px;
}
.container-900 {
    max-width: 930px;
    margin: 0 auto;
    padding: 0 15px;
}
.Trending_title h1{
    font-size: 30px;
    color: #222;
    text-transform: capitalize;
}
.Trending_post_wrapper {
    margin-top: 45px;
}
.Trending_post_grid {
    display: grid;
    grid-template-columns: repeat(3 , 1fr);
    gap: 16px;
}
.Trending_post_box {
    position: relative;
}
.Trending_post_img {
    position: relative;
    height: 0;
    border-radius: 8px;
    overflow: hidden;
    padding-top: 100%;
}
.Trending_post_img::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 21.88%, #000 100%);
}
.Trending_post_img img{ 
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0%;
    object-fit: cover;
}
.Trending_post_content {
    position: absolute;
    bottom: 20px;
    left: 11px;
    right: 11px;
}
.Trending_post-title h6{
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
    line-height: 120%; /* 24px */
    letter-spacing: 0.4px
}
.Trending_post_para{
    margin-top: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
.Trending_post_para p{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%;   
    color: #FCFCFC;
}
.createBlogWrap {
    margin: 40px 0;
    padding: 40px 0;
    border-top: 1px solid #E3E3E3;
}

@media(max-width:1200px){
    .Trending_post_grid {
        grid-template-columns: repeat(2 , 1fr);
    }
    
}
@media(max-width:767px){
    .Trending_post_grid {
        grid-template-columns: repeat(1 , 1fr);
    }
}
