.error-template-heading h1{
    font-family:var(--switzer);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
}
.error-template-heading{
    padding-top: 40px;
}
.error-template-body{
    padding-top: 32px;
    max-width: 50ch;
}
.error-template-body p{
    color: #444;
    font-weight: 400;
    line-height: 144%;
    letter-spacing: 0.04px;
}
.error-template{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.error-template-button{
    padding-top: 24px;
}
.error-template-wrapper{
    padding: 0 20px;
}