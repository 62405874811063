.home-banner{
    background: #FEC14B;
    position: relative;
    margin-bottom: 80px;
}
.home-banner-wrap {
    position: relative;
}
.home-banner-heading h1{
    line-height: 0.75;
    color: #FFFFFF;
    margin-left: -10px;
}
.home-banner-body p{
    text-align: right;
    max-width: 45ch;
    overflow: hidden;
}
.home-banner-heading{
    width: 72.4ch;
}
.home-banner-body{
    margin-bottom: 200px;
    overflow: hidden;
}
.home-banner-wrapper{
    position: relative;
}
.homebanner-img{
    border-radius: 8px;
    position: absolute;
    overflow: hidden;
    bottom: -180px;
    right: 0px;
    width: 56%;
}
.homebanner-img img{
    min-height: 540px;
    border-radius: 8px;
    overflow: hidden;
}
.home-banner-heading img{
    margin-top: 5px;
}
.home-banner .marquee-wrap {
    bottom: -50px;
}

@media only screen and (max-width:1400px){
    .homebanner-img{
        bottom: -95px;
        width: 51%;
    }
    .homebanner-img img{
        min-height: 430px;
    }
}
@media only screen and (max-width:1300px){
    .home-banner-heading{
        width: 64.5ch;
    }
    .homebanner-img{
        bottom: -95px;
        width: 52%;
    }
    .home-banner .insage-marquee{
        bottom: -30px !important;
    }
}
@media only screen and (max-width:1200px){
    .home-banner-heading{
        width: 56.4ch;
    }
    .homebanner-img{
        bottom: -140px;
        width: 56%;
    }
}
@media only screen and (max-width:1100px){
    .home-banner-heading{
        width:47.9ch;
    }
    .homebanner-img{
        bottom: -120px;
    }
    .homebanner-img img{
        min-height: 370px;
    }
}

@media only screen and (max-width:991px){
    .home-banner-heading{
        width:35.6ch;
    }
    .home-banner-body{
        margin-bottom: 150px;
    }
    .homebanner-img{
        bottom: -100px;
    }
    .home-banner .insage-marquee{
        bottom: -20px !important;
    }
    .home-banner{
        margin-bottom: 100px;
    }
    .homebanner-img img{
        min-height: 300px;
    }
}
@media only screen and (max-width:767px){
    .home-banner-content{
        flex-direction: column;
        align-items: start;
        gap: 20px;
    }
    .home-banner-heading{
        width: 100%;
    }
    .home-banner-heading img{
        display: none;
    }
    .home-banner-body p{
        text-align: left;
    }
    .homebanner-img{
       position: static;
       width: 100%;
    }
    .home-banner-body{
        margin-bottom: 30px;
    }
    .home-banner{
        margin-bottom: 50px;
    }
}
