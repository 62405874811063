.dash-emp-trm-slider.trmentors {
    margin-bottom: 80px;
}
.trmentors .dash-emp-trm-slider-left {
    width: 20%;
}
.trmentors .dash-emp-trm-slider-right {
    width: 80%;
}
.trmentors .dash-emp-trm-slider-right .swiper-slide {
    height: auto;
}
.trmentors .dash-emp-trm-slider-right .swiper-slide a {
    height: 100%;
    display: block;
}
.trmentors .dash-emp-trm-slider-right .swiper-slide .top-rated-mentor-card {
    height: 100%;
}
.trmentors .dash-emp-trm-slider-right .top-rated-mentor-card-content {
    height: calc(100% - 273px);
}
.trmentors .top-rated-mentor-card-name-price {
    flex-direction: row;
    align-items: flex-start;
}
.exploring .det-slider-prev {
    transform: rotate(180deg);
}
.exploring .det-slider-prev, .exploring .det-slider-next {
    box-shadow: none;
}
.exploring .dash-empty-sessions-content {
    margin-bottom: 30px;
}
@media(max-width:991px){
    .trmentors .dash-emp-trm-slider-left {
        width: 30%;
    }
    .trmentors .dash-emp-trm-slider-right {
        width: 70%;
    }
}
@media(max-width:767px){
    .trmentors .dash-emp-trm-slider-left {
        width: 100%;
    }
    .trmentors .dash-emp-trm-slider-right {
        width: 100%;
    }
}