.top-rated-mentor-card-image{
    aspect-ratio: 1/1;
    position: relative;
}
.top-rated-mentor-card-image > span {
    height: 100%;
}
.top-rated-mentor-card-image > span span {
    height: 100%!important;
}
.top-rated-mentor-card-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.top-rated-mentor-card{
    border-radius: 8px;
    overflow: hidden;
}
.trm-card-tag-wrapper{
    color: #222;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    text-transform: capitalize;
    padding: 4px 13px;
}
.trm-card-tag{
    border-radius: 4px;
    background-color: #FFDFCC;
    position: absolute;
    bottom: 12px;
    left: 12px;
}
.top-rated-mentor-card-content{
    background-color: #FEFBF4;
}
.top-rated-mentor-card-name, .top-rated-mentor-card-price{
    color: #222;
    font-family: var(--switzer);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}
.top-rated-mentor-card-content-wrap{
    padding: 18px 10px;
}
.top-rated-mentor-card-designation{
    gap: 6px;
}
.top-rated-mentor-card-designation-text{
    color: #333;
    font-family: var(--nunito);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    gap: 6px;
    letter-spacing: 0.12px;
}
.top-rated-mentor-card-designation-text span{
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #BABABA;
}
.top-rated-mentor-card-designation{
    padding-top: 16px;
}
.trm-card-exp {
    padding-top: 10px;
}