.session-list-card {
    border-radius: 8px;
    background: #F8F8F8;
    overflow: hidden;
}
.session-list-card-in {
    position: relative;
}
.slci-main {
    padding: 24px 60px 24px 36px;
}
.slci-main .slci-head.sh-withimage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
}
.slci-head.sh-withimage .slcihead-image {
    width: 57px;
    height: 57px;
    border-radius: 50%;
    overflow: hidden;
}
.slci-head.sh-withimage .slcihead-content {
    width: 100%;
}
.slcihead-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}
.slci-head .slcih-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.slcih-top .sl-top-name {
    font-family: var(--switzer);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%; /* 25.92px */
    letter-spacing: 0.36px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 6px;
}
.slcih-top .sl-top-name span {
    color: #24584C;
    font-weight: 600;
}
.slcih-top .details-btn {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    text-transform: capitalize;
    cursor: pointer;
}
.slcih-top .details-btn:hover {
    text-decoration: underline;
}
.slcih-top .details-btn span {
    margin-left: 5px;
    transition: .3s ease all;
}
.session-list-card-in.active .slcih-top .details-btn span {
    transform: rotate(0deg);
}
.slci-head .slcih-bottom {
    display: flex;
    align-items: center;
    gap: 35px;
    margin-top: 12px;
}
.slcih-bottom .slb-date, .slcih-bottom .slb-time {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.035px;
}
.slb-date span, .slb-time span {
    display: flex;
    align-items: center;
    margin-right: 4px;
}
.slb-time .timer {
    display: flex;
    align-items: center;
}
.slb-time .star-box {
    padding: 6px 11px;
    border-radius: 4px;
    background: #FAF0C9;
    color: #C39D00;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    font-family: var(--switzer);
    display: inline-block;
    margin-left: 10px;
}
.slb-time .star-box  svg {
    margin-right: 2px;
}
.timer .duration {
    padding-left: 10px;
    margin-left: 10px;
    position: relative;
}
.timer .duration::before {
    content: '';
    position: absolute;
    left: 0;
    top: 7px;
    width: 3px;
    height: 3px;
    background-color: #BABABA;
    border-radius: 50%;
}
.slci-foot {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #E3E3E3;
}
.slci-foot .foot-upcoming, .slci-foot .foot-completed {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.slci-foot .foot-ques {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
    color: #777;
}


.session-tag {
    position: absolute;
    top: 0;
    right: 0px;
    padding: 0 8px;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.session-tag.upcoming {
    background-color: #FAF0C9;
}
.session-tag.completed {
    background-color: #24584B;
}
.session-tag.cancelled {
    background-color: #F8E0DD;
}
.session-tag span {
    font-family: var(--switzer);
    color: #FFF;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 1.2px;
    text-transform: uppercase;
}
.session-tag.upcoming span, .session-tag.cancelled span {
    color: #222;
}
.foot-completed .foot-cancel {
    display: flex;
    align-items: center;
    gap: 15px;
}
button.cancel-text {
    color: #FF6565;
    font-family: var(--switzer);
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    border-bottom: 1px solid #FF6565;
}
.review-button {
    padding: 6px 20px;
    border-radius: 4px;
    background: #FAF0C9;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    font-family: var(--switzer);
    color: #C39D00;
    display: flex;
    align-items: center;
    gap: 4px;
}
.book-button {
    padding: 6px 20px;
    border-radius: 4px;
    background: #E4F6EA;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    font-family: var(--switzer);
    color: #2A8751;
    transition: .3s ease all;
}
.book-button:hover {
    background-color: #2A8751;
    color: #E4F6EA;
}
.review-button {
    padding: 6px 20px;
    border-radius: 4px;
    background: #FAF0C9;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    font-family: var(--switzer);
    color: #C39D00;
    transition: .3s ease all;
}
.review-button:hover {
    background-color: #C39D00;
    color: #FAF0C9;
}
.review-button svg path {
    transition: .3s ease all;
}
.review-button:hover svg path {
    stroke: #FAF0C9 ;
}
.slci-content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out , overflow 0.3s ease-out;
    opacity: 0;
}
.session-list-card-in.active .slci-content {
    max-height: 600px;
    transition: max-height 0.3s ease-in, opacity 0.3s ease-in, overflow 0.3s ease-in;
    overflow: initial;
    opacity: 1;
}
.slci-content.no-margin {
    margin-top: 0;
}
.slci-content .slci-content-in {
    padding: 20px 0;
    border-top: 1px solid #E3E3E3;
    margin-top: 20px;
}
.slci-content.no-margin .slci-content-in {
    margin-top: 0;
}
.slci-in-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.slci-in-top .slcitt-left {
    display: flex;
    align-items: center;
}
.slcitt-left .slcit-image {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    overflow: hidden;
}
.slcit-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.slcitt-left .slcit-right-part {
    margin-left: 20px;
}
.slcit-right-part .slcit-rp-name {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 144%; /* 25.92px */
    letter-spacing: 0.36px;
    color: #222;
    font-family: var(--switzer);
}
.slcit-right-part .slcit-rp-bdetails {
    display: flex;
    align-items: center;
    gap: 12px;
}
.slcit-rp-bdetails .desig {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #777;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.12px;
    text-transform: capitalize;
}
.slcit-rp-bdetails .company {
    position: relative;
    color: #777;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.12px;
    text-transform: capitalize;
}
.slcit-rp-bdetails .company::before {
    content: '';
    position: absolute;
    left: -8px;
    top: 7px;
    width: 3px;
    height: 3px;
    background-color: #BABABA;
    border-radius: 50%;
}
.slcitt-right .three-dots {
    position: relative;
}
.three-dots .threedots-content {
    position: absolute;
    bottom: -22px;
    right: -7px;
}
.threedots-content .report-button {
    padding: 4px 12px;
    border-radius: 4px;
    background: #4E4E4E;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    text-transform: capitalize;
    color: #FFF;
    font-family: var(--switzer);
    position: relative;
}
.threedots-content .report-button:after {
    content: '';
    position: absolute;
    top: -3px;
    right: 2px;
    width: 0; 
    height: 0; 
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #4E4E4E;
}
.slci-in-content {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.slci-in-content > div {
    border-top: 1px solid #F1F1F1;
    padding-top: 15px;
}
.slci-in-content > div:nth-child(1){
    border-top: none;
    padding-top: 0;
}
.slci-in-content h6.micro {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%; /* 18.24px */
    letter-spacing: 0.24px;
    color: #24584B;
    display: flex;
    align-items: center;
    gap: 5px;
}
.slci-in-content p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 152%; /* 18.24px */
    letter-spacing: 0.24px;
    color: #777;
    margin-top: 4px;
}
.review-stars {
    display: flex;
    align-items: center;
    gap: 1px;
}
.slciic-reviews .reviewBox {
    border-radius: 4px;
    background: #FFF;
    padding: 16px 20px;
    margin-top: 10px;
}
.re-in .rb-defined {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.rb-defined-in {
    text-align: center;
}
.rb-defined-in .rbd-tag {
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 144%; /* 14.4px */
    letter-spacing: 0.2px;
    text-transform: capitalize;
    color: #24584B;
    font-family: var(--switzer);
    margin-bottom: 2px;
}
.rbd-stars .review-stars {
    justify-content: center;
}
.re-in .rb-content p {
    margin-top: 20px;
}
.next-up {
    position: absolute;
    left: 0px;
    top: 10px;
    padding: 2px 10px;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    font-family: var(--switzer);
    color: #71A783;
    border-radius: 2px;
    background: #E4F6EA;
    z-index: 2;
}
.next-up.nextupmentor {
    left: auto;
    right: 10px;
    top: 30px;
}
.session-grid-card-outer {
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    position: relative;
    margin-top: 20px;
    height: calc(100% - 62px);
}
.session-grid-card-in {
    position: relative;
    height: 100%;
}
.session-grid-card-in .sgc-industry {
    position: absolute;
    right: -20px;
    top: -10px;
    padding: 4px 13px;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    font-family: var(--switzer);
    color: #B87F5F;
    border-radius: 4px 0px 0px 4px;
    background: #FFDFCC;
}
.sgc-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.sgcm-top .sgcmt-upper {

}
.sgcmt-mentor-image {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 4px;
}
.sgcmt-mentor-image.round {
    border-radius: 50%;
}
.sgcmt-mentor-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.sgcmt-upper .sgcmt-mentor-name {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    font-family: var(--switzer);
    margin-top: 10px;
}
.sgcmt-mentor-poscom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
    color: #777;
    margin-top: 5px;
    flex-wrap: wrap;
}
.sgcmt-mentor-poscom .sgd-icon {
    margin-right: 6px;
    display: flex;
}
.dot-seperator {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #777;
    margin: 0 5px;
}
.sgcmt-lower {
    margin-top: 15px;
    padding-top: 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.sgcmt-lower::before {
    content: '';
    position: absolute;
    top: 0px;
    height: 2px;
    width: 10px;
    background-color: #E3E3E3;
}
.sgcmt-lower > div {
    display: flex;
    align-items: center;
}
.sgcmt-lower .sgd-icon {
    margin-right: 6px;
    display: flex;
}
.sgd-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.035px;
    color: #666;
}
.detailsbtn .more-link {
    display: flex;
    align-items: center;
    margin-top: 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    text-transform: capitalize;
    font-family: var(--switzer);
    color: #666;
    gap: 4px;
    transition: .3s ease all;
    cursor: pointer;
    border-bottom: 1px solid #666;
}
.detailsbtn .more-link:hover {
    gap: 8px;
}
.sgc-main .sgcm-top {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.sgc-main .sgcm-foot {
    border-top: 1px solid #E3E3E3;
    margin-top: 20px;
    padding-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sgcm-foot .sgcmf-text {
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
    color: #999;
}
.dash-session-grid {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 13px;
}
.sgcm-foot .review-text {
    border-bottom: 1px solid #999;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    font-family: var(--switzer);
    transition: .3s ease all;
}
.review-text:hover {
    color: #2A8751;
    border-color: #2A8751;
}
.review-text svg path {
    transition: .3s ease all;
}
.review-text:hover svg path {
    stroke: #2A8751;
}
.dash-empty-upcoming-sessions .dash-ongoing-wrap {
    border-radius: 8px;
    background-color: #FEFBF4;
    padding: 24px 20px;
    margin-top: 10px;
}
.dash-ongoing-wrap .dash-sessions-data {
    margin-top: 20px;
}
.dash-ongoing-wrap .ongoing-report {
    padding-top: 20px;
}
.ongoing-report .or-report {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
}
.or-report .or-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
    color: #555;
}
.or-report .or-button {
    display: flex;
    align-items: center;
    gap: 10px;
}
.dash-sessions-data .swiper-slide {
    height: auto;
}
.session-ongoing-card-outer {
    border-radius: 8px;
    background: #FFF7E5;
    padding: 14px 18px;
}
.session-ongoing-card-outer .session-ongoing-card-in {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sogi-left .sogi-left-in .sli-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.sli-wrap .sliw-image {
    width: 79px;
    height: 79px;
    overflow: hidden;
    border-radius: 50%;
}
.sli-wrap .sliw-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}
.sli-wrap .sliw-content {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.sliw-content .sliw-name {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    color: #222;
    font-family: var(--switzer);
}
.sliw-content .sliw-position {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.sliw-position > div {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
    color: #777;
}
.sliw-position .sliwp-designation, .sliwp-designation span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
}
.sogi-right .sogi-right-in {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
}
.sogi-right-in .sli-dd {
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.sli-dd .slidd-heading {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.035px;
    color: #24584C;
}
.sli-dd .slidd-content {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
    text-transform: capitalize;
    color: #333;
    font-family: var(--switzer);
}
.rated-text{
    color: #777;
    font-family: var(--nunito);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
}
.rated-div{
    gap: 8px;
}
.rated-icon-number{
    padding: 6px 11px;
    border-radius: 4px;
    background: #FAF0C9;
}
.rated-number{
    color: #C39D00;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 152%;
    letter-spacing: 0.96px;
    text-transform: uppercase;
}
.rated-icon-number{
    gap: 3px;
}
@media (min-width: 992px) and (max-width: 1200px){
    .sgc-main .sgcm-foot {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
    }
}
@media(max-width:991px){
    .dash-session-grid {
        grid-template-columns: repeat(2,1fr);
    }
}
@media(max-width:767px){
    .slci-main {
        padding: 20px 20px;
    }
    .session-tag {
        position: relative;
        transform: rotate(0);
        writing-mode: horizontal-tb;
        padding: 8px 0;
    }
    .slci-head .slcih-top {
        flex-direction: column;
        align-items: flex-start;
    }
    .slcih-top .sl-top-name {
        display: block;
        width: 100%;
    }
    .slcih-top .sl-top-name span {
        display: inline;
    }
    .slcih-top .details-btn {
        margin-top: 10px;
    }
    .slci-head .slcih-bottom {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }
    .slci-foot .foot-upcoming, .slci-foot .foot-completed {
        display: block;
    }
    .foot-cancel {
        margin-top: 15px;
    }
    .dash-session-grid {
        grid-template-columns: repeat(1,1fr);
    }
    .re-in .rb-defined {
        grid-template-columns: 1fr 1fr;
        gap: 12px;
    }
}