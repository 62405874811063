.insage-guides-wrapper{
    padding: 51px 0px 173px 23px;
}
.insage-guides-heading h1{
    font-weight: 600;
    font-size: 30px;
    line-height: 120%;
    text-transform: capitalize;
    font-feature-settings: 'ss08' on;
    color: #222222;
}
.insage-guides-tabs-content{
    padding-top: 65px;
    min-width: 980px;
    width: 75%;
}
.react-tabs__tab-panel{
    animation: fadeEffect 1s;
}
.insage-guides .react-tabs__tab{
    font-family: var(--switzer);
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #888888;
    border: none ;
}
.insage-guides .react-tabs__tab--selected{
    background: transparent;
    border-radius: 0px;
    border: none;
    color: #24584B;
    border-bottom: 1px solid #24584B;
}
.insage-guides .react-tabs__tab-list{
    border-bottom: 1px solid #E3E3E3;
    display: flex;
    gap: 36px;
}
.insage-guides .react-tabs__tab{
    padding: 9px 0;
}
.insage-guides .react-tabs__tab:focus:after{
    background: transparent;
}
.insage-guides-tab-guides-wrapper{
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 41px;
    grid-row-gap: 96px;
}
.insage-guides-tabs{
    padding-top: 32px;
}
.insage-guides-tab-buttons{
    width: 90%;
}
.insage-guides-tab-load-more-btn{
    padding-top: 40px;
    text-align: center;
}

@keyframes fadeEffect {
    from {opacity: 0;}
    to {opacity: 1;}
  }

@media only screen and (max-width:1200px) {
    .insage-guides-tabs-content{
        min-width: 95%;
    }
} 
@media only screen and (max-width:991px) {
    .insage-guides-tab-guides-wrapper{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}
@media only screen and (max-width:767px) {
    .insage-guides-tab-guides-wrapper{
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}
