.usecases-quality-mentors{
    padding-top: 200px;
    overflow: hidden;
}
.quality-mentors-body p{
    text-align: center;
}
.quality-mentors-body{
    width: 49%;
    margin: 0 auto;
    overflow: hidden;
    padding-top: 20px;
}
.quality-mentors-mentor-image{
    width: 77px;
    height: 77px;
    overflow: hidden;
    border-radius: 50%;
}
.quality-mentors-mentor-designation span{
    font-family: var(--switzer);
    font-weight: 700;
    font-size: 10px;
    line-height: 154%;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    font-feature-settings: 'liga' off;
    color: #777777;
}
.quality-mentors-mentor-category-label{
    font-family: var(--switzer);
    font-weight: 700;
    font-size: 12px;
    line-height: 152%;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #444444;
}
.quality-mentors-top-ticker-box{
    background: #FFF7E6;
    border-radius: 8px;
    transition: .2s ease all;
}
.quality-mentors-top-ticker-box:hover{
    background: #EEE6D6;
}
.quality-mentors-top-ticker-box-wrapper{
    padding: 20px 30px;
    gap: 20px;
}
.quality-mentors-mentor-category{
    gap: 4px;
    padding-top: 14px;
}
.quality-mentors-top-ticker-wrapper{
    flex-shrink: 0;
    gap: 14px;
    overflow: hidden;
    white-space: nowrap;
    animation: ticker-animation 45s linear infinite;
    display: inline-flex;
}
.quality-mentors-top-ticker:hover .quality-mentors-top-ticker-wrapper, .quality-mentors-bottom-ticker:hover .quality-mentors-top-ticker-wrapper{
    animation-play-state: paused;
}
.quality-mentors-top-ticker-box{
    flex-shrink: 0;
    white-space: nowrap;
}
.quality-mentors-top-ticker, .quality-mentors-bottom-ticker{
    gap: 14px;
}
.quality-mentors-bottom-ticker .quality-mentors-top-ticker-wrapper{
    animation: ticker-animation 45s linear infinite reverse;
}
.quality-mentors-bottom-ticker{
    padding-top: 14px;
}
.quality-mentors-ticker-wrapper{
    padding-top: 85px;
}
.usecases-professional-networking{
    padding-top: 190px;
}
.pro-network-body{
    padding-top: 30px;
    overflow: hidden;
}
.pro-network-main-image{
    border-radius: 8px;
    overflow: hidden;
    width: 65%;
    position: relative;
    padding-left: 30px;
}
.usecases-pro-network-right{
    width: 60%;
}
.usecases-pro-network-left{
    width: 40%;
}
.pro-network-thumb-images-image{
    border-radius: 8px;
    overflow: hidden;
}
.pro-network-thumb-images{
    width: 13%;
}
.usecases-pro-network-right-wrapper{
    gap: 10px;
}
.pro-network-thumb-images{
    gap: 13px;
}
.pro-network-views{
    background: #FFFFFF;
    border-radius: 8px;
    overflow: hidden;
    position: absolute;
    right: 14px;
    top: 14px;
}
.pro-network-views-wrapper{
    padding: 9px 20px;
    gap: 14px;
}
.pro-network-views-text{
    font-family: var(--switzer);
    font-weight: 700;
    font-size: 10px;
    line-height: 154%;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    font-feature-settings: 'liga' off;
    color: #676767;
}
.pro-network-data{
    background: #FF7252;
    border-radius: 8.2px;
    overflow: hidden;
    position: absolute;
    bottom: 40px;
    left: 0px;
    min-width: 258px;
}
.pro-network-data-wrapper{
  position: relative;
}
.pro-network-data-wrapper svg{
    position: absolute;
    height: 100%;
    object-fit: cover;
    bottom: 0;
    right: 0;
}
.pnn-number{
    font-family: var(--switzer);
    font-weight: 600;
    font-size: 54.4017px;
    line-height: 72px;
    color: #FFFFFF;
}
.pnn-number-word{
    font-family: var(--switzer);
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
}
.pro-network-numbers{
    padding-left: 19px;
    padding-bottom: 12px;
}
.usecases-learning-community{
    padding-top: 190px;
    position: relative;
    padding-bottom: 120px;
}
.usecases-learn-comm-left-image{
    width: 145px;
    height: 145px;
    border-radius: 50%;
    position: absolute;
}
.usecases-learn-comm-left-images{
    position: relative;
    width: 62%;
    height: 500px;
    margin-left: 90px;
}
.usecases-learn-comm-left-image:nth-child(1){
  top: 0%;
  left: 35%;
}
.usecases-learn-comm-left-image:nth-child(2){
    top: 20%;
    right: 0%;
}
.usecases-learn-comm-left-image:nth-child(3){
    top: 55%;
    right: 0%;
}
.usecases-learn-comm-left-image:nth-child(4){
    bottom: 0%;
    left: 35%;
}
.usecases-learn-comm-left-image:nth-child(5){
    top: 55%;
    left: 0%;
}
.usecases-learn-comm-left-image:nth-child(6){
    top: 20%;
    left: 0%;
}
.usecases-learn-comm-left-image:nth-child(7){
    top: 35%;
    left: 36%;
}
.usecases-learn-comm-left-bg{
    width: 50%;
    position: absolute;
    left: 0;
    top: 0;
}
.usecases-learn-comm-left-bg svg{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.usecases-learn-comm-left{
    width: 60%;
    position: relative;
}
.usecases-learn-comm-right{
    width: 40%;
}
.usecases-learn-comm-body{
    padding-top: 15px;
    overflow: hidden;
}
.usecases-learning-community .container{
    padding-left: 0;
}
.quality-mentors-head{
    overflow: hidden;
    padding-bottom: 6px;
}
.pro-network-heading{
    overflow: hidden;
    padding-bottom: 6px;
}
.usecases-learn-comm-heading{
    overflow: hidden;
    padding-bottom: 6px;
}
@media only screen and (max-width:1300px){
    .usecases-quality-mentors {
        padding-top: 120px;
    }
    .usecases-professional-networking {
        padding-top: 120px;
    }
    .usecases-learning-community {
        padding-top: 120px;
    }
    .quality-mentors-body{
        width: 53%;
    }
    .pnn-number{
        font-size: 50px;
    }
}
@media only screen and (max-width:1200px){
    .quality-mentors-body{
        width: 56%;
    }
    .usecases-learn-comm-left-image{
        width: 130px;
        height: 130px;
    }
    .usecases-quality-mentors {
        padding-top: 80px;
    }
}
@media only screen and (max-width:1100px){
    .quality-mentors-body{
        width: 65%;
    }
    .usecases-learn-comm-left-images{
        margin-left: 30px;
        width: 75%;
    }
    .usecases-quality-mentors {
        padding-top: 30px;
    }

}

@media only screen and (max-width:991px){
    .quality-mentors-body{
        width: 76%;
    }
    .usecases-pro-network-wrapper{
        flex-direction: column;
        gap: 50px;
    }
    .usecases-professional-networking {
        padding-top: 100px;
    }
    .usecases-learning-community{
        padding-top: 100px;
    }
    .usecases-pro-network-left{
        width: 65%;
    }
    .usecases-pro-network-right{
        width: 75%;
    }
    .pro-network-main-image{
        width: 80%;
    }
    .pro-network-thumb-images{
        width: 15%;
    }
    .usecases-learn-comm-left-bg{
        display: none;
    }
    .usecases-learning-community-wrapper{
        flex-direction: column;
        gap: 50px;
    }
    .usecases-learn-comm-left-images{
        margin-left: 0px;
        width: 100%;
    }
    .usecases-learn-comm-left{
        width: 65%;
    }
    .usecases-learn-comm-right{
        width: 65%;
    }

}
@media only screen and (max-width:767px){
    .quality-mentors-body{
        width: 89%;
    }
    .usecases-learn-comm-left{
        width: 100%;
    }
    .usecases-learn-comm-right{
        width: 100%;
    }
    .usecases-pro-network-left{
        width: 100%;
    }
    .usecases-pro-network-right{
        width: 100%;
    }
    .usecases-learning-community .container{
        padding-left: 20px;
    }
    .usecases-professional-networking {
        padding-top: 100px;
    }
    .usecases-learning-community{
        padding-top: 100px;
    }
    .pro-network-data{
        display: none;
    }
    .pro-network-main-image{
        padding-left: 0;
        width: 100%;
    }
    .pro-network-thumb-images{
        display: none;
    }
    .pro-network-views{
        right: 10px;
        top: 10px;
    }
    .usecases-learn-comm-left-images{
        height: 370px;
    }
    .usecases-learn-comm-left-image{
        width: 100px;
        height: 100px;
    }
}