.features-what-we-offer{
    padding: 270px 0 150px 0;
    position: relative;
    max-width: 1500px;
    margin: 0 auto;
}
.features-what-we-offer .insage-top-categories-body{
    width: 55%;
}
.features-what-we-offer-svg{
    position: absolute;
    left: 0;
    top: 40%;
    z-index: -1;
}
.features-what-we-offer-svg svg{ 
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.what-we-offer-bottom{
    padding-top: 100px;
    position: relative;
}
.wwo-bottom-left{
    flex:1 1 50%;
    position: relative;
    padding-left: 30px;
}
.wwo-bottom-left-top-img{
    position: absolute;
    top: 0;
    left: 0;
}
.wwo-bottom-right{
    flex:1 1 50%;
    padding-top: 60px;
}
.wwo-bottom-left-wrapper{
    position: relative;
    padding-top: 65%;
    border-radius: 6px;
}
.wwo-bottom-left-wrapper img{
    position: absolute;
    inset: 0px;
}
.what-we-offer-bottom-wrapper{
    gap: 73px;
    align-items: start;
    /* height: 100vh; */
}
.wwo-content-body{
    padding-top: 15px;
}
.wwo-content-points{
    padding-top: 35px;
}
.wwo-content-points span{
    font-family: var(--switzer);
    font-weight: 400;
    font-size: 12px;
    line-height: 156%;
}
.wwo-content-points ul{
    padding-top: 15px;
}
.woo-content-btn{
    padding-top: 34px;
}
.wwo-content-points ul li{
    color: #333333;
    position: relative;
    padding-left: 15px;
}
.wwo-content-points ul li:not(:first-child){
    margin-top: 12px;
}
.wwo-content-points ul li::before{
    content: "";
    position: absolute;
    left: 0;
    top: 7px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
}
.wwo-content-points ul li:nth-child(1):before{
    background-color: #7FD2BE;
}
.wwo-content-points ul li:nth-child(2):before{
    background-color: #FBA38F;
}
.wwo-content-points ul li:nth-child(3):before{
    background-color: #FEDB96;
}
.wwo-top-mentors-heading{
    font-family: var(--switzer);
    font-weight: 500;
    font-size: 10.628px;
    line-height: 99.5%;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #222222;
    padding-left: 20px;
}
.wwo-bottom-left-bottom-mentors{
    background: #FFFFFF;
    border-radius: 5.35117px;
    overflow: hidden;
    position: absolute;
    bottom: -10%;
    right: -4%;
}
.wwo-bottom-left-bottom-mentors-wrapper{
    padding: 20px 0;
}
.wwo-top-mentor-image{
    border-radius: 50%;
    width: 38px;
    height: 38px;
    padding-left: 20px;
}
.wwo-top-mentor-image img {
    border-radius: 50%;
    overflow: hidden;
}
.wwo-top-mentor-mentor{
    gap: 12px;
    padding-bottom: 7px;
}
.wwo-top-mentor-mentor:not(:first-child){
    padding-top: 8px;
}
.wwo-top-mentor-mentor:not(:last-child){
    border-bottom: 0.483092px solid #D5D5D5;
}
.wwo-top-mentors-list{
    padding-top: 21px;
}
.wwo-top-mentor-name{
    font-family: var(--switzer);
    font-weight: 500;
    font-size: 9.63211px;
    line-height: 99.5%;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #222222;
}
.wwo-top-mentor-designation{
    font-family: var(--switzer);
    font-weight: 700;
    font-size: 6px;
    line-height: 99.5%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #222222;
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding-top: 5px;
}
.wwo-top-mentor-designation span{
    color: #ACAAA4;
}
.wwo-top-mentor-desc{
    padding-right: 32px;
    width: calc(100% - 130px);
    /* width: 55%; */
}
.wwo-bottom-right-wrapper{
    position: relative;
    /* opacity: 0;
    visibility: hidden; */
    transition: .4s ease all;
}
.wwo-bottom-right .wwo-bottom-right-wrapper:nth-child(1){
    opacity: 1;
    visibility: visible;
}
.features-slider-btns {
    justify-content: center;
    margin-top: 30px;
}
.features-slider-btns .det-slider-prev, .features-slider-btns .det-slider-next {
    width: 30px;
    height: 30px;
    background-color: #222222;
}
.features-slider-btns .det-slider-prev svg path, .features-slider-btns .det-slider-next svg path {
    stroke: #fff;
}
@media(max-width: 1300px){
    .features-what-we-offer {
        padding-top: 120px;
        padding-bottom: 100px;
    }
}
@media(max-width: 1200px){
    .features-what-we-offer {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}
@media only screen and (max-width:1100px){
    .features-what-we-offer {
        padding-top: 0;
        padding-bottom: 100px;
    }
    .wwo-bottom-left{
        flex: 1 1 70%;
    }
}
@media only screen and (max-width:991px){
    .what-we-offer-bottom-wrapper{
        flex-direction: column;
        align-items: center;
    }
    .wwo-bottom-right{
        padding-top: 0;
        width: 90%;
    }
    .features-what-we-offer-svg{
        display: none;
    }
    .wwo-bottom-right-wrapper{
        position: static;
        opacity: 1;
        visibility: visible;
        transition: .4s ease all;
    }
    .wwo-bottom-right{
        display: flex;
        flex-direction: column;
        gap: 50px;
    }
    .features-what-we-offer {
        padding-bottom: 80px;
    }
}

@media only screen and (max-width:767px){
    .features-what-we-offer .insage-top-categories-body{
        width: 100%;
    }
    .what-we-offer-bottom-wrapper {
        gap:40px;
    }
    .wwo-bottom-left-top-img img{
        width: 130px;
    }
    .wwo-bottom-left-bottom-mentors{
        position: static;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
    }
    .wwo-bottom-left-top-img{
        top: 0;
    }
    .what-we-offer-bottom {
        padding-top: 40px;
    }
    .banner-platform-left{
        padding-top: 40px;
    }
    .wwo-bottom-right{
        width: 100%;
    }
}