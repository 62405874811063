.community-lines-sec {
    position: relative;
}
.community-lines-sec::before {
    content: '';
    position: absolute;
    top: 82px;
    width: 100%;
    height: 1px;
    background-color: #E3E3E3;
}
.community-lines-sec .left-line {
    
    margin-left: calc((100vw - 900px)/2);
    position: relative;
}
.community-lines-sec .left-line::before {
    content: '';
    position: absolute;
    top: 82px;
    width: 1px;
    height: 100%;
    background-color: #E3E3E3;
}
.blog-container-for-line {
    margin: 0 auto;
    padding: 0 20px;
    margin-right: calc((100vw - 900px)/2);
}
/* Community Create */
.editor-header {
    padding: 24px 0;
    border-bottom: 1px solid #E3E3E3;
}
.editor-header-in {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.editor-buttons {
    display: flex;
    align-items: center;
    gap: 20px;
}
.editor-content {
    padding: 60px 0;
}
.editor-content .post-title {
    margin-bottom: 40px;
    text-align: center;
}
.editor-content .post-title input {
    border: none;
    font-family: var(--switzer);
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: #222;
    width: 80%;
    margin: 0 auto;
    text-align: center;
}
.post-title input::placeholder {
    color: #C2C2C2;
}
.ql-container .ql-editor {
    min-height: 500px;
}
.ql-editor img {
    width: 100%;
}
/* Community Create */

@media(max-width:991px){
    .community-lines-sec .left-line  {
        margin-left: auto;
    }
    .blog-container-for-line {
        margin-right: auto;
    }
}