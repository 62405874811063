.banner-platform {
    margin-bottom: 100px;
}
.banner-platform-left{
    flex: 1 1 51%;
    padding-top: 80px;
}
.banner-platform-right{
    flex: 1 1 40%;
    position: relative;
}
.banner-platform{
    padding-top: 100px;
    position: relative;
}
.banner-platform .container{
  position: relative;
}
.banner-platform-heading{
    overflow: hidden;
    padding-bottom: 6px;
}
.banner-platform-heading h2{
    text-align: left;
}
.banner-platform-right-image{
    position: relative;
    z-index: 1;
    width: 65%;
    top: 57%;
    left: 19%;
}
.banner-platform-bg{
    width: 40%;
    position: absolute;
    top: 0;
    right: 0;;
}
.banner-platform-bg svg{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.banner-platform-body{
    padding-top: 20px;
    max-width: 60ch;
    overflow: hidden;
}
.banner-platform-button .primary-btn {
    margin-top: 50px;
}
.banner-platform-right-image svg{
    position: absolute;
    left: -10%;
    bottom: 13%;
}
.banner-platform-ticker {
    bottom: -200px;
    z-index: 9;
    position: relative;
}
.banner-platform-ticker .marquee-wrap{
    width: 100%;
    
}
.insage-features .banner-platform .banner-platform-bg svg path{
    fill: #A8DD73;
}
.insage-features .banner-platform .banner-platform-right-image svg circle{
    fill: #FEC14B;
}
.insage-usecases .banner-platform .banner-platform-bg svg path{
    fill: #FF7352;
}
.insage-usecases .banner-platform .banner-platform-right-image svg circle{
    fill: #A8DD73;
}

@media only screen and (max-width:1400px){
    .banner-platform-ticker {
        bottom: -100px;
    }
}

@media only screen and (max-width:1100px){
    
    .banner-platform-ticker {
        bottom: 0;
    }
}

@media only screen and (max-width:991px){
    .banner-platform-wrapper{
        flex-direction: column;
        gap: 50px;
    }
    .banner-platform-bg{
        display: none;
    }
    .banner-platform-right-image{
        inset: 0px;
        margin: 0 auto;
    }
    .banner-platform{
        margin-top: 60px;
        padding-top: 0;
    }
    .banner-platform-ticker .marquee-wrap{
        bottom: 0;
    }
    .banner-platform-right-image svg {
        position: absolute;
        left: -7%;
        bottom: 13%;
    }
    .banner-platform-ticker {
        bottom: 40px;
    }
    .banner-platform {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width:767px){
    .banner-platform-right-image{
        width: 100%;
    }
    .banner-platform-right-image svg {
        position: absolute;
        left: -11%;
        bottom: 13%;
        height: 55px;
    }
    .banner-platform{
        margin-top: 0px;
    }
    .banner-platform-heading h2 br {
        display: none;
    }
}

