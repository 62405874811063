.obHeader {
    padding: 20px 0;
    position: relative;
    z-index: 999;
    background-color: #FFFFFF;
}
.oh-logo .oh-logo-img {
    width: 150px;
}
.obHeader-left{
    width: 60%;
}
.obHeader-right{
    width: 70ch;
}
.obHeader-wrapper{
    width: 402px;
    margin: 0 auto;
}
.obFromBtn{
    text-align: end;
    padding-top: 40px;
}

@media only screen and (max-width:991px){
    .obHeader{
        flex-direction: column;
    }
    .obHeader-left{
        width: 100%;
    }
    .obHeader-right{
        width: 100%;
    }
    .obHeader-wrapper{
        width: 90%;
    }
    .oh-logo .oh-logo-img{
        width: 130px;
    }
}
