.slot-booking-note-wrap{
    padding: 10px 12px;
    display: flex;
    align-items: center;
    gap: 12px;
}
.schedule-step-2{
    margin-top: 24px;
}
.schedule-step-2-left{
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    display: inline-flex;
    width: 66%;
}
.slot-booking-note-text{
    font-family: var(--switzer);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 168%;
    letter-spacing: 0.2px;
    color: #666;
}
.slot-booking-note{
    display: inline-flex;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    box-shadow: 0px 3px 4px 0px #F6F6F6;
}
.slot-booking-slots{
    padding: 22px 23px 32px 23px;
}
.slot-booking-slots .react-tabs{
  margin-top: 32px;
}
.slot-booking-slots .react-tabs .react-tabs__tab-list{
    border-bottom:1px solid #E3E3E3;
    display: inline-flex;
    gap: 53px;
    margin-bottom: 0px;
    scroll-snap-type: x mandatory;
    overflow-x: auto;
    overflow-y: hidden;
}
.slot-booking-slots .react-tabs__tab{
    color: #B4B4B4;
    font-family: var(--switzer);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
    border: none;
    padding: 0px;
    padding-bottom: 16px;
    flex-shrink: 0;
    scroll-snap-align: start;
}
.slot-booking-slots .react-tabs__tab:focus::after{
  background: transparent;
}
.slot-booking-slots .react-tabs__tab--selected{
    background: transparent;
    color: #222;
    border-bottom: 2px solid #222;
}
.slot-booking-slots .react-tabs__tab-panel ul{ 
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 32px;
}
.slot-booking-slots .react-tabs__tab-panel ul li{
    width: 104px;
    border-radius: 99px;
    background: #FFF;
    box-shadow: 1px 4px 8px 0px #F3F3F3;
    color: #454545;
    padding: 2px 0;
    font-family: var(--switzer);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 152%;
    letter-spacing: 0.24px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    position: relative;
    display: flex;
    border: 2px solid transparent;
}
.slot-selected-icon{
    margin-left: 6px;
    display: none;
}
.slot-booking-slots .react-tabs__tab-panel ul li.selected-slot{
    border: 2px solid #222;
    color: #222;
}
.slot-booking-slots .react-tabs__tab-panel ul li.selected-slot .slot-selected-icon{
    display: inline-flex;
}
.schedule-step-2-wrap{
    display: flex;
    gap: 12px;
}
.schedule-step-2-right .schedule-details-summary{
   width: 100%;
}
.schedule-step-2-right{
    width: 33%;
}

@media only screen and (max-width:1300px){
    .schedule-step-2-left{
        width: 60%;
    }
    .schedule-step-2-right{
        width: 40%;
    }
}

@media only screen and (max-width:767px){
    .schedule-step-2-wrap{
        flex-direction: column;
    }
    .schedule-step-2-left {
        width: 100%;
    }
    .schedule-step-2-right{
        width: 100%;
    }

}