.insage-FAQ-temp{
    background-color: white;
}
.isg-faq-page-heading h1{
    font-weight: 700;
    font-size: 52px;
    line-height: 112%;
    font-feature-settings: 'ss08' on;
    color: #24584C;
    text-transform: capitalize;
}
.isg-faq-page-body{
    padding-top: 26px;
}
.insage-faq-page-wrapper{
    margin-left: 10vw;
}
.isg-faq-page-body p{
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0.01em;
    color: #555555;
}
.insage-faq-page-heading-wrapper{
    position: relative;
    width: 55%;
    padding-top: 75px;
    min-width: 790px;
}
.isg-faq-page-banner{
    position: absolute;
    top: 0;
    right: 0;
}
.isg-faq-page-banner img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.isg-faq-page-tabs{
    padding-top: 106px;
    min-width: 965px;
    width: 65%;
}
.isg-faq-page-tabs .react-tabs__tab-list{
    border: none;
    display: flex;
    flex-direction: column;
    gap: 21px;
    min-width: 140px;
}
.isg-faq-page-tabs-wrapper{
    gap: 179px;
    align-items: start;
}
.isg-faq-page-tabs-buttons{
    position: sticky;
    top: 100px;
}
.isg-faq-page-callout{
    background-color: #FEFBF4;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 120px;
}
.isg-faq-page-tabs .react-tabs__tab{
    border: none;
    padding: 0;
    background: transparent;
    font-family: var(--switzer);
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    transition: .5s ease all;
    color: #888888;
}
.isg-faq-page-tabs .react-tabs__tab.active{
    color: #333333;
}
.isg-faq-page-tabs .react-tabs__tab:focus:after{
    background: transparent;
}
.isg-faq-page-tab-heading h2{
    font-family: var(--switzer);
    font-weight: 600;
    font-size: 30px;
    line-height: 120%;
    text-transform: capitalize;
    font-feature-settings: 'ss08' on;
    color: #333333;
    text-align: left;
}
.isg-faq-page-tab-faqs{
    padding-top: 36px;
}
.isg-faq-page-tab-faq{
    cursor: pointer;
}
.isg-faq-page-tab-faqs li{
    border-bottom: 1px solid #E3E3E3;
    padding-bottom: 30px;
}
.isg-faq-page-tab-faqs ul{
    gap: 30px;
}
.isg-faq-page-tab-faq-heading{
    font-family:  var(--switzer);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 144%;
    letter-spacing: 0.02em;
    color: #333333;
}
.isg-faq-page-tab-faq-body{
    display: grid;
    grid-template-rows: 0fr;
    opacity: 0;
    --webkit-transition: all .4s;
    transition: all .4s;
}
.isg-faq-page-tab-faq-body div{
    overflow: hidden;
}
li.active .isg-faq-page-tab-faq-body{
    grid-template-rows: 1fr;
    opacity: 1;
}

.isg-faq-page-tab-faq-body p{
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0.01em;
    color: #555555;
    padding-top: 20px;
}
.isg-faq-page-tab-faq-arrow{
    transition: all .4s;
    transform: rotate(0deg);
}
li.active .isg-faq-page-tab-faq-arrow{
    transform: rotate(180deg);
}
.isg-faq-page-callout-content-heading{
    font-family: var(--switzer);
    font-weight: 600;
    font-size: 30px;
    line-height: 120%;
    text-transform: capitalize;
    font-feature-settings: 'ss08' on;
    color: #24584C
}
.isg-faq-page-callout-content-body{
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0.01em;
    color: #4F4D48;
    padding-top: 10px;
}
.isg-faq-page-callout-content-body a{
    text-decoration: underline;
    color: #222;
}
.isg-faq-page-callout-wrapper{
    padding: 33px 25px;
}
.isg-faq-page-tabs-contents{
    gap: 64px;
}
@media only screen and (max-width:1100px){
    .isg-faq-page-tabs{
        min-width: 95%;
    }
    .isg-faq-page-tabs-wrapper{
        gap: 100px;
    }
    .isg-faq-page-callout-content{
        width: 70%;
    }
}
@media only screen and (max-width:991px){
    .isg-faq-page-heading h1{
        font-size: 40px;
    }
    .isg-faq-page-body p{
        font-size: 16px;
    }
    .insage-faq-page-heading-wrapper{
        min-width: 95%;
        padding-right: 30px;
    }
    .isg-faq-page-tab-faq-body p{
        font-size: 16px;
    }
    .isg-faq-page-tab-heading h2{
        font-size: 26px;
    }
    .isg-faq-page-callout-content-body{
        font-size: 16px;
    }
    .isg-faq-page-callout-content-heading{
        font-size: 26px;
    }
}
@media only screen and (max-width:767px){
    .isg-faq-page-tab-faq-body p{
        font-size: 14px;
    }
    .isg-faq-page-callout-content-heading{
        font-size: 22px;
    }
    .isg-faq-page-heading h1{
        font-size: 28px;
    }
    .isg-faq-page-body p{
        font-size: 14px;
    }
    .isg-faq-page-tab-heading h2{
        font-size: 22px;
    }
    .isg-faq-page-tab-faq-heading{
        font-size: 16px;
    }
    .isg-faq-page-callout-content-body{
        font-size: 14px;
    }
    .insage-faq-page-wrapper{
        margin-left: 0;
    }
    .isg-faq-page-banner{
        display: none;
    }
    .isg-faq-page-tabs-wrapper{
        flex-direction: column;
        gap: 50px;
    }
    .isg-faq-page-tabs .react-tabs__tab-list{
        flex-direction: row;
        scroll-snap-type: x mandatory;
        overflow: hidden;
        overflow-x: auto;
    }
    .isg-faq-page-tabs .react-tabs__tab-list li{
        flex-shrink: 0;
        scroll-snap-align: start;
    }
    .isg-faq-page-tabs{
        padding-top: 86px;
        margin: 0 auto;
        min-width: 90%;
    }
    .isg-faq-page-callout-wrapper{
        flex-direction: column;
        align-items: start;
        gap: 30px;
    }
    .isg-faq-page-callout-content{
        width: 100%;
    }
    .insage-faq-page-heading-wrapper{
        padding-right: 0;
        min-width: 90%;
        margin: 0 auto;
    }
    .isg-faq-page-tabs-buttons{
        position: static;
    }
    .insage-faq-page-heading-wrapper{
        padding-top: 0px;
    }
    .isg-faq-page-tabs{
        padding-top: 46px;
    }
}