.insage-become-better{
    background-color: #FFECA2;
    position: relative;
}
.insage-become-better .marquee-wrap {
    top: -50px;
}
.insage-become-better .container{
   position: relative;
   z-index: 2;
}
.insage-become-better-img {
    border-radius: 50%;
    overflow: hidden;
    transition: 1s ease all;
    transition-delay: .3s;
    clipPath: circle(100% at 50% 50%);
    -webkit-clipPath: circle(0% at 50% 50%);
    transition: clipPath 1s cubic-bezier(0.9, 0.03, 0.47, 0.89);
    height: 0;
    width: 100%;
    position: relative;
    padding-top: 100%;;
}
.swiper-slide-active .insage-become-better-img{
    transition: clipPath 1s cubic-bezier(0.9, 0.03, 0.47, 0.89);
    clipPath: circle(46% at 50% 50%);
    transition: 1s ease all;
}
.insage-become-better-img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.insage-become-better-left{
    width: 40%;
    margin: 0 auto;
    margin-left: 0;
}
.separator-div{
    width: 41px;
    height: 8px;
    background: #F9E491;
    margin-top: 44px;
}
.insage-become-better-mentor-name h5{
    text-transform: capitalize;
}
.insage-become-better-mentor-designation span{
    color:#555555;
}
.insage-become-better-body{
    padding-top: 57px;
    overflow: hidden;
}
.insage-become-better-body p{
    color: #555555;
    transition: 0.9s cubic-bezier(0.17, 0.55, 0.55, 1);
    transform: translateY(100%);
    opacity: 0;
    transition-delay: .3s;
}
.swiper-slide-active .insage-become-better-body p{
    transform: translateY(0%);
    opacity: 1;
}
.insage-become-better-wrapper{
    padding-top: 80px;
    padding-bottom: 94px;
}
.insage-become-better-mentor-wrapper{
    padding-top: 38px;
    transition: 0.9s cubic-bezier(0.17, 0.55, 0.55, 1);
    transform: translateY(100px);
    opacity: 0;
    transition-delay: .6s;
}
.swiper-slide-active .insage-become-better-mentor-wrapper{
    transform: translateY(0px);
    opacity: 1;
}
.insage-become-better-mentor-designation{
    padding-top: 7px;
}
.insage-become-better-mentor-btn{
    padding-top: 23px;
}
.insage-become-better-right{
    width: 43%;
}
.insage-become-better-svg{
    position: absolute;
    bottom: -19%;
    z-index: 0;
    width: 31%;
}
.insage-become-better-svg svg{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.insage-become-better-swiper{
    position: relative;
}
.insage-become-better-swiper .prev-btn{
   right: 70px;
   bottom: 85px;
}
.insage-become-better-swiper .next-btn{
    right: 0px;
    bottom: 85px;
}
.insage-become-better-heading {
    overflow: hidden;
    padding-bottom: 5px;
}
.insage-become-better-heading h3{
    transition: 0.9s cubic-bezier(0.17, 0.55, 0.55, 1);
    transform: translateY(100%);
    opacity: 0;
}
.swiper-slide-active .insage-become-better-heading h3{
    transform: translateY(0%);
    opacity: 1;
}
.insage-become-better .swiper-slide{
    opacity: 0 !important;
}
.insage-become-better .swiper-slide.swiper-slide-active{
    opacity: 1 !important;
}
@media only screen and (max-width:1500px){
    .insage-become-better-left{

        margin: 0 auto;
        margin-left: auto;
    }
}

@media only screen and (max-width:1200px){
    .insage-become-better-svg{
        bottom: -10%;
    }
    .insage-become-better-wrapper{
        padding-top: 40px;
    }
}

@media only screen and (max-width:1100px){
    .insage-become-better-svg{
        width: 37%;
        bottom: -5%;
    }
}

@media only screen and (max-width:991px){
    .insage-become-better-svg{
        display: none;
    }
    .insage-become-better-left{
        width: 30%;
        margin: 0;
    }
    .insage-become-better-right {
        width: 65%;
    }
    .insage-become-better-body {
        padding-top: 26px;
    }
    .insage-become-better-mentor-wrapper {
        padding-top: 20px;
    }
    .separator-div {
        margin-top: 20px;
    }
    .insage-become-better-swiper .prev-btn, .insage-become-better-swiper .next-btn{
        bottom: 20px;
     }
     .insage-become-better-swiper .prev-btn{
       left: 35%;
     }
     .insage-become-better-swiper .next-btn{
       left: 45%;
     }
}

@media only screen and (max-width:767px){
    .insage-become-better-wrapper{
        flex-direction: column;
        padding-top: 0;
        gap: 50px;
    }
    .insage-become-better-left{
        width: 100%;
    }
    .insage-become-better-right{
        width: 100%;
    }
    .insage-become-better-body{
        padding-top: 35px;
    }
    .insage-become-better-swiper .prev-btn{
        left: 0%;
      }
      .insage-become-better-swiper .next-btn{
        left: 20%;
      }  
}