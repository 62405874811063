.loading-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.9);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: 999;
}
.loading-loader p {
    margin-top: 20px;
    font-weight: 700;
}

.spinner-square {
    display: flex;
    flex-direction: row;
    width: 90px;
    height: 120px;
}

.spinner-square > .square {
    width: 17px;
    height: 80px;
    margin: auto auto;
    border-radius: 4px;
}

.square-1 {
    animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s infinite;
}

.square-2 {
    animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 200ms infinite;
}

.square-3 {
    animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 400ms infinite;
}

@keyframes square-anim {
    0% {
        height: 80px;
        background-color: rgb(255, 115, 82);
    }
    20% {
        height: 80px;
    }
    40% {
        height: 120px;
        background-color: rgb(250, 228, 145);
    }
    80% {
        height: 80px;
    }
    100% {
        height: 80px;
        background-color: rgb(36, 88, 76);
    }
}