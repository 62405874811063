.loadingState {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,.5);
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
    z-index: 999999;
}
.loadingState p.quoteText {
    font-weight: 700;
}
.loader {
    width: calc(100px - 24px);
    height: 50px;
    position: relative;
    animation: flippx 2s infinite linear;
  }
  .loader:before {
    content: "";
    position: absolute;
    inset: 0;
    margin: auto;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fec14b;
    transform-origin: -24px 50%;
    animation: spin 1s infinite linear;
  }
  .loader:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50% , -50%);
    background: #fff;
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
  
  @keyframes flippx {
    0%, 49% {
      transform: scaleX(1);
    }
    50%, 100% {
      transform: scaleX(-1);
    }
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }