.insage-top-categories-label{
    background-color: #24584B;
    border-radius: 4px 4px 0px 0px;
    width: 208px;
    height: 32px;
    margin: 0 auto;
    overflow: hidden;
}
.insage-top-categories-label span{
    padding: 7px 0;
}
.insage-top-categories-heading{
    padding-top: 22px;
    overflow: hidden;
    padding-bottom: 6px;
}
.insage-top-categories-body{
    padding-top: 30px;
    text-align: center;
    margin: 0 auto;
    overflow: hidden;
    max-width: 40ch;
}
.insage-top-categories-middle-wrapper{
    padding-top: 62px;
}
.mentored-in-cards-wrapper {
    gap: 24px;
    scroll-snap-type: x mandatory;
    overflow: hidden;
    overflow-x: scroll;
    justify-content: center;
    padding-bottom: 20px;
}
.mentored-in-cards-wrapper::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 3px;
  }
.mentored-in-cards-wrapper::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .4);
  }
.mentored-in-card-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px;
    height: calc(100% - 50px);
}
.mentored-in-card:nth-child(1){
    background-color: #FEC14B;
}
.mentored-in-card:nth-child(1) .mentored-in-title{
    color: white;
}
.mentored-in-card:nth-child(2){
    background-color: #F5F5F5;
}
.mentored-in-card:nth-child(3){
    background-color: #F9E491;
}
.mentored-in-card:nth-child(3) .mentored-in-title, .mentored-in-card:nth-child(2) .mentored-in-title{
   color: #FEC14B;
}
.mentored-in-card{
    border-radius: 6px;
    overflow: hidden;
    width:31%;
    flex-shrink: 0;
    scroll-snap-align: start;
}
.mentored-in-title{
    font-family: var(--switzer);
    font-weight: 700;
    font-size: 76px;
    line-height: 75.5%;
    padding-top: 79px;
}
.mentored-in-btn a {
    padding: 0;
    border-radius: 0;
    gap: 5px;
}
.mentored-in-btn a svg {
    transition: .3s ease all;
}
.mentored-in-btn a:hover svg {
    transform: translateX(10px);
}
.mentored-in-body p{
    color: #555555;
}
.mentored-in-body{
    padding-top: 105px;
}
.mentored-in-btn{
    padding-top: 15px;
}
.insage-top-categories-bottom-wrapper{
    padding-top: 28px;
}
.insage-top-categories-bottom-btn{
    padding-top: 40px;
    text-align: center;
}
.insage-top-categories-bottom-content{
    width: 70%;
    text-align: center;
    overflow: hidden;
    margin: 0 auto;
}
.mentoredin-content {
    position: relative;
    padding-bottom: 50px;
}
.mentoredin-content .mentored-in-btn {
    position: absolute;
    bottom: 0;
    left: 0;
}
.mentored-in-btn .primary-btn {
    padding-left: 0;
    padding-right: 0;
    gap: 5px;
}
.mentored-in-btn .primary-btn svg {
    margin-top: 0px;
}

@media only screen and (max-width:1300px){
    .mentored-in-title{
        font-size: 76px;
    }
    .mentored-in-cards-wrapper{
        justify-content: start;
    }
}

@media only screen and (max-width:1200px){
    .mentored-in-title{
        font-size: 60px;
    }
}

@media only screen and (max-width:1100px){
    .mentored-in-card{
        width: 35%;
    }
}

@media only screen and (max-width:991px){
    .mentored-in-body{
        padding-top: 80px;
    }
    .mentored-in-card{
        width: 40%;
    }
    .insage-top-categories-body{
        width: 80%;
    }
    .insage-top-categories-bottom-content{
        width: 80%;
    }
    .mentored-in-title{
        font-size: 64px;
    }
}

@media only screen and (max-width:767px){
    .mentored-in-card{
        width: 80%;
    }
    .insage-top-categories-bottom-content{
        width: 100%;
    }
    .insage-top-categories-body{
        width: 100%;
    }
}